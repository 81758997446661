import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AssessmentService } from '../../../services/assessment.service';
import { NotificationService } from '../../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';
import { NgSelectModule } from '@ng-select/ng-select';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-add-team',
  standalone: true,
  imports: [CommonModule,FormsModule,NgToastModule,NgSelectModule,RouterLink],
  templateUrl: './add-team.component.html',
  styleUrl: './add-team.component.css'
})
export class AddTeamComponent implements OnInit {
  selectedOrgId: any;
  organization: any;
  sport: any;
  selectedOrgFilter: string ='';
  selectedSportFilter: string ='';
  teamName: any;
  teamSport:any;
  selectedTeamId: any;

  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private router:Router
  ) {

  }

  ngOnInit() {
    this.loadOrgainsation();
    this.loadTeam();
  }

  loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.organization = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }


  loadTeam() {
    this.assessmentService.getSport().subscribe(
      (data) => {
        this.sport = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }

  onSubmit(){
  if (this.selectedOrgFilter && this.teamName && this.selectedSportFilter) {
    this.selectedTeamId = 0 ;
    this.selectedSportFilter ;
    this.assessmentService.AddTeam(this.selectedTeamId,this.selectedOrgFilter,this.teamName,this.selectedSportFilter).subscribe(
      response => {
        if (response.data[0] == 0) {
          this.notify.failed(response.message);
      } else {
        this.notify.success(response.message);
        setTimeout(() => {

          this.router.navigate(['/team'], { 
            queryParams: { 
               id: this.selectedOrgFilter 
            }
          }
        );
          
        },
        
        1000);
      }
    },
      error => {
        this.notify.failed(error.error.message);
        console.error("error",error.error.message);
        // Handle error
      }
    );
  } else {
    this.notify.failed('“Please enter Team name and select Sport name.');
  }
}
}