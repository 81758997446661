import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { NotificationService } from '../../services/Notifications.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser';
import { UserRegisterService } from '../../services/user-register.service';
import {
  ApexNonAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexLegend,
  NgApexchartsModule,
} from 'ng-apexcharts';
import * as Highcharts from 'highcharts';
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChatService } from '../../services/chat.service';
import { Location } from '@angular/common';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  colors: string[];
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
};

@Component({
  selector: 'app-sports-psychology-list',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule,NgApexchartsModule,HighchartsChartModule],
  providers:[DatePipe],
  templateUrl: './sports-psychology-list.component.html',
  styleUrls: ['./sports-psychology-list.component.css']
})
export class SportsPsychologyListComponent {
  @ViewChildren('fitnessTable') tables!: QueryList<ElementRef>;
  
  rowCounts: number[] = [];
  pctSums: number[] = [];
  Highcharts: typeof Highcharts = Highcharts;
  detail: any;
  UserId: any;
  fitness: any;
  cognitive: any;
  cardio: any;
  ImageUrl: any;
  showImage: boolean = false;
  userDetail: any;
  selectedImageUrl: any;
  id: any;
  percentages: any = {};
  cognitivePercentages: any = {};


 referenceValues = {
  pfa50mSprint: 6.0,
  pfa30mSprint: 4.0,
  pfa10mShuttleRun: 20.0,
  pfaTTest: 15.0,
  pfaStandingBroadJump: 250,
  pfaVerticalJump: 40,
  pfaSeatedMedBallThrow: 7.5,
  pfaGripStrengthRight: 50,
  pfaGripStrengthLeft: 48,
  pfaSingleLegBalanceLeftEyesOpen: 30,
  pfaSingleLegBalanceLeftEyesClose: 20,
  pfaSingleLegBalanceRightEyesOpen: 32,
  pfaSingleLegBalanceRightEyesClose: 22,
  pfaPushups: 35,
  pfaPullups: 10,
  pfaSquat: 80,
  pfaSitNReach: 18,
  pfaUpperBodyPower: 100,  // Reference value for Upper Body Power
  pfaUpperBodyForce: 60, // Reference value for Upper Body Force
  pfaLowerBodyPower: 8000, // Reference value for Lower Body Power
  pfaLowerBodyForce: 400,  // Reference value for Lower Body Force
  pfaSpeed: 5.0,   // Adding reference value for Speed
  pfaYoYoTest: 2000 // Adding reference value for YoYo Test
  };
  
  
  cognitiveReferenceValues = {
    cnaRulerDrop: 100,     // Reference value for ruler drop test (example)
    cnaBlazepods: 100,     // Reference value for blazepods test (example)
    cnaBlazepodsTime: 30,  // Reference value for blazepods time (example)
    cnaWallToss: 100,      // Reference value for wall toss test (example)
    cnaPlateTaping: 100    // Reference value for plate taping test (example)
  };
  referenceNutritionValues = {
    naBodyWeight: 70,
    naBodyHeight: 170,
    naBMI: 22,
    naBodyFat: 15,
    naFatFreeWeight: 50,
    naSubcutaneousFat: 10,
    naVisceralFat: 10,
    naBodyWater: 60,
    naSkeletalMuscle: 30,
    naLeanMass: 55,
    naBoneMass: 3,
    naProtein: 18,
    naBMR: 1500,
    naMetabolicAge: 25,
    naHealthScore: 100,
  };

  referenceSPAValues = {
    spaAverageTorqueCR: 100,  // Reference value for spaAverageTorqueCR (right leg)
    spaAverageTorqueCL: 100,  // Reference value for spaAverageTorqueCL (left leg)
    spaPeakTorqueCR: 200,     // Reference value for spaPeakTorqueCR (right leg)
    spaPeakTorqueCL: 200,     // Reference value for spaPeakTorqueCL (left leg)
    spaAverageTorqueIR: 90,   // Reference value for spaAverageTorqueIR (internal rotation right leg)
    spaAverageTorqueIL: 90,   // Reference value for spaAverageTorqueIL (internal rotation left leg)
    spaPeakTorqueIR: 150,     // Reference value for spaPeakTorqueIR (internal rotation right leg)
    spaPeakTorqueIL: 150,     // Reference value for spaPeakTorqueIL (internal rotation left leg)
    spaJumpHeight: 30,        // Reference value for jump height
    spaFlightTime: 5.0,       // Reference value for flight time (seconds)
    spaVelocity: 2.0,         // Reference value for velocity (m/s)
    spaForce: 10.0,           // Reference value for force (N)
    spaPower: 5.0,            // Reference value for power (W)
  };
  
  
  org: any;
  sport: any;
  leveloneReference: any;
  leveloneReport: any;
  fitnessReport: any;
  cognitiveReport: any;
  cardioReport: any;
  public chartOptions: Partial<ChartOptions> | any; // Add chartOptions property
  chartOptionsHeight: any;
  chartOptionsSingleleg:any;
  chartOptions800m:any;
  chartOptionsRast:any;
  chartOptionsYoyo:any;
  chartOptionsBalance:any;
  chartOptionsExcursion:any;
  chartOptionsWeight: any;
  chartOptionsBMI: any;
  chartOptionsPushups: any;
  chartOptionsPullups: any;
  chartOptionsGripStrengthRight: any;
  chartOptionsGripStrengthLeft: any;
  chartOptionsSquat: any;
  chartOptionsCurlUp: any;
  chartOptionsStandingBroadJump: any;
  chartOptionsVerticalJump: any;
  chartOptionsMedBallThrow: any;
  chartOptions50mSprint: any;
  chartOptions30mSprint: any;
  chartOptionsShuttleRun: any;
  chartOptionsTTest: any;
  chartOptionsSitNReach: any;
  chartOptionsApleysScratch: any;
  chartOptionsRulerDrop: any;
  chartOptionsBlazepods: any;
  chartOptionsWallToss: any;
  chartOptionsPlateTaping: any;
  overall: any;
  assessmentId: any;
  summarydetails: any;
  monthList: string[] = [];
  selectedMonth: string = '';
  fitnessData: [] = [];
  cardioData:  [] = [];
  cognitiveData:  [] = [];
  nutritionData: [] = [];
  nutrition: any;
  injuryData: [] = [];
  injury: any;
  nutritionPercentages: any = {};
  sportsData: [] = [];
  sports: any;
  age: any;
  performanceReport: any;
  nutritionReport: any;
  spaPercentages: any = {};
  chat: any;
  SleepandRest: any;
  hydration: any;
  questionary: any;
  coachName: any;
  allTeams: any;
  averagePctlValues: number[] = []; 
  image: any;
  benchMarkReference: any;
  Sports: any;
  user: any;

  constructor(
    private assessmentService: AssessmentService,
    private sanitizer: DomSanitizer,
    private userRegisterService: UserRegisterService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private notify: NotificationService,private chatService: ChatService,
    private datePipe: DatePipe,private cdr: ChangeDetectorRef,
    private location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.org = params['orgId'];
      this.UserId = this.id;  // Set UserId from query parameter
      this.user = params['user'];
    });

    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;

      if (this.UserId === undefined) {
        this.UserId = this.detail.user?.usrId;
        this.org = this.detail.user.usrOrganization;
        this.sport = this.detail.user.usrSport;
       
      }
    });
    this.getProfileImage();
    
  }
 
  
  ngOnInit() {
    this.fetchInitialData();

    this.assessmentService.getSport().subscribe(
      (data: any[]) => {
        this.Sports = data;
      },
      (error: any) => {
      }
    );

    this.averagePctlValues = new Array(this.tables.length).fill(0);
  }

  // ngAfterViewInit() {
  //   // After tables are initialized, calculate average pctl for each table
  //   this.tables.forEach((table, index) => {
  //     const avgPctl = this.calculateAveragePctl(index);
  //     this.averagePctlValues[index] = avgPctl;
  //   });

  //   // Trigger change detection to ensure the values are updated in the view
  //   this.cdr.detectChanges();
  // }

  async fetchInitialData() {
    await this.getUser();
    // await this.fetchAssessments();
    await this.getFitnessData();
    await this.getFitnessDataByAthleteAndOrg();
    await this.getNutritionData();
    await this.getCognitiveData();
    await this.getCardioData();
    await this.getInjuryPrventionData();
    await this.getSportsPerformaceData();
    await this.getNutritionalTest(this.assessmentId);
    await this.getNutritionDataByAthleteAndOrg();
    await this.summaryDetails();
    await this.getPerformanceDataByAthleteAndOrg();
    await this.getCognitiveDataByAthleteAndOrg();
    await this.getCardioDataByAthleteAndOrg();
    await this.getLorMarkRangeForEachParameter();
    await this.calculateOverallPercentage();
    // await this.summaryDetails();
    
  }
 
  navigateToNextPage(){
    if(this.detail.user.usrRole ==2){
    this.router.navigate(["/registerrequest"],{ queryParams: { id: 1} });
  

    }else{
      this.router.navigate(["/dashboard"])
    }
  }

  onBack(): void {
    if(this.user){
      this.router.navigate(['/registerrequest'], { queryParams: { usrActive: 1 } });
      }else{
          this.location.back();
      }
  }

  getFitnessData() {
    this.assessmentService.getFitnessByAthleteId(this.UserId).subscribe(
       async (response: any) => {
        this.fitnessData = response;
        this.fitness = response && response.length > 0 ? response.slice(-1)[0] : null;
        this.assessmentId = this.fitness.pfaAssessment;

        this.assessmentService.getPhysicalFitnessByAsm(this.UserId,this.assessmentId).subscribe(
          async (data) => {
            this.fitness = data[0];
            await this.calculatePercentages();  // Ensure this runs after data is fetched
          },
          (error) => {
          }
        );
        
        const uniqueMonths = new Set<string>(); // Use a Set to avoid duplicates

        response.forEach((item: any) => {
          const date = new Date(item.pfaCreatedOn); // Convert the string to a Date object
          const monthYear = this.datePipe.transform(date, 'MMMM'); // Convert to 'Month Year' format

          if (monthYear) {
            uniqueMonths.add(monthYear); // Add to the Set (duplicates will be ignored)
          }
        });

        this.monthList = Array.from(uniqueMonths); // Convert the Set back to an array
        //await this.getBMI();
        await this.calculatePercentages();  // Ensure this runs after data is fetched
      },
      (error) => {
      }
    );
  }


  getBenchMarkRange(columnName: string): string {
    const benchmark = this.benchMarkReference?.find(
      (item: any) => item.testColumnName === columnName
    );
    return benchmark ? benchmark.ibmRange : 'N/A';
  }
  


  getNutritionData() {
    this.assessmentService.geteNutritionalByAthleteId(this.UserId).subscribe(
      async (response: any) => {
        this.nutritionData = response;
        this.nutrition = response && response.length > 0 ? response.slice(-1)[0] : null;
        this.assessmentId = this.nutrition.naAssessment;
        this.assessmentService.getNutritionByAsm(this.UserId,this.assessmentId).subscribe(
          (data) => {
            this.nutrition = data[0];
          },
          (error) => {
          }
        );

        const uniqueMonths = new Set<string>(); // Use a Set to avoid duplicates

        response.forEach((item: any) => {
          const date = new Date(item.naDate); // Convert the string to a Date object
          const monthYear = this.datePipe.transform(date, 'MMMM'); // Convert to 'Month Year' format

          if (monthYear) {
            uniqueMonths.add(monthYear); // Add to the Set (duplicates will be ignored)
          }
        });

        this.monthList = Array.from(uniqueMonths); // Convert the Set back to an array

        await this.getBMI();
        await this.calculateNutritionPercentages(); 
      },
      (error) => {
      }
    );
  }


  getInjuryPrventionData() {
    this.assessmentService.geteInjuryPrventionByAthleteId(this.UserId).subscribe(
      async (response: any) => {
        this.injuryData = response;
        this.injury = response && response.length > 0 ? response.slice(-1)[0] : null;
        this.assessmentService.getinjuryPeventionByAsm(this.UserId,this.assessmentId).subscribe(
          (data) => {
            this.injury = data[0];
          },
          (error) => {
          }
        );

        const uniqueMonths = new Set<string>(); // Use a Set to avoid duplicates

        response.forEach((item: any) => {
          const date = new Date(item.ipaAssessmentDate); // Convert the string to a Date object
          const monthYear = this.datePipe.transform(date, 'MMMM'); // Convert to 'Month Year' format

          if (monthYear) {
            uniqueMonths.add(monthYear); // Add to the Set (duplicates will be ignored)
          }
        });

        this.monthList = Array.from(uniqueMonths); // Convert the Set back to an array
        // await this.getBMI();
        // this.calculatePercentages();  // Ensure this runs after data is fetched
      },
      (error) => {
      }
    );
  }

  getSportsPerformaceData() {
    this.assessmentService.getSportsPerformaceDataByAthleteId(this.UserId).subscribe(
      async (response: any) => {
        this.sportsData = response;
        this.sports = response && response.length > 0 ? response.slice(-1)[0] : null;
        this.assessmentService.getsportPerformanceByAsm(this.UserId,this.assessmentId).subscribe(
          (data) => {
            this.sports = data[0];
          },
          (error) => {

          }
        );

        const uniqueMonths = new Set<string>(); // Use a Set to avoid duplicates

        response.forEach((item: any) => {
          const date = new Date(item.spaDate); // Convert the string to a Date object
          const monthYear = this.datePipe.transform(date, 'MMMM'); // Convert to 'Month Year' format

          if (monthYear) {
            uniqueMonths.add(monthYear); // Add to the Set (duplicates will be ignored)
          }
        });

        this.monthList = Array.from(uniqueMonths); // Convert the Set back to an array
        // await this.getBMI();
        await this.calculateSPAPercentages();
      },
      (error) => {

      }
    );
  }

  async getFitnessDataByAthleteAndOrg() {
    try {
        // Fetch the entire organization's fitness data
        const orgFitnessData = await this.assessmentService.getPhysicalFitnessDetail(this.org).toPromise();

        // Filter the data for the specific athlete (user)
        const athleteId = this.UserId;  // Assuming this.UserId contains the athleteId
        const filteredFitnessData = orgFitnessData.filter((item: any) => item.pfaAthlete == athleteId);
        var FitnessData;

        // Get the most recent fitness record for this athlete (if any)
        if (this.selectedMonth === '') {
            this.fitnessReport = filteredFitnessData && filteredFitnessData.length > 0 ? filteredFitnessData.slice(-1)[0] : null;
            FitnessData = orgFitnessData.filter((item: any) => item.pfaAssessment == this.assessmentId);
        } else {
            FitnessData = filteredFitnessData.filter((item: any) => {
                const date = new Date(item.pfaDate); 
                const monthYear = this.datePipe.transform(date, 'MMMM'); 
                return monthYear === this.selectedMonth;
            });
            this.fitnessReport = FitnessData[0];
        }

        if (this.fitnessReport) {
            const athleteGender = this.userDetail[0].usrGender;
            const age = this.calculateAge(this.fitnessReport.usrDoB);
            const sport = this.Sports.filter((item: any) => item.sportName == this.userDetail[0].asiPrimarySport);

           await this.leveloneRefrences(age, athleteGender, sport[0].sportId);

           await this.InterNationalBenchMark(athleteGender,"11");

            // Calculate Z-Scores for this user based on their gender
           await this.calculateZScoresByGender(FitnessData, athleteGender);
        } else {
            console.log('No fitness data found for this athlete.');
        }
    } catch (error) {

    }
}

calculateSleepPercentage(sleepData: any): number {
  let totalScore = 0;
  let maxScore = 0;

  // Define values for each question's possible answers
  const answer1Score = this.getHoursOfSleepScore(sleepData.answer1);
  const answer2Score = this.getSleepQualityScore(sleepData.answer2);
  const answer3Score = this.getNapsScore(sleepData.answer3);
  const answer4Score = this.getNapDurationScore(sleepData.answer4);

  // Sum up the total score
  totalScore += answer1Score + answer2Score + answer3Score + answer4Score;

  // Define max possible score (adjust based on your scoring logic)
  maxScore = 100; // You can change this based on how many questions you ask and their weight

  // Calculate percentage
  const percentage = (totalScore / maxScore) * 100;

  this.SleepandRest = parseFloat(percentage.toFixed(2));

  return Math.min(percentage, 100); // Cap at 100%
}

// Example methods to return scores based on answers
getHoursOfSleepScore(answer: string): number {
  if (answer === 'More than 8 hours') {
    return 25;
  } else if (answer === '7-8 hours') {
    return 20;
  } else if (answer === '6-7 hours') {
    return 15;
  } else if (answer === '5-6 hours') {
    return 10;
  } else {
    return 5;
  }
}

getSleepQualityScore(answer: string): number {
  if (answer === 'Excellent') {
    return 25;
  } else if (answer === 'Very Good') {
    return 20;
  } else if (answer === 'Good') {
    return 15;
  }else if (answer === 'Fair') {
    return 10;
  } else {
    return 5;
  }
}

getNapsScore(answer: string): number {
  if (answer === 'Yes') {
    return 25;
  } else {
    return 10;
  }
}

getNapDurationScore(answer: string): number {
  if (answer === 'More than 60 minutes') {
    return 25;
  }else if (answer === '40-60 minutes') {
    return 15;
  } else if (answer === '20-40 minutes') {
    return 10;
  } else {
    return 5;
  }
}
calculateHydrationPercentage(hydrationData: any): number {
  let totalScore = 0;
  let maxScore = 0;

  // Define values for each question's possible answers
  const answer1Score = this.getMealOrSnackScore(hydrationData.answer1);
  const answer2Score = this.getMealQualityScore(hydrationData.answer2);
  const answer3Score = this.getWaterIntakeScore(hydrationData.answer3);
  const answer4Score = this.getSupplementScore(hydrationData.answer4);

  // Sum up the total score
  totalScore += answer1Score + answer2Score + answer3Score + answer4Score;

  // Define max possible score (adjust based on your scoring logic)
  maxScore = 100; // Assuming each question can contribute equally

  // Calculate percentage
  const percentage = (totalScore / maxScore) * 100;

  this.hydration = parseFloat(percentage.toFixed(2));

  return Math.min(percentage, 100); // Cap at 100%
}

// Example methods to return scores based on answers
getMealOrSnackScore(answer: string): number {
  return answer === 'Yes' ? 25 : 0;
}

getMealQualityScore(answer: string): number {
  if (answer === 'Excellent') {
    return 25;
  } else if (answer === 'Good') {
    return 15;
  } else if (answer === 'Average') {
    return 10;
  } else {
    return 0;
  }
}

getWaterIntakeScore(answer: string): number {
  if (answer === 'More than 1.5 litres') {
    return 25;
  } else if (answer === '1-1.5 litres') {
    return 15;
  } else if (answer === '500 ml - 1 liter') {
    return 5;
  } else {
    return 0;
  }
}

getSupplementScore(answer: string): number {
  return answer === 'Yes' ? 0 : 25; // Adjust logic if needed
}


async getNutritionalTest(asmId:any){
  this.assessmentService.getSleepandRestByAsm(this.UserId, asmId).subscribe(response => {
    if (response.length > 0) {
      const sleepData = response[0];
      this.calculateSleepPercentage(sleepData);

      // Structure the questions and answers into JSON format
      const sleepJson = {
        questions: [
          {
            question: 'How many hours of sleep do you usually get per night?',
            answer: sleepData.answer1 || ''
          },
          {
            question: 'How would you rate the quality of your sleep?',
            answer: sleepData.answer2 || ''
          },
          {
            question: 'Do you take naps during the day to help with recovery?',
            answer: sleepData.answer3 || ''
          },
          {
            question: 'If yes, how long are your naps?',
            answer: sleepData.answer4 || ''
          }
        ]
      };

      const jsonStr = JSON.stringify(sleepJson, null, 2);
      // this.getSleepandRest(jsonStr);
    }
  }, error => {

  });

  this.assessmentService.getHydrationandNutritionByAsm(this.UserId, asmId).subscribe(response => {
    if (response.length > 0) {
      const hydrationData = response[0];
      this.calculateHydrationPercentage(hydrationData);

      // Structure the questions and answers into JSON format
      const hydrationJson = {
        questions: [
          {
            "question": "Do you consume a meal or snack rich in protein and carbohydrates within 30-60 minutes after training or competition?",
            "answer": hydrationData.answer1 || ''
          },
          {
            "question": "How would you rate the quality of your post-training/competition meals?",
            "answer": hydrationData.answer1 || ''
          },
          {
            "question": "How much water or sports drinks do you consume post-training?",
            "answer": hydrationData.answer1 || ''
          },
          {
            "question": "Do you use any supplements (e.g., protein, electrolytes) for recovery?",
            "answer": hydrationData.answer1 || ''
          }
        ]
      };

      const jsonStr = JSON.stringify(hydrationJson, null, 2);
      //  this.getHydration(jsonStr);
    }
  }, error => {

  });


  this.assessmentService.getRecallAssessments(this.UserId, asmId).subscribe(response => {
    if (response.length > 0) {
      const recallData = response[0];


      // Structure the questions and answers into JSON format
      const hydrationJson = {
        questions: [

          {
        
            "question": "What time did you wake up yesterday?",
        
            "answer": recallData.rcaQ1 || ''
        
          },
        
          {
        
            "question": "What time did you go to bed last night?",
        
            "answer": recallData.rcaQ2 || ''
        
          },
        
          {
        
            "question": "Did you have any unusual activities or events yesterday that might have influenced your eating habits?",
        
            "answer": recallData.rcaQ3 || ''
        
          },
        
          {
        
            "question": "If yes, please specify: ",
        
            "answer": recallData.rcaQ4 || ''
        
          },
        
          {
        
            "question": "Breakfast - Time:",
        
            "answer": recallData.rcaQ5 || ''
        
          },
        
          {
        
            "question": "Breakfast - Food/Beverage Consumed:",
        
            "answer": recallData.rcaQ6 || ''
        
          },
        
          {
        
            "question": "Breakfast - Quantity:",
        
            "answer": recallData.rcaQ7 || ''
        
          },
        
          {
        
            "question": "Breakfast - Any additional details (e.g., condiments, sides, drinks):",
        
            "answer": recallData.rcaQ8 || ''
        
          },
        
          {
        
            "question": "Morning Snack - Time:",
        
            "answer": recallData.rcaQ9 || ''
        
          },
        
          {
        
            "question": "Morning Snack - Food/Beverage Consumed:",
        
            "answer": recallData.rcaQ10 || ''
        
          },
        
          {
        
            "question": "Morning Snack - Quantity:",
        
            "answer": recallData.rcaQ11 || ''
        
          },
        
          {
        
            "question": "Morning Snack - Any additional details:",
        
            "answer": recallData.rcaQ12 || ''
        
          },
        
          {
        
            "question": "Lunch - Time:",
        
            "answer": recallData.rcaQ13 || ''
        
          },
        
          {
        
            "question": "Lunch - Food/Beverage Consumed:",
        
            "answer": recallData.rcaQ14 || ''
        
          },
        
          {
        
            "question": "Lunch - Quantity:",
        
            "answer": recallData.rcaQ15 || ''
        
          },
        
          {
        
            "question": "Lunch - Any additional details:",
        
            "answer": recallData.rcaQ16 || ''
        
          },
        
          {
        
            "question": "Afternoon Snack - Time:",
        
            "answer": recallData.rcaQ17 || ''
        
          },
        
          {
        
            "question": "Afternoon Snack - Food/Beverage Consumed:",
        
            "answer": recallData.rcaQ18 || ''
        
          },
        
          {
        
            "question": "Afternoon Snack - Quantity:",
        
            "answer": recallData.rcaQ19 || ''
        
          },
        
          {
        
            "question": "Afternoon Snack - Any additional details:",
        
            "answer": recallData.rcaQ20 || ''
        
          },
        
          {
        
            "question": "Dinner - Time:",
        
            "answer": recallData.rcaQ21 || ''
        
          },
        
          {
        
            "question": "Dinner - Food/Beverage Consumed:",
        
            "answer": recallData.rcaQ22 || ''
        
          },
        
          {
        
            "question": "Dinner - Quantity:",
        
            "answer": recallData.rcaQ23 || ''
        
          },
        
          {
        
            "question": "Dinner - Any additional details:",
        
            "answer": recallData.rcaQ24 || ''
        
          },
        
          {
        
            "question": "Evening Snack - Time:",
        
            "answer": recallData.rcaQ25 || ''
        
          },
        
          {
        
            "question": "Evening Snack - Food/Beverage Consumed:",
        
            "answer": recallData.rcaQ26 || ''
        
          },
        
          {
        
            "question": "Evening Snack - Quantity:",
        
            "answer": recallData.rcaQ27 || ''
        
          },
        
          {
        
            "question": "Evening Snack - Any additional details:",
        
            "answer": recallData.rcaQ28 || ''
        
          },
        
          {
        
            "question": "How much water did you drink yesterday?",
        
            "answer": recallData.rcaQ29 || ''
        
          },
        
          {
        
            "question": "Did you consume any of the following beverages yesterday? (Check all that apply)",
        
            "answer": {
        
              "Coffee": recallData.rcaQ30 === 'Coffee' ? recallData.rcaQ31 && recallData.rcaQ32 && recallData.rcaQ33 : '',
        
              "Tea": recallData.rcaQ34 === 'Tea' ? recallData.rcaQ35 && recallData.rcaQ36 && recallData.rcaQ37 : '',
        
              "Sports Drinks": recallData.rcaQ38,
        
              "Soft Drinks": recallData.rcaQ39 === 'Soft Drinks' ? recallData.rcaQ40 && recallData.rcaQ41 : '',
        
              "Juice": recallData.rcaQ42 || '',
        
              "Alcohol": recallData.rcaQ43 || '',
        
              "Milk": recallData.rcaQ44 === 'Milk' ? recallData.rcaQ45 && recallData.rcaQ46 && recallData.rcaQ47 && recallData.rcaQ48 : ''
        
            }
        
          },
        
          {
        
            "question": "Did you consume any supplements (protein, vitamins, etc.)? If yes, please specify the type and dosage.",
        
            "answer": recallData.rcaQ49 || ''
        
          },
          {
        
            "question": "Details ",
        
            "answer": recallData.rca50 || ''
        
          },
        
          {
        
            "question": "How would you rate the balance of your meals (e.g., protein, carbs, fats)?",
        
            "answer": recallData.rcaQ51 || ''
        
          },
        
          {
        
            "question": "Did you feel satisfied or hungry after your meals?",
        
            "answer": recallData.rcaQ52 || ''
        
          },
        
          {
        
            "question": "Did you eat before or after any physical activity yesterday?",
        
            "answer": recallData.rcaQ53 || ''
        
          },
        
          {
        
            "question": "If yes, what did you consume before and/or after your physical activity?Pre-Activity",
        
            "answer": recallData.rcaQ54 || ''
        
          }
        
        ]
      };

      const jsonStr = JSON.stringify(hydrationJson, null, 2);
      this.get24Questionary(jsonStr);
    }
  }, error => {

  });


}


getSleepandRest(Data:any) {
  const message = `in this data give me the overall score using below scoring system which is for maximum of 25 and scale it for 100
  1. How many hours of sleep do you usually get per night?"

8+ hours: 10 points
7-8 hours: 8 points
6-7 hours: 6 points
Less than 6 hours: 3 points
2. "How would you rate the quality of your sleep?"

Excellent: 10 points
Good: 8 points
Average: 6 points
Fair: 4 points
Poor: 2 points

3. "Do you take naps during the day to help with recovery?"

Yes: 5 points
No: 5 points (since not everyone needs naps if they get sufficient sleep)
"If yes, how long are your naps?" (Only applicable if "Yes" to naps)

4. Less than 30 minutes: 5 points (ideal nap duration)
30 minutes to 1 hour: 4 points
1-2 hours: 3 points
More than 2 hours: 2 points
  `
  const combinedMessage = `
    {
      "data": ${Data},
      "message": "${message}"
    }
  `;
   this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
    const chatContent = chatResponse?.choices?.[0]?.message?.content;
    if (chatContent) {
      
      try {
        this.SleepandRest = chatContent;
       
      } catch (error) {
        this.chat = chatContent;

      }
    } else {

    }

  });
}

getHydration(Data:any) {
  const message = "in this data analyse and give me the overall score out of 100 for hydration";
  const combinedMessage = `
    {
      "data": ${Data},
      "message": "${message}"
    }
  `;
   this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
    const chatContent = chatResponse?.choices?.[0]?.message?.content;
    if (chatContent) {
      
      try {
        this.hydration = chatContent;
       
      } catch (error) {
        this.chat = chatContent;

      }
    } else {

    }

  });
}

get24Questionary(jsonStr: string) {
  // Define scoring function
  const calculateScore = (data: any) => {
    let score = 100;

    // 1. Meal Timing Consistency (20 points)
    const expectedMealTimes = {
      breakfast: [5, 10], // Between 5 AM to 10 AM
      lunch: [11, 15], // Between 11 AM to 3 PM
      dinner: [17, 21], // Between 5 PM to 9 PM
    };
    
    const meals = data.questions.filter((q:any) => q.question.includes("Time"));
    
    meals.forEach((meal:any) => {
      const timeParts = meal.answer.split(':');
      const hour = parseInt(timeParts[0], 10);
      if (meal.question.includes("Breakfast") && (hour < expectedMealTimes.breakfast[0] || hour > expectedMealTimes.breakfast[1])) {
        score -= 4; // Deduct for breakfast at an odd time
      }
      if (meal.question.includes("Lunch") && (hour < expectedMealTimes.lunch[0] || hour > expectedMealTimes.lunch[1])) {
        score -= 4; // Deduct for lunch at an odd time
      }
      if (meal.question.includes("Dinner") && (hour < expectedMealTimes.dinner[0] || hour > expectedMealTimes.dinner[1])) {
        score -= 4; // Deduct for dinner at an odd time
      }
    });

    // 2. Food/Beverage Consumption (40 points)
    const foodEntries = data.questions.filter((q:any) => q.question.includes("Food/Beverage Consumed"));
    foodEntries.forEach((entry:any) => {
      if (entry.answer.toLowerCase() === "no" || entry.answer === "") {
        score -= 8; // Deduct for each skipped meal
      }
    });

    // 3. Water Intake (20 points)
    const waterEntry = data.questions.find((q:any) => q.question.includes("How much water"));
    if (waterEntry && waterEntry.answer.includes("1-2 liters")) {
      score -= 5; // Deduct for not reaching optimal water intake (2-3 liters)
    }

    // 4. Meal Balance (10 points)
    const balanceEntry = data.questions.find((q:any) => q.question.includes("How would you rate the balance"));
    if (!balanceEntry || balanceEntry.answer === "") {
      score -= 10; // Deduct for no information about meal balance
    }

    // 5. Data Consistency and Missing Information (10 points)
    const missingInfo = data.questions.filter((q:any) => q.answer === "");
    if (missingInfo.length > 0) {
      score -= 5; // Deduct for missing information
    }

    return score;
  };

  // Combine data and message into the request payload
  const combinedMessage = `
    {
      "data": ${jsonStr},
      "message": "Please analyze the data and calculate an overall nutrition score."
    }
  `;
  
  const score = calculateScore(JSON.parse(jsonStr));
  this.questionary = score;
  // Call the chat service to get the response with nutrition score
  // this.chatService.getChatResponse(combinedMessage).subscribe(async (chatResponse: any) => {
  //   const chatContent = chatResponse?.choices?.[0]?.message?.content;
    
  //   if (chatContent) {
  //     try {
        
  //       const score = calculateScore(JSON.parse(jsonStr));
  //       this.questionary = score;
  //       console.log(`Overall nutrition score: ${score}/100`, "plan");
  //     } catch (error) {
  //       this.chat = chatContent;
  //       console.error('Error parsing chat response JSON:', error);
  //     }
  //   } else {
  //     console.error('Unexpected chat response structure:', chatResponse);
  //   }
  // });
}


async getNutritionDataByAthleteAndOrg() {
  try {
    // Fetch the entire organization's nutrition data
    const orgNutritionData = await this.assessmentService.getNutritional(this.org).toPromise();

    // Filter the data for the specific athlete (user)
    const athleteId = this.UserId; // Assuming this.UserId contains the athleteId
    const filteredNutritionData = orgNutritionData.filter((item: any) => item.naAthlete == athleteId);
    let NutritionData;

    // Get the most recent nutrition record for this athlete (if any)
    if (this.selectedMonth === '') {
      this.nutritionReport = filteredNutritionData && filteredNutritionData.length > 0 ? filteredNutritionData.slice(-1)[0] : null;
      NutritionData = orgNutritionData.filter((item: any) => item.naAssessment == this.assessmentId);
    } else {
      NutritionData = filteredNutritionData.filter((item: any) => {
        const date = new Date(item.naDate); // Convert `naDate` to Date object
        const monthYear = this.datePipe.transform(date, 'MMMM'); // Get 'Month Year' format
        return monthYear === this.selectedMonth; // Check if it matches the selected month
      });
      this.nutritionReport = NutritionData[0];
    }

    if (this.nutritionReport) {

      const athleteGender = this.userDetail[0].usrGender;
      const age = this.calculateAge(this.nutritionReport.usrDoB); // Assuming age calculation is required
      const sport = this.userDetail[0].usrSport;

      this.leveloneReference(age, athleteGender, sport); // Similar reference logic if needed for nutrition

      // Calculate Z-Scores for this user based on their gender
      await this.calculateNutritionZScoresByGender(NutritionData, athleteGender);
    } else {
      console.log('No nutrition data found for this athlete.');
    }
  } catch (error) {

  }
}

calculateNutritionZScoresByGender(orgNutritionData: any[], athleteGender: string) {
  const calculateMean = (values: number[]): number => {
    const sum = values.reduce((acc, value) => acc + value, 0);
    return values.length > 0 ? sum / values.length : 0;
  };

  const calculateStandardDeviation = (values: number[], mean: number): number => {
    const variance = values.reduce((acc, value) => acc + Math.pow(value - mean, 2), 0) / values.length;
    return Math.sqrt(variance);
  };

  const calculateZScore = (value: number, mean: number, stdDev: number): number | null => {
    return stdDev === 0 ? null : (value - mean) / stdDev;
  };

  const genderSpecificData = orgNutritionData.filter((item: any) => item.usrGender === athleteGender);

  const bodyWeights = genderSpecificData.map(item => item.naBodyWeight);
  const bodyHeights = genderSpecificData.map(item => item.naBodyHeight);
  const bmis = genderSpecificData.map(item => item.naBMI);
  const bodyFats = genderSpecificData.map(item => item.naBodyFat);
  const fatFreeWeights = genderSpecificData.map(item => item.naFatFreeWeight);
  const visceralFats = genderSpecificData.map(item => item.naVisceralFat);
  const skeletalMuscles = genderSpecificData.map(item => item.naSkeletalMuscle);

  const bodyWeightMean = calculateMean(bodyWeights);
  const bodyHeightMean = calculateMean(bodyHeights);
  const bmiMean = calculateMean(bmis);
  const bodyFatMean = calculateMean(bodyFats);
  const fatFreeWeightMean = calculateMean(fatFreeWeights);
  const visceralFatMean = calculateMean(visceralFats);
  const skeletalMuscleMean = calculateMean(skeletalMuscles);

  const bodyWeightStdDev = calculateStandardDeviation(bodyWeights, bodyWeightMean);
  const bodyHeightStdDev = calculateStandardDeviation(bodyHeights, bodyHeightMean);
  const bmiStdDev = calculateStandardDeviation(bmis, bmiMean);
  const bodyFatStdDev = calculateStandardDeviation(bodyFats, bodyFatMean);
  const fatFreeWeightStdDev = calculateStandardDeviation(fatFreeWeights, fatFreeWeightMean);
  const visceralFatStdDev = calculateStandardDeviation(visceralFats, visceralFatMean);
  const skeletalMuscleStdDev = calculateStandardDeviation(skeletalMuscles, skeletalMuscleMean);

  this.nutritionReport.bodyWeightZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naBodyWeight, bodyWeightMean, bodyWeightStdDev));
  this.nutritionReport.bodyHeightZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naBodyHeight, bodyHeightMean, bodyHeightStdDev));
  this.nutritionReport.bmiZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naBMI, bmiMean, bmiStdDev));
  this.nutritionReport.bodyFatZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naBodyFat, bodyFatMean, bodyFatStdDev));
  this.nutritionReport.fatFreeWeightZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naFatFreeWeight, fatFreeWeightMean, fatFreeWeightStdDev));
  this.nutritionReport.visceralFatZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naVisceralFat, visceralFatMean, visceralFatStdDev));
  this.nutritionReport.skeletalMuscleZScorePercent = this.zScoreToPercent(calculateZScore(this.nutritionReport.naSkeletalMuscle, skeletalMuscleMean, skeletalMuscleStdDev));
}

  async getPerformanceDataByAthleteAndOrg() {
    try {
      // Fetch the entire organization's performance data
      const orgPerformanceData = await this.assessmentService.getSportsperformanceByOrg(this.org).toPromise();
  
      // Filter the data for the specific athlete (user)
      const athleteId = this.UserId; // Assuming this.UserId contains the athleteId
      const filteredPerformanceData = orgPerformanceData.filter((item: any) => item.spaAthlete == athleteId);
      
      var performanceData;
      
      // Get the most recent performance record for this athlete (if any)
      if (this.selectedMonth === '') {
        this.performanceReport = filteredPerformanceData && filteredPerformanceData.length > 0 ? filteredPerformanceData.slice(-1)[0] : null;
        performanceData = orgPerformanceData.filter((item: any) => item.spaAssessment == this.performanceReport.spaAssessment);
      } else {
        performanceData = filteredPerformanceData.filter((item: any) => {
          const date = new Date(item.spaDate);
          const monthYear = this.datePipe.transform(date, 'MMMM');
          return monthYear === this.selectedMonth;
        });
        this.performanceReport = performanceData[0];
      }
      
      if (this.performanceReport) {
  
        const athleteGender = this.userDetail[0].usrGender;
        const age = this.calculateAge(this.performanceReport.usrDoB);
        const sport = this.userDetail[0].usrSport;
  
        // Call your leveloneReference or any other related function based on age, gender, and sport
        this.leveloneReference(age, athleteGender, sport);
  
        // Calculate Z-Scores for performance metrics by gender
       await this.calculateZScoresForPerformance(performanceData, athleteGender);
      } else {

      }
    } catch (error) {

    }
  }

  calculateZScoresForPerformance(orgPerformanceData: any[], athleteGender: string) {
    const calculateMean = (values: number[]): number => {
      const sum = values.reduce((acc, value) => acc + value, 0);
      return values.length > 0 ? sum / values.length : 0;
    };
  
    const calculateStandardDeviation = (values: number[], mean: number): number => {
      const variance = values.reduce((acc, value) => acc + Math.pow(value - mean, 2), 0) / values.length;
      return Math.sqrt(variance);
    };
  
    const calculateZScore = (value: number, mean: number, stdDev: number): number | null => {
      return stdDev === 0 ? null : (value - mean) / stdDev;
    };
  
    const genderSpecificData = orgPerformanceData.filter((item: any) => item.usrGender === athleteGender);
  
    const averageTorqueCRValues = genderSpecificData.map(item => parseFloat(item.spaAverageTorqueCR.split('/')[0]));
    const averageTorqueCLValues = genderSpecificData.map(item => parseFloat(item.spaAverageTorqueCL.split('/')[0]));
    const jumpHeights = genderSpecificData.map(item => item.spaJumpHeight);
    const velocities = genderSpecificData.map(item => item.spaVelocity);
    const forces = genderSpecificData.map(item => item.spaForce);
    const powers = genderSpecificData.map(item => item.spaPower);
  
    const averageTorqueCRMean = calculateMean(averageTorqueCRValues);
    const averageTorqueCLMean = calculateMean(averageTorqueCLValues);
    const jumpHeightMean = calculateMean(jumpHeights);
    const velocityMean = calculateMean(velocities);
    const forceMean = calculateMean(forces);
    const powerMean = calculateMean(powers);
  
    const averageTorqueCRStdDev = calculateStandardDeviation(averageTorqueCRValues, averageTorqueCRMean);
    const averageTorqueCLStdDev = calculateStandardDeviation(averageTorqueCLValues, averageTorqueCLMean);
    const jumpHeightStdDev = calculateStandardDeviation(jumpHeights, jumpHeightMean);
    const velocityStdDev = calculateStandardDeviation(velocities, velocityMean);
    const forceStdDev = calculateStandardDeviation(forces, forceMean);
    const powerStdDev = calculateStandardDeviation(powers, powerMean);
  
    this.performanceReport.averageTorqueCRZScorePercent = this.zScoreToPercent(calculateZScore(this.performanceReport.spaAverageTorqueCR.split('/')[0], averageTorqueCRMean, averageTorqueCRStdDev));
    this.performanceReport.averageTorqueCLZScorePercent = this.zScoreToPercent(calculateZScore(this.performanceReport.spaAverageTorqueCL.split('/')[0], averageTorqueCLMean, averageTorqueCLStdDev));
    this.performanceReport.jumpHeightZScorePercent = this.zScoreToPercent(calculateZScore(this.performanceReport.spaJumpHeight, jumpHeightMean, jumpHeightStdDev));
    this.performanceReport.velocityZScorePercent = this.zScoreToPercent(calculateZScore(this.performanceReport.spaVelocity, velocityMean, velocityStdDev));
    this.performanceReport.forceZScorePercent = this.zScoreToPercent(calculateZScore(this.performanceReport.spaForce, forceMean, forceStdDev));
    this.performanceReport.powerZScorePercent = this.zScoreToPercent(calculateZScore(this.performanceReport.spaPower, powerMean, powerStdDev));
  
  }
  
  

  leveloneRefrences(age: number, athleteGender: any, sport: any) {
    this.assessmentService.levelonereference(age, athleteGender, sport).subscribe(response => {

      this.leveloneReference = response;

    });
  }

  InterNationalBenchMark(age: number, sport: any) {
    this.assessmentService.getInterNationalBenchMark(age, sport).subscribe(response => {

      this.benchMarkReference = response;

    });
  }

  async getCognitiveDataByAthleteAndOrg() {
    try {
      // Fetch the entire organization's cognitive data
      const orgCognitiveData = await this.assessmentService.getcognitiveDetail(this.org).toPromise();

      // Filter the data for the specific athlete (user)
      const athleteId = this.UserId; // Assuming this.UserId contains the athleteId
      const filteredCognitiveData = orgCognitiveData.filter((item: any) => item.cnaAthlete == athleteId);

      // Get the most recent cognitive record for this athlete (if any)
     
      if (this.selectedMonth === '') {
        this.cognitiveReport = filteredCognitiveData && filteredCognitiveData.length > 0 ? filteredCognitiveData.slice(-1)[0] : null;
      } else {
        this.cognitiveReport = filteredCognitiveData.filter((item: any) => {
          const date = new Date(item.cnaCreatedOn); // Convert `coaCreatedOn` to Date object
          const monthYear = this.datePipe.transform(date, 'MMMM'); // Get 'Month Year' format
          return monthYear === this.selectedMonth; // Check if it matches the selected month
        });
        this.cognitiveReport = this.cognitiveReport[0];
      }
      if (this.cognitiveReport) {
        // Calculate Z-Scores for this user based on their gender
        this.calculateCognitiveZScoresByGender(orgCognitiveData, this.userDetail[0].usrGender);
      } else {
     
      }
    } catch (error) {

    }
  }

  calculateAge(dob: string): number {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  getBMI() {
    const weight = this.nutrition.naBodyWeight;  // Replace with actual weight field from fitness data
    const height = this.nutrition.naBodyHeight;  // Replace with actual height field from fitness data

    if (weight && height) {
      const bmi = this.calculateBMI(weight, height);
    } else {
      console.log('Weight or height data is missing.');
    }
  }
  calculateBMI(weight: number, height: number): number | null {
    if (weight && height) {
      const heightInMeters = height / 100;  // Assuming height is in cm, convert to meters
      const bmi = weight / (heightInMeters * heightInMeters);
      this.nutrition.naBMI = parseFloat(bmi.toFixed(2));
      return parseFloat(bmi.toFixed(2)); // Return BMI rounded to 2 decimal places
    }
    return null;  // If weight or height is not provided, return null
  }

  getCognitiveData() {
    this.assessmentService.getcognitiveDetaillist(this.UserId).subscribe(
      async (response: any) => {
        this.cognitiveData = response;
        this.cognitive = response && response.length > 0 ? response.slice(-1)[0] : null;

        this.assessmentService.getCognitiveReportByAsm(this.UserId,this.assessmentId).subscribe(
          (data) => {
            this.cognitive = data[0];
          },
          (error) => {
    
          }
        );

        await this.calculateCognitivePercentages();
      },
      (error) => {
      
      }
    );
  }

  getCardioData() {
    this.assessmentService.getcardioByAthleteId(this.UserId).subscribe(
      (response: any) => {
        this.cardioData = response;
        this.cardio = response && response.length > 0 ? response.slice(-1)[0] : null;

        this.assessmentService.getcardioByAsm(this.UserId,this.assessmentId).subscribe(
          (data) => {
            this.cardio = data[0];
          },
          (error) => {
        
          }
        );

      },
      (error) => {
   
      }
    );
  }

   // Function to filter cardio data based on the selected month
   filterCardioByMonth() {
    if (this.selectedMonth === '') {
      // If no month is selected, return the entire dataset
      this.cardio = this.cardio;
    } else {
     
    }
  }

  async filterReportByMonth() {
    if (this.selectedMonth === '') {
      // If no month is selected, return the entire dataset
      this.fitness = this.fitness;
      this.cardio = this.cardio;
      this.cognitive = this.cognitive;
    } else {
      this.fitness = this.fitnessData.filter((item: any) => {
        const date = new Date(item.pfaDate); // Convert `coaCreatedOn` to Date object
        const monthYear = this.datePipe.transform(date, 'MMMM'); // Get 'Month Year' format
        return monthYear === this.selectedMonth; // Check if it matches the selected month
      });
      this.cardio = this.cardioData.filter((item: any) => {
        const date = new Date(item.coaCreatedOn); // Convert `coaCreatedOn` to Date object
        const monthYear = this.datePipe.transform(date, 'MMMM'); // Get 'Month Year' format
        return monthYear === this.selectedMonth; // Check if it matches the selected month
      });
      this.cognitive = this.cognitiveData.filter((item: any) => {
        const date = new Date(item.cnaCreatedOn); // Convert `coaCreatedOn` to Date object
        const monthYear = this.datePipe.transform(date, 'MMMM'); // Get 'Month Year' format
        return monthYear === this.selectedMonth; // Check if it matches the selected month
      });
      this.fitness =this.fitness[0] ?? this.fitness;
      this.cardio = this.cardio[0] ?? this.cardio;
      this.cognitive = this.cognitive[0] ?? this.cognitive;
      await this.getBMI();
      this.calculatePercentages(); 
      this.calculateCognitivePercentages();
      await this.getFitnessDataByAthleteAndOrg();
      await this.getCognitiveDataByAthleteAndOrg();
      await this.getCardioDataByAthleteAndOrg();
      await this.getLorMarkRangeForEachParameter();
      await this.calculateOverallPercentage();
      await this.summaryDetails();
    }
  }
  

  async getCardioDataByAthleteAndOrg() {
    try {
      // Fetch the entire organization's cardio data
      const orgCardioData = await this.assessmentService.getcardioetaillist(this.org).toPromise();

      // Filter the data for the specific athlete (user)
      const athleteId = this.UserId; // Assuming this.UserId contains the athleteId
      const filteredCardioData = orgCardioData.filter((item: any) => item.coaAthlete == athleteId);

      // Get the most recent cardio record for this athlete (if any)
      
      if (this.selectedMonth === '') {
        this.cardioReport = filteredCardioData && filteredCardioData.length > 0 ? filteredCardioData.slice(-1)[0] : null;
      } else {
        this.cardioReport = filteredCardioData.filter((item: any) => {
          const date = new Date(item.coaCreatedOn); // Convert `coaCreatedOn` to Date object
          const monthYear = this.datePipe.transform(date, 'MMMM'); // Get 'Month Year' format
          return monthYear === this.selectedMonth; // Check if it matches the selected month
        });
        this.cardioReport = this.cardioReport[0];
      }
      if (this.cardioReport) {

        // Calculate Z-Scores for this user based on their gender
        this.calculateCardioZScoresByGender(orgCardioData, this.userDetail[0].usrGender);
      } else {
   
      }
    } catch (error) {
     
    }
  }


  getUser() {
      this.userRegisterService.fetchUser(this.UserId).subscribe(
        (response: any) => {
          this.userDetail = response;
        this.age = this.calculateAge(this.userDetail[0].usrDoB);
        this.assessmentService.getTrainer().subscribe(
          (data) => {
            this.coachName = data.filter((item: any) => item.usrId == this.userDetail[0]?.asiCoach);
          },
          (error) => {
        
          }
        );
        this.assessmentService.getTeams().subscribe(
          (data) => {
            this.allTeams = data.filter((item: any) => item.teamId == this.userDetail[0]?.asiCurrentClubTeam);; // Save original data
          },
          (error) => {
       
          }
        );
      },
      (error) => {
      
      }
    );
  }

  async fetchAssessments() {
    // Fetch assessments from your API or set static values
    this.assessmentService.getAllAssessment().subscribe(response => {
      const filteredCardioData = response.filter((item: any) => item.asmOrg == this.org);
      const assessment = filteredCardioData && filteredCardioData.length > 0 ? filteredCardioData.slice(-1)[0] : null;
      this.assessmentId = assessment[0].asmId;
    }, error => {
 
    });
  }

  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(this.UserId).subscribe(
      (response: any) => {
        if (response ) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
        }
      },
      (error: any) => {
        this.showImage = true;
        this.assessmentService.getImage(this.UserId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
          }
      );
      }
    );
  }

  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    
    if (DATA) {

      // Get full content dimensions (including scrollable areas)
      const componentWidth = DATA.offsetWidth;
      const componentHeight = DATA.scrollHeight;
  
      // Convert pixels to millimeters for jsPDF (1px = 0.264583 mm)
      const pdfWidth = componentWidth * 0.264583;
      const pdfHeight = componentHeight * 0.264583;
  
      // Create a new jsPDF document with custom page dimensions
      const pdf = new jsPDF({
        orientation: pdfWidth > pdfHeight ? 'l' : 'p', // Landscape or Portrait based on content dimensions
        unit: 'mm',
        format: [pdfWidth, pdfHeight], // Set custom page size
      });
  
      // Add the HTML content to the PDF
      pdf.html(DATA, {
        callback: (doc) => {
          // Save the generated PDF with a filename
          doc.save('Report-details.pdf');
        },
        x: 0, // X position (left padding, adjust if needed)
        y: 0, // Y position (top padding, adjust if needed)
        width: pdfWidth, // Ensure it uses the full width of the PDF
        windowWidth: componentWidth // Use the full width of the HTML content
      });
    }
  }
  
  calculatePercentage(testValue: number | null, referenceValue: number): number | null {
    if (testValue !== null) {
      let percentage = (testValue / referenceValue) * 100;
  
      // Clamp percentage between 0 and 100
      percentage = Math.min(Math.max(percentage, 0), 100);
  
      // Round to the nearest whole number
      return Math.round(percentage);
    }
    return null;
  }

  calculatePercentages(): void {
    if (this.fitness) {
      this.percentages = {
        pfa50mSprint: this.calculatePercentage(this.fitness.pfa50mSprint, this.referenceValues.pfa50mSprint),
        pfa30mSprint: this.calculatePercentage(this.fitness.pfa30mSprint, this.referenceValues.pfa30mSprint),
        pfa10mShuttleRun: this.calculatePercentage(this.fitness.pfa10mShuttleRun, this.referenceValues.pfa10mShuttleRun),
        pfaTTest: this.calculatePercentage(this.fitness.pfaTTest, this.referenceValues.pfaTTest),
        pfaStandingBroadJump: this.calculatePercentage(this.fitness.pfaStandingBroadJump, this.referenceValues.pfaStandingBroadJump),
        pfaVerticalJump: this.calculatePercentage(this.fitness.pfaVerticalJump, this.referenceValues.pfaVerticalJump),
        pfaSeatedMedBallThrow: this.calculatePercentage(this.fitness.pfaSeatedMedBallThrow, this.referenceValues.pfaSeatedMedBallThrow),
        pfaGripStrengthRight: this.calculatePercentage(this.fitness.pfaGripStrengthRight, this.referenceValues.pfaGripStrengthRight),
        pfaGripStrengthLeft: this.calculatePercentage(this.fitness.pfaGripStrengthLeft, this.referenceValues.pfaGripStrengthLeft),
        pfaSingleLegBalanceLeftEyesOpen: this.calculatePercentage(this.fitness.pfaSingleLegBalanceLeftEyesOpen, this.referenceValues.pfaSingleLegBalanceLeftEyesOpen),
        pfaSingleLegBalanceLeftEyesClose: this.calculatePercentage(this.fitness.pfaSingleLegBalanceLeftEyesClose, this.referenceValues.pfaSingleLegBalanceLeftEyesClose),
        pfaSingleLegBalanceRightEyesOpen: this.calculatePercentage(this.fitness.pfaSingleLegBalanceRightEyesOpen, this.referenceValues.pfaSingleLegBalanceRightEyesOpen),
        pfaSingleLegBalanceRightEyesClose: this.calculatePercentage(this.fitness.pfaSingleLegBalanceRightEyesClose, this.referenceValues.pfaSingleLegBalanceRightEyesClose),
        pfaPushups: this.calculatePercentage(this.fitness.pfaPushups, this.referenceValues.pfaPushups),
        pfaPullups: this.calculatePercentage(this.fitness.pfaPullups, this.referenceValues.pfaPullups),
        pfaSquat: this.calculatePercentage(this.fitness.pfaSquat, this.referenceValues.pfaSquat),
        pfaSitNReach: this.calculatePercentage(this.fitness.pfaSitNReach, this.referenceValues.pfaSitNReach),
        pfaUpperBodyPower: this.calculatePercentage(this.fitness.pfaUpperBodyPower, this.referenceValues.pfaUpperBodyPower),
        pfaUpperBodyForce: this.calculatePercentage(this.fitness.pfaUpperBodyForce, this.referenceValues.pfaUpperBodyForce),
        pfaLowerBodyPower: this.calculatePercentage(this.fitness.pfaLowerBodyPower, this.referenceValues.pfaLowerBodyPower),
        pfaLowerBodyForce: this.calculatePercentage(this.fitness.pfaLowerBodyForce, this.referenceValues.pfaLowerBodyForce),
        pfaSpeed: this.calculatePercentage(this.fitness.pfaSpeed, this.referenceValues.pfaSpeed), // Added Speed
        pfaYoYoTest: this.calculatePercentage(this.fitness.pfaYoYoTest, this.referenceValues.pfaYoYoTest) // Added YoYo Test
      };
    } else {
      console.log('No fitness data available');
    }
  }
  

  calculateCognitivePercentages(): void {
    if (this.cognitive) {
      this.cognitivePercentages = {
        cnaRulerDrop: this.calculatePercentage(this.cognitive.cnaRulerDrop, this.cognitiveReferenceValues.cnaRulerDrop),
        cnaWallToss: this.calculatePercentage(this.cognitive.cnaWallToss, this.cognitiveReferenceValues.cnaWallToss),
      };
    } else {
      console.log('No cognitive data available');
    }
  }

  calculateNutritionPercentages(): void {
    if (this.nutrition) {
      this.nutritionPercentages = {
        naBodyWeight: this.calculatePercentage(this.nutrition.naBodyWeight, this.referenceNutritionValues.naBodyWeight),
        naBodyHeight: this.calculatePercentage(this.nutrition.naBodyHeight, this.referenceNutritionValues.naBodyHeight),
        naBMI: this.calculatePercentage(this.nutrition.naBMI, this.referenceNutritionValues.naBMI),
        naBodyFat: this.calculatePercentage(this.nutrition.naBodyFat, this.referenceNutritionValues.naBodyFat),
        naFatFreeWeight: this.calculatePercentage(this.nutrition.naFatFreeWeight, this.referenceNutritionValues.naFatFreeWeight),
        naSubcutaneousFat: this.calculatePercentage(this.nutrition.naSubcutaneousFat, this.referenceNutritionValues.naSubcutaneousFat),
        naVisceralFat: this.calculatePercentage(this.nutrition.naVisceralFat, this.referenceNutritionValues.naVisceralFat),
        naBodyWater: this.calculatePercentage(this.nutrition.naBodyWater, this.referenceNutritionValues.naBodyWater),
        naSkeletalMuscle: this.calculatePercentage(this.nutrition.naSkeletalMuscle, this.referenceNutritionValues.naSkeletalMuscle),
        naLeanMass: this.calculatePercentage(this.nutrition.naLeanMass, this.referenceNutritionValues.naLeanMass),
        naBoneMass: this.calculatePercentage(this.nutrition.naBoneMass, this.referenceNutritionValues.naBoneMass),
        naProtein: this.calculatePercentage(this.nutrition.naProtein, this.referenceNutritionValues.naProtein),
        naBMR: this.calculatePercentage(this.nutrition.naBMR, this.referenceNutritionValues.naBMR),
        naMetabolicAge: this.calculatePercentage(this.nutrition.naMetabolicAge, this.referenceNutritionValues.naMetabolicAge),
        naHealthScore: this.calculatePercentage(this.nutrition.naHealthScore, this.referenceNutritionValues.naHealthScore),
      };
    } else {
      console.log('No nutrition data available');
    }
  }

  calculateSPAPercentages(): void {
    if (this.sports) {
      const avgTorqueCR = this.extractNumericParts(this.sports.spaAverageTorqueCR);
      const avgTorqueCL = this.extractNumericParts(this.sports.spaAverageTorqueCL);
      const peakTorqueCR = this.extractNumericParts(this.sports.spaPeakTorqueCR);
      const peakTorqueCL = this.extractNumericParts(this.sports.spaPeakTorqueCL);
      const avgTorqueIR = this.extractNumericParts(this.sports.spaAverageTorqueIR);
      const avgTorqueIL = this.extractNumericParts(this.sports.spaAverageTorqueIL);
      const peakTorqueIR = this.extractNumericParts(this.sports.spaPeakTorqueIR);
      const peakTorqueIL = this.extractNumericParts(this.sports.spaPeakTorqueIL);
  
      // Function to calculate average percentage from two parts
      const calculateAverage = (part1: number | null, part2: number | null) => {
        if (part1 !== null && part2 !== null) {
          return (part1 + part2) / 2;
        }
        return null;
      };
  
      // Initialize the spaPercentages object
      this.spaPercentages = {};
  
      // Calculate percentages
      this.spaPercentages.spaAverageTorqueCR_1 = avgTorqueCR ? this.calculatePercentage(avgTorqueCR[0], this.referenceSPAValues.spaAverageTorqueCR) : null;
      this.spaPercentages.spaAverageTorqueCR_2 = avgTorqueCR ? this.calculatePercentage(avgTorqueCR[1], this.referenceSPAValues.spaAverageTorqueCR) : null;
  
      this.spaPercentages.spaAverageTorqueCL_1 = avgTorqueCL ? this.calculatePercentage(avgTorqueCL[0], this.referenceSPAValues.spaAverageTorqueCL) : null;
      this.spaPercentages.spaAverageTorqueCL_2 = avgTorqueCL ? this.calculatePercentage(avgTorqueCL[1], this.referenceSPAValues.spaAverageTorqueCL) : null;
  
      this.spaPercentages.spaPeakTorqueCR_1 = peakTorqueCR ? this.calculatePercentage(peakTorqueCR[0], this.referenceSPAValues.spaPeakTorqueCR) : null;
      this.spaPercentages.spaPeakTorqueCR_2 = peakTorqueCR ? this.calculatePercentage(peakTorqueCR[1], this.referenceSPAValues.spaPeakTorqueCR) : null;
  
      this.spaPercentages.spaPeakTorqueCL_1 = peakTorqueCL ? this.calculatePercentage(peakTorqueCL[0], this.referenceSPAValues.spaPeakTorqueCL) : null;
      this.spaPercentages.spaPeakTorqueCL_2 = peakTorqueCL ? this.calculatePercentage(peakTorqueCL[1], this.referenceSPAValues.spaPeakTorqueCL) : null;
  
      this.spaPercentages.spaAverageTorqueIR_1 = avgTorqueIR ? this.calculatePercentage(avgTorqueIR[0], this.referenceSPAValues.spaAverageTorqueIR) : null;
      this.spaPercentages.spaAverageTorqueIR_2 = avgTorqueIR ? this.calculatePercentage(avgTorqueIR[1], this.referenceSPAValues.spaAverageTorqueIR) : null;
  
      this.spaPercentages.spaAverageTorqueIL_1 = avgTorqueIL ? this.calculatePercentage(avgTorqueIL[0], this.referenceSPAValues.spaAverageTorqueIL) : null;
      this.spaPercentages.spaAverageTorqueIL_2 = avgTorqueIL ? this.calculatePercentage(avgTorqueIL[1], this.referenceSPAValues.spaAverageTorqueIL) : null;
  
      this.spaPercentages.spaPeakTorqueIR_1 = peakTorqueIR ? this.calculatePercentage(peakTorqueIR[0], this.referenceSPAValues.spaPeakTorqueIR) : null;
      this.spaPercentages.spaPeakTorqueIR_2 = peakTorqueIR ? this.calculatePercentage(peakTorqueIR[1], this.referenceSPAValues.spaPeakTorqueIR) : null;
  
      this.spaPercentages.spaPeakTorqueIL_1 = peakTorqueIL ? this.calculatePercentage(peakTorqueIL[0], this.referenceSPAValues.spaPeakTorqueIL) : null;
      this.spaPercentages.spaPeakTorqueIL_2 = peakTorqueIL ? this.calculatePercentage(peakTorqueIL[1], this.referenceSPAValues.spaPeakTorqueIL) : null;
  
      // Calculate averages after all individual values have been set
      this.spaPercentages.spaAverageTorqueCR_avg = calculateAverage(this.spaPercentages.spaAverageTorqueCR_1, this.spaPercentages.spaAverageTorqueCR_2);
      this.spaPercentages.spaAverageTorqueCL_avg = calculateAverage(this.spaPercentages.spaAverageTorqueCL_1, this.spaPercentages.spaAverageTorqueCL_2);
      this.spaPercentages.spaPeakTorqueCR_avg = calculateAverage(this.spaPercentages.spaPeakTorqueCR_1, this.spaPercentages.spaPeakTorqueCR_2);
      this.spaPercentages.spaPeakTorqueCL_avg = calculateAverage(this.spaPercentages.spaPeakTorqueCL_1, this.spaPercentages.spaPeakTorqueCL_2);
      this.spaPercentages.spaAverageTorqueIR_avg = calculateAverage(this.spaPercentages.spaAverageTorqueIR_1, this.spaPercentages.spaAverageTorqueIR_2);
      this.spaPercentages.spaAverageTorqueIL_avg = calculateAverage(this.spaPercentages.spaAverageTorqueIL_1, this.spaPercentages.spaAverageTorqueIL_2);
      this.spaPercentages.spaPeakTorqueIR_avg = calculateAverage(this.spaPercentages.spaPeakTorqueIR_1, this.spaPercentages.spaPeakTorqueIR_2);
      this.spaPercentages.spaPeakTorqueIL_avg = calculateAverage(this.spaPercentages.spaPeakTorqueIL_1, this.spaPercentages.spaPeakTorqueIL_2);
  
      // Additional calculations
      this.spaPercentages.spaJumpHeight = this.calculatePercentage(this.sports.spaJumpHeight, this.referenceSPAValues.spaJumpHeight);
      this.spaPercentages.spaFlightTime = this.calculatePercentage(this.sports.spaFlightTime, this.referenceSPAValues.spaFlightTime);
      this.spaPercentages.spaVelocity = this.calculatePercentage(this.sports.spaVelocity, this.referenceSPAValues.spaVelocity);
      this.spaPercentages.spaForce = this.calculatePercentage(this.sports.spaForce, this.referenceSPAValues.spaForce);
      this.spaPercentages.spaPower = this.calculatePercentage(this.sports.spaPower, this.referenceSPAValues.spaPower);
  
    } else {
      console.log('No SPA data available');
    }
  }
  
  extractNumericParts(value: string): number[] | null {
    if (value) {
      const splitValues = value.split('/');
      return splitValues.length === 2 ? [parseFloat(splitValues[0]), parseFloat(splitValues[1])] : null; // Extract both parts
    }
    return null;
  }
  

  // Helper function to calculate the error function (erf)
  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;
  
    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
  
      const a1 =  0.254829592;
      const a2 = -0.284496736;
      const a3 =  1.421413741;
      const a4 = -1.453152027;
      const a5 =  1.061405429;
      const p  =  0.3275911;
  
      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
  
      return sign * y;
    };
  
    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }

// Fitness Z-score calculation
calculateZScoresByGender(orgFitnessData: any[], athleteGender: string) {
  const calculateMean = (values: number[]): number => {
    const sum = values.reduce((acc, value) => acc + value, 0);
    return values.length > 0 ? sum / values.length : 0;
  };

  const calculateStandardDeviation = (values: number[], mean: number): number => {
    const variance = values.reduce((acc, value) => acc + Math.pow(value - mean, 2), 0) / values.length;
    return Math.sqrt(variance);
  };

  const calculateZScore = (value: number, mean: number, stdDev: number): number | null => {
    return stdDev === 0 ? null : (value - mean) / stdDev;
  };

  // Filter data by gender
  const genderSpecificData = orgFitnessData.filter((item: any) => item.usrGender === athleteGender);

  // Map the relevant fields for Z-score calculations
  const heights = genderSpecificData.map(item => item.pfaHeight);
  const weights = genderSpecificData.map(item => item.pfaWeight);
  const bmiValues = genderSpecificData.map(item => item.pfaBMI);
  const squats = genderSpecificData.map(item => item.pfaSquat);
  const singleLegSquats = genderSpecificData.map(item => item.pfaSingleLegSquat);
  const pushups = genderSpecificData.map(item => item.pfaPushups);
  const pullups = genderSpecificData.map(item => item.pfaPullups);
  const balanceLeftEyesOpen = genderSpecificData.map(item => item.pfaSingleLegBalanceLeftEyesOpen);
  const starExcursion1 = genderSpecificData.map(item => item.pfaStarExcursionTest1);
  const upperBodyPower = genderSpecificData.map(item => item.pfaUpperBodyPower);
  const upperBodyForce = genderSpecificData.map(item => item.pfaUpperBodyForce);
  const lowerBodyPower = genderSpecificData.map(item => item.pfaLowerBodyPower);
  const lowerBodyForce = genderSpecificData.map(item => item.pfaLowerBodyForce);

  // Calculate means
  const heightMean = calculateMean(heights);
  const weightMean = calculateMean(weights);
  const bmiMean = calculateMean(bmiValues);
  const squatMean = calculateMean(squats);
  const singleLegSquatMean = calculateMean(singleLegSquats);
  const pushupMean = calculateMean(pushups);
  const pullupMean = calculateMean(pullups);
  const balanceLeftMean = calculateMean(balanceLeftEyesOpen);
  const starExcursionMean = calculateMean(starExcursion1);
  const upperBodyPowerMean = calculateMean(upperBodyPower);
  const upperBodyForceMean = calculateMean(upperBodyForce);
  const lowerBodyPowerMean = calculateMean(lowerBodyPower);
  const lowerBodyForceMean = calculateMean(lowerBodyForce);

  // Calculate standard deviations
  const heightStdDev = calculateStandardDeviation(heights, heightMean);
  const weightStdDev = calculateStandardDeviation(weights, weightMean);
  const bmiStdDev = calculateStandardDeviation(bmiValues, bmiMean);
  const squatStdDev = calculateStandardDeviation(squats, squatMean);
  const singleLegSquatStdDev = calculateStandardDeviation(singleLegSquats, singleLegSquatMean);
  const pushupStdDev = calculateStandardDeviation(pushups, pushupMean);
  const pullupStdDev = calculateStandardDeviation(pullups, pullupMean);
  const balanceLeftStdDev = calculateStandardDeviation(balanceLeftEyesOpen, balanceLeftMean);
  const starExcursionStdDev = calculateStandardDeviation(starExcursion1, starExcursionMean);
  const upperBodyPowerStdDev = calculateStandardDeviation(upperBodyPower, upperBodyPowerMean);
  const upperBodyForceStdDev = calculateStandardDeviation(upperBodyForce, upperBodyForceMean);
  const lowerBodyPowerStdDev = calculateStandardDeviation(lowerBodyPower, lowerBodyPowerMean);
  const lowerBodyForceStdDev = calculateStandardDeviation(lowerBodyForce, lowerBodyForceMean);

  // Z-scores and percentages
  this.fitnessReport.heightZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaHeight, heightMean, heightStdDev));
  this.fitnessReport.weightZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaWeight, weightMean, weightStdDev));
  this.fitnessReport.bmiZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaBMI, bmiMean, bmiStdDev));
  this.fitnessReport.squatZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaSquat, squatMean, squatStdDev));
  this.fitnessReport.singleLegSquatZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaSingleLegSquat, singleLegSquatMean, singleLegSquatStdDev));
  this.fitnessReport.pushupZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaPushups, pushupMean, pushupStdDev));
  this.fitnessReport.pullupZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaPullups, pullupMean, pullupStdDev));
  this.fitnessReport.balanceLeftZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaSingleLegBalanceLeftEyesOpen, balanceLeftMean, balanceLeftStdDev));
  this.fitnessReport.starExcursionZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaStarExcursionTest1, starExcursionMean, starExcursionStdDev));
  this.fitnessReport.upperBodyPowerZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaUpperBodyPower, upperBodyPowerMean, upperBodyPowerStdDev));
  this.fitnessReport.upperBodyForceZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaUpperBodyForce, upperBodyForceMean, upperBodyForceStdDev));
  this.fitnessReport.lowerBodyPowerZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaLowerBodyPower, lowerBodyPowerMean, lowerBodyPowerStdDev));
  this.fitnessReport.lowerBodyForceZScorePercent = this.zScoreToPercent(calculateZScore(this.fitnessReport.pfaLowerBodyForce, lowerBodyForceMean, lowerBodyForceStdDev));

}


// Cognitive Z-score calculation
calculateCognitiveZScoresByGender(orgCognitiveData: any[], athleteGender: string) {
  const calculateMean = (values: number[]): number => {
    const sum = values.reduce((acc, value) => acc + value, 0);
    return values.length > 0 ? sum / values.length : 0;
  };

  const calculateStandardDeviation = (values: number[], mean: number): number => {
    const variance = values.reduce((acc, value) => acc + Math.pow(value - mean, 2), 0) / values.length;
    return Math.sqrt(variance);
  };

  const calculateZScore = (value: number, mean: number, stdDev: number): number | null => {
    return stdDev === 0 ? null : (value - mean) / stdDev;
  };

  const genderSpecificData = orgCognitiveData.filter((item: any) => item.usrGender === athleteGender);

  const rulerDrops = genderSpecificData.map(item => item.cnaRulerDrop);
  const blazepods = genderSpecificData.map(item => item.cnaBlazepods);
  const wallTosses = genderSpecificData.map(item => item.cnaWallToss);

  const rulerDropMean = calculateMean(rulerDrops);
  const blazepodMean = calculateMean(blazepods);
  const wallTossMean = calculateMean(wallTosses);

  const rulerDropStdDev = calculateStandardDeviation(rulerDrops, rulerDropMean);
  const blazepodStdDev = calculateStandardDeviation(blazepods, blazepodMean);
  const wallTossStdDev = calculateStandardDeviation(wallTosses, wallTossMean);

  this.cognitiveReport.rulerDropZScorePercent = this.zScoreToPercent(calculateZScore(this.cognitiveReport.cnaRulerDrop, rulerDropMean, rulerDropStdDev));
  this.cognitiveReport.blazepodZScorePercent = this.zScoreToPercent(calculateZScore(this.cognitiveReport.cnaBlazepods, blazepodMean, blazepodStdDev));
  this.cognitiveReport.wallTossZScorePercent = this.zScoreToPercent(calculateZScore(this.cognitiveReport.cnaWallToss, wallTossMean, wallTossStdDev));

}

// Cardio Z-score calculation
calculateCardioZScoresByGender(orgCardioData: any[], athleteGender: string) {
  const calculateMean = (values: number[]): number => {
    const sum = values.reduce((acc, value) => acc + value, 0);
    return values.length > 0 ? sum / values.length : 0;
  };

  const calculateStandardDeviation = (values: number[], mean: number): number => {
    const variance = values.reduce((acc, value) => acc + Math.pow(value - mean, 2), 0) / values.length;
    return Math.sqrt(variance);
  };

  const calculateZScore = (value: number, mean: number, stdDev: number): number | null => {
    return stdDev === 0 ? null : (value - mean) / stdDev;
  };

  const genderSpecificData = orgCardioData.filter((item: any) => item.usrGender === athleteGender);

  const runTimes = genderSpecificData.map(item => item.coa800mRun);
  const yoYoTests = genderSpecificData.map(item => item.coaYoYoTest);
  const rastScores = genderSpecificData.map(item => item.coaRAST);
  const fatigueIndices = genderSpecificData.map(item => item.coaFatigueIndex);

  const runTimeMean = calculateMean(runTimes);
  const yoYoMean = calculateMean(yoYoTests);
  const rastMean = calculateMean(rastScores);
  const fatigueIndexMean = calculateMean(fatigueIndices);

  const runTimeStdDev = calculateStandardDeviation(runTimes, runTimeMean);
  const yoYoStdDev = calculateStandardDeviation(yoYoTests, yoYoMean);
  const rastStdDev = calculateStandardDeviation(rastScores, rastMean);
  const fatigueIndexStdDev = calculateStandardDeviation(fatigueIndices, fatigueIndexMean);

  this.cardioReport.runTimeZScorePercent = this.zScoreToPercent(calculateZScore(this.cardioReport.coa800mRun, runTimeMean, runTimeStdDev));
  this.cardioReport.yoYoZScorePercent = this.zScoreToPercent(calculateZScore(this.cardioReport.coaYoYoTest, yoYoMean, yoYoStdDev));
  this.cardioReport.rastZScorePercent = this.zScoreToPercent(calculateZScore(this.cardioReport.coaRAST, rastMean, rastStdDev));
  this.cardioReport.fatigueIndexZScorePercent = this.zScoreToPercent(calculateZScore(this.cardioReport.coaFatigueIndex, fatigueIndexMean, fatigueIndexStdDev));

}


  getLorMarkRangeForEachParameter() {
    let result: any = {}; // Object to store lorMarkRange for each parameter comparison

    for (let reference of this.leveloneReference) {
      // Height comparison
      if (this.nutritionReport.naBodyHeight < reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naBodyHeight >= reference.lorHeight) {
        result.lorHeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      // Weight comparison
      if (this.nutritionReport.naWeight < reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.nutritionReport.naWeight >= reference.lorWeight) {
        result.lorWeightMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    // for (let reference of this.leveloneReference) {
    //   // Ruler Drop comparison
    //   if (this.fitnessReport.pfaRulerDrop < reference.lorRulerDrop) {
    //     result.lorRulerDropMarkRange = reference.lorMarkRange;
    //     break; // Break to get the first matching range
    //   } else if (this.fitnessReport.pfaRulerDrop >= reference.lorRulerDrop) {
    //     result.lorRulerDropMarkRange = reference.lorMarkRange; // If greater than or equal
    //   }
    // }

    for (let reference of this.leveloneReference) {
      // Hand Grip comparison
      if (this.fitnessReport.pfaGripStrengthRight < reference.lorHandGrip) {
        result.lorHandGripMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.fitnessReport.pfaGripStrengthRight >= reference.lorHandGrip) {
        result.lorHandGripMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      // Seated Med Ball comparison
      if (this.fitnessReport.pfaSeatedMedBallThrow < reference.lorSeatedMedBall) {
        result.lorSeatedMedBallMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.fitnessReport.pfaSeatedMedBallThrow >= reference.lorSeatedMedBall) {
        result.lorSeatedMedBallMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      // 30m Sprint comparison
      if (this.fitnessReport.pfa30mSprint < reference.lor30mSprint) {
        result.lor30mSprintMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.fitnessReport.pfa30mSprint >= reference.lor30mSprint) {
        result.lor30mSprintMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      // 800m Run comparison
      if (this.fitnessReport.pfa800mRun < reference.lor800mRun) {
        result.lor800mRunMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.fitnessReport.pfa800mRun >= reference.lor800mRun) {
        result.lor800mRunMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    // Cognitive comparisons
    for (let reference of this.leveloneReference) {
      if (this.cognitiveReport.cnaRulerDrop < reference.lorRulerDrop) {
        result.lorRulerDropMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cognitiveReport.cnaRulerDrop >= reference.lorRulerDrop) {
        result.lorRulerDropMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cognitiveReport.cnaBlazepods < reference.lorBlazepods) {
        result.lorBlazepodsMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cognitiveReport.cnaBlazepods >= reference.lorBlazepods) {
        result.lorBlazepodsMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cognitiveReport.cnaBlazepodsTime < reference.lorBlazepodsTime) {
        result.lorBlazepodsTimeMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cognitiveReport.cnaBlazepodsTime >= reference.lorBlazepodsTime) {
        result.lorBlazepodsTimeMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cognitiveReport.cnaWallToss < reference.lorWallToss) {
        result.lorWallTossMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cognitiveReport.cnaWallToss >= reference.lorWallToss) {
        result.lorWallTossMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cognitiveReport.cnaPlateTaping < reference.lorPlateTaping) {
        result.lorPlateTapingMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cognitiveReport.cnaPlateTaping >= reference.lorPlateTaping) {
        result.lorPlateTapingMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    // Cardio comparisons
    for (let reference of this.leveloneReference) {
      if (this.cardioReport.coa800mRun < reference.lor800mRun) {
        result.lor800mRunMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cardioReport.coa800mRun >= reference.lor800mRun) {
        result.lor800mRunMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cardioReport.coaYoYoTest < reference.lorYoYoTest) {
        result.lorYoYoTestMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cardioReport.coaYoYoTest >= reference.lorYoYoTest) {
        result.lorYoYoTestMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cardioReport.coaRAST < reference.lorRAST) {
        result.lorRASTMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cardioReport.coaRAST >= reference.lorRAST) {
        result.lorRASTMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    for (let reference of this.leveloneReference) {
      if (this.cardioReport.coaFatigueIndex < reference.lorFatigueIndex) {
        result.lorFatigueIndexMarkRange = reference.lorMarkRange;
        break; // Break to get the first matching range
      } else if (this.cardioReport.coaFatigueIndex >= reference.lorFatigueIndex) {
        result.lorFatigueIndexMarkRange = reference.lorMarkRange; // If greater than or equal
      }
    }

    this.leveloneReport = result;

    return result;
  }

  
  getBMICategory(bmi: number): string {
    if (bmi < 18.5) {
      return 'Underweight';
    } else if (bmi >= 18.5 && bmi < 24.9) {
      return 'Normal weight';
    } else if (bmi >= 25 && bmi < 29.9) {
      return 'Overweight';
    } else {
      return 'Obesity';
    }
  }


  calculateOverallPercentage(): number | null {
    // Filter out null values from fitness and cognitive percentages
    const fitnessValues = Object.values(this.percentages).filter((p): p is number => p !== null);
    const cognitiveValues = Object.values(this.cognitivePercentages).filter((p): p is number => p !== null);
    const nutritionValues = Object.values(this.nutritionPercentages).filter((p): p is number => p !== null);
    const spaValues = Object.values(this.spaPercentages).filter((p): p is number => p !== null);

    // Combine all valid fitness and cognitive values
    const allValidValues = [...fitnessValues, ...cognitiveValues, ...nutritionValues, ...spaValues];

    // Check if there are valid values to avoid 'undefined' total
    if (allValidValues.length === 0) {
        return null; // Return null if no valid values
    }

    // Calculate total and count of valid values
    const total = allValidValues.reduce((acc, val) => acc + val, 0);
    
    this.overall = parseFloat((total / allValidValues.length).toFixed(2)) ;
    // Return the overall percentage as a value between 0 and 100
    return (total / allValidValues.length); 
}

summaryDetails() {
  this.assessmentService.checkUserSummary(this.UserId,this.assessmentId).subscribe(
    async (response: any) => {
      this.summarydetails = response;
    },
    (error) => {
    }
  );
}

getColorClass(value: number): string {
  if (value >= 85) {
    return 'dark-green';
  } else if (value >= 70) {
    return 'light-green';
  } else if (value >= 40) {
    return 'yellow';
  } else {
    return 'red';
  }
}

getScoreLabel(score: number): string {
  if (score >= 85) {
    return 'Excellent';
  } else if (score >= 70) {
    return 'Good';
  } else if (score >= 40) {
    return 'Average';
  } else {
    return 'Poor';
  }
}

getInjuryScoreLabel(score: number): string {
  if (score >= 3) {
    return 'Excellent';
  } else if (score >= 2) {
    return 'Good';
  } else if (score >= 1) {
    return 'Average';
  } else {
    return 'Poor';
  }
}

getLevelScoreLabel(score: number): string {
  if (score >= 15) {
    return 'Excellent';
  } else if (score >= 12) {
    return 'Good';
  } else if (score >= 10) {
    return 'Average';
  } else {
    return 'Poor';
  }
}

// calculateAveragePctl(tableIndex: number): number {
//   const table = this.tables.toArray()[tableIndex].nativeElement as HTMLTableElement;
//   const rows = Array.from(table.querySelectorAll('tbody tr'));
//   let totalPctl = 0;
//   let rowCount = 0;

//   rows.forEach(row => {
//     const pctlCell = row.querySelector('td:nth-child(3)') as HTMLTableCellElement;
//     const pctlText = pctlCell?.textContent?.replace('%', '').trim();
//     const pctlValue = pctlText ? parseFloat(pctlText) : 0; // Use 0 if parse fails

//     if (!isNaN(pctlValue)) { // Check if the value is a valid number
//       totalPctl += pctlValue;
//       rowCount++;
//     }
//   });

//   const averagePctl = rowCount > 0 ? totalPctl / rowCount : 0;

//   // Store the average PCTL value in the array at the corresponding table index
//   this.averagePctlValues[tableIndex] = parseFloat(averagePctl.toFixed(2));

//   return this.averagePctlValues[tableIndex];
// }

// Function to calculate the overall score by averaging all values in the array
calculateOverallScore(): number {
  const totalPctlSum = this.averagePctlValues.reduce((sum, pctlValue) => sum + pctlValue, 0);
  const numberOfTables = this.averagePctlValues.length;

  // Return the overall average rounded to 2 decimal places
  return numberOfTables > 0 ? parseFloat((totalPctlSum / numberOfTables).toFixed(2)) : 0;
}
// Get the row count for a particular table (for dynamic display if needed)
getRowCount(index: number): number {
  const table = this.tables.toArray()[index].nativeElement as HTMLTableElement;
  return table.querySelectorAll('tbody tr').length;
}

calculateTTestAverage() {
  const tTestMetrics = [
    parseInt(this.percentages.pfaTTest ?? this.fitness?.pfaTTest ?? 0),
  ];

  const total = tTestMetrics.reduce((sum, value) => sum + value, 0);
  const average = tTestMetrics.length > 0 ? total / tTestMetrics.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateSpeedAverage() {
  const metrics = [
    parseInt(this.percentages.pfaSpeed ?? this.fitness?.pfaSpeed ?? 0)
  ];

  const total = metrics.reduce((sum, value) => sum + value, 0);
  const average = metrics.length > 0 ? total / metrics.length : 0;

  return parseFloat(average.toFixed(2));
}


calculatePushUpsAverage() {
  const pushUpsMetrics = [
    parseInt(this.percentages.pfaPushups ?? this.fitnessReport?.pushupZScorePercent ?? this.fitness?.pfaPushups ?? 0),
  ];

  const total = pushUpsMetrics.reduce((sum, value) => sum + value, 0);
  const average = pushUpsMetrics.length > 0 ? total / pushUpsMetrics.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateFitnessAverages() {
  const squatsMetrics = [
    parseInt(this.percentages.pfaSquat ?? this.fitnessReport?.squatZScorePercent ?? this.fitness?.pfaSquat ?? 0)
  ];
 
  const partialCurlUpsTotal = squatsMetrics.reduce((sum, value) => sum + value, 0);
  const average = squatsMetrics.length > 0 ? partialCurlUpsTotal / squatsMetrics.length : 0;
 
  return parseFloat(average.toFixed(2));
}

calculateCurlUpAverages() {
  const squatsMetrics = [
    parseInt(this.fitness?.pfaPartialCurlUp ?? 0)
  ];

  const partialCurlUpsTotal = squatsMetrics.reduce((sum, value) => sum + value, 0);
  const average = squatsMetrics.length > 0 ? partialCurlUpsTotal / squatsMetrics.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateGripStrengthAverages() {
  const gripStrengthMetrics = [
    parseInt(this.percentages.pfaGripStrengthRight ?? this.fitness?.pfaGripStrengthRight ?? 0),
    parseInt(this.percentages.pfaGripStrengthLeft ?? this.fitness?.pfaGripStrengthLeft ?? 0),
  ];

  const gripStrengthTotal = gripStrengthMetrics.reduce((sum, value) => sum + value, 0);
  const average = gripStrengthMetrics.length > 0 ? gripStrengthTotal / gripStrengthMetrics.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateSeatedMedBallAverage() {
  const seatedMedBallMetrics = [
    parseInt(this.percentages?.pfaUpperBodyForce ??this.fitness?.pfaUpperBodyForce?? 0),
    parseInt(this.percentages?.pfaUpperBodyPower ?? this.sports?.pfaUpperBodyPower?? 0),
    parseInt(this.leveloneReport?.lorSeatedMedBallMarkRange ?? this.percentages.pfaSeatedMedBallThrow ??  this.fitness?.pfaSeatedMedBallThrow ?? 0),
  ];

  const seatedMedBallTotal = seatedMedBallMetrics.reduce((sum, value) => sum + value, 0);
  const numberOfMetrics = seatedMedBallMetrics.filter(value => value > 0).length;

  const average = numberOfMetrics > 0 ? seatedMedBallTotal / numberOfMetrics : 0;

  return parseFloat(average.toFixed(2));
}

calculateFitnesslowerAverage() {
  const fitnessMetrics = [
    parseInt(this.percentages?.pfaStandingBroadJump ?? this.fitness?.pfaStandingBroadJump ?? 0),
    parseInt(this.percentages?.pfaLowerBodyForce ?? this.fitness?.pfaLowerBodyForce ?? 0),
    parseInt(this.percentages?.pfaLowerBodyPower ?? this.fitness?.pfaLowerBodyPower ?? 0)
  ];

  const sum = fitnessMetrics.reduce((acc, value) => acc + value, 0);
  const average = fitnessMetrics.length > 0 ? sum / fitnessMetrics.length : 0;

  // Return the average rounded to 2 decimal places
  return parseFloat(average.toFixed(2));
}


calculateAveragePctl(): number {
  const pctlValues = [
    parseInt(this.percentages.pfaSingleLegBalanceRightEyesOpen ?? this.fitness?.pfaSingleLegBalanceRightEyesOpen ?? 0),
    parseInt(this.percentages.pfaSingleLegBalanceLeftEyesOpen ?? this.fitness?.pfaSingleLegBalanceLeftEyesOpen ?? 0),
    parseInt(this.percentages.pfaSingleLegBalanceRightEyesClose ?? this.fitness?.pfaSingleLegBalanceRightEyesClose ?? 0),
    parseInt(this.percentages.pfaSingleLegBalanceLeftEyesClose ?? this.fitness?.pfaSingleLegBalanceLeftEyesClose ?? 0)
  ];

  const sum = pctlValues.reduce((acc, value) => acc + value, 0);
  const average = pctlValues.length > 0 ? sum / pctlValues.length : 0;

  // Return the average rounded to 2 decimal places
  return parseFloat(average.toFixed(2));
}


calculateCognitiveAveragePctl() {
  const cognitivePctlValues = [
    parseInt(this.cognitiveReport?.rulerDropZScorePercent ?? this.cognitive?.cnaRulerDrop ?? 0),
    parseInt(this.leveloneReport?.lorWallTossMarkRange ?? this.cognitiveReport?.wallTossZScorePercent ?? this.cognitive?.cnaWallToss ?? 0),
  ];

  const total = cognitivePctlValues.reduce((sum, value) => sum + value, 0);
  const average = cognitivePctlValues.length > 0 ? total / cognitivePctlValues.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateTorqueAveragePctl() {
  const torquePctlValues = [
      parseInt(this.spaPercentages?.spaAverageTorqueIR_avg ??(this.sports?.spaAverageTorqueIR > 100 ? 100 : this.sports?.spaAverageTorqueIR) ?? 0),
      parseInt(this.spaPercentages?.spaPeakTorqueIR_avg ??(this.sports?.spaPeakTorqueIR > 100 ? 100 : this.sports?.spaPeakTorqueIR) ?? 0),
      parseInt(this.spaPercentages?.spaPeakTorqueIR_avg ??(this.sports?.spaAverageTorqueIL > 100 ? 100 : this.sports?.spaAverageTorqueIL)  ?? 0),
      parseInt(this.spaPercentages?.spaPeakTorqueIL_avg ??(this.sports?.spaPeakTorqueIL > 100 ? 100 : this.sports?.spaPeakTorqueIL) ?? 0),
  ];

  const total = torquePctlValues.reduce((sum, value) => sum + value, 0);
  const average = torquePctlValues.length > 0 ? total / torquePctlValues.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateTorqueAveragePctlForCRandCL() {
  const torquePctlValuesCRCL = [
    parseInt(this.spaPercentages?.spaAverageTorqueCR_avg ?? (this.sports?.spaAverageTorqueCR > 100 ? 100 : this.sports?.spaAverageTorqueCR)?? 0),
    parseInt(this.spaPercentages?.spaPeakTorqueCR_avg ?? (this.sports?.spaPeakTorqueCR > 100 ? 100 : this.sports?.spaPeakTorqueCR)  ?? 0),
    parseInt(this.spaPercentages?.spaAverageTorqueCL_avg ??(this.sports?.spaAverageTorqueCL > 100 ? 100 : this.sports?.spaAverageTorqueCL) ?? 0),
    parseInt(this.spaPercentages?.spaPeakTorqueCL_avg ??(this.sports?.spaPeakTorqueCL > 100 ? 100 : this.sports?.spaPeakTorqueCL) ?? 0),
  ];

  const total = torquePctlValuesCRCL.reduce((sum, value) => sum + value, 0);
  const average = torquePctlValuesCRCL.length > 0 ? total / torquePctlValuesCRCL.length : 0;

  return parseFloat(average.toFixed(2));
}

calculatePerformanceMetricsAverage() {
  const performanceMetrics = [
    parseInt(this.performanceReport?.jumpHeightZScorePercent ?? this.spaPercentages?.spaJumpHeight ?? this.sports?.spaJumpHeight ?? 0),
    parseInt(this.spaPercentages?.spaFlightTime ?? this.sports?.spaFlightTime ?? 0),
    parseInt(this.performanceReport?.velocityZScorePercent ?? this.spaPercentages?.spaVelocity ?? this.sports?.spaVelocity ?? 0),
    parseInt(this.performanceReport?.forceZScorePercent ?? this.spaPercentages?.spaForce ?? this.sports?.spaForce ?? 0),
    parseInt(this.performanceReport?.powerZScorePercent ?? this.spaPercentages?.spaPower ?? this.sports?.spaPower ?? 0),
  ];

  const total = performanceMetrics.reduce((sum, value) => sum + value, 0);
  const average = performanceMetrics.length > 0 ? total / performanceMetrics.length : 0;

  return parseFloat(average.toFixed(2));
}

calculateNutritionMetricsAverage() {
  // Collect all the nutrition metrics or fallback values
  const nutritionMetrics = [
    parseInt(this.nutritionPercentages.naLeanMass ?? this.nutrition?.naLeanMass ?? 0),
    parseInt(this.nutritionPercentages.naBoneMass ?? this.nutrition?.naBoneMass ?? 0),
    parseInt(this.nutritionPercentages.naProtein ?? this.nutrition?.naProtein ?? 0),
    parseInt(this.nutritionPercentages.naBMR ?? this.nutrition?.naBMR ?? 0),
    parseInt(this.nutritionPercentages.naMetabolicAge ?? this.nutrition?.naMetabolicAge ?? 0),
    parseInt(this.nutritionPercentages.naHealthScore ?? this.nutrition?.naHealthScore ?? 0),
    parseInt(this.leveloneReport?.lorHeightMarkRange ?? this.nutritionReport?.bodyHeightZScorePercent ?? this.nutritionPercentages.naBodyHeight ?? this.nutrition?.naBodyHeight ?? 0),
    parseInt(this.leveloneReport?.lorWeightMarkRange ?? this.nutritionPercentages.naBodyWeight ?? this.nutrition?.naBodyWeight ?? 0),
    parseInt(this.nutritionPercentages.naBMI ?? this.nutrition?.naBMI ?? 0),
    parseInt(this.nutritionPercentages.naBodyFat ?? this.nutrition?.naBodyFat ?? 0),
    parseInt(this.nutritionPercentages.naFatFreeWeight ?? this.nutrition?.naFatFreeWeight ?? 0),
    parseInt(this.nutritionPercentages.naSubcutaneousFat ?? this.nutrition?.naSubcutaneousFat ?? 0),
    parseInt(this.nutritionPercentages.naVisceralFat ?? this.nutrition?.naVisceralFat ?? 0),
    parseInt(this.nutritionPercentages.naBodyWater ?? this.nutrition?.naBodyWater ?? 0),
    parseInt(this.nutritionPercentages.naSkeletalMuscle ?? this.nutrition?.naSkeletalMuscle ?? 0)
  ];

  // Calculate the total and average percentile
  const total = nutritionMetrics.reduce((sum, value) => sum + value, 0);
  const average = nutritionMetrics.length > 0 ? total / nutritionMetrics.length : 0;

  // Return the average rounded to 2 decimal places
  return parseFloat(average.toFixed(2));
}



calculateSleepAndRestAverage() {
  // Assuming SleepandRest is an array of scores for simplicity, adjust as necessary
  const sleepAndRestMetrics = [
    parseInt(this.SleepandRest ?? 0),
      // Add any other relevant metrics if applicable
  ];

  // Calculate the total and average percentile
  const total = sleepAndRestMetrics.reduce((sum, value) => sum + value, 0);
  const average = sleepAndRestMetrics.length > 0 ? total / sleepAndRestMetrics.length : 0;

  // Return the average rounded to 2 decimal places
  return parseFloat(average.toFixed(2));
}

calculateHydrationAverage() {
  // Collect all the relevant hydration metrics
  const hydrationMetrics = [
    parseInt(this.hydration ?? 0),
      // Include any other hydration-related metrics if necessary
  ];

  // Calculate the total and average percentile
  const total = hydrationMetrics.reduce((sum, value) => sum + value, 0);
  const average = hydrationMetrics.length > 0 ? total / hydrationMetrics.length : 0;

  // Return the average rounded to 2 decimal places
  return parseFloat(average.toFixed(2));
}

// In your Angular component
calculateQuestionaryAverage() {
  // Collect all relevant questionary metrics
  const questionaryMetrics = [
    parseInt(this.questionary ?? 0),
      // Include any other related metrics if necessary
  ];

  // Calculate the total and average percentile
  const total = questionaryMetrics.reduce((sum, value) => sum + value, 0);
  const average = questionaryMetrics.length > 0 ? total / questionaryMetrics.length : 0;

  // Return the average rounded to 2 decimal places
  return parseFloat(average.toFixed(2));
}

calculateOverallAverage() {
  // Get individual averages
  const tTestAverage = this.calculateTTestAverage();
  const speedAverage = this.calculateSpeedAverage();
  const pushUpsAverage = this.calculatePushUpsAverage();
  const fitnessAverages = this.calculateFitnessAverages();
  const curlupAverage = this.calculateCurlUpAverages();
  const lowerAverage = this.calculateFitnesslowerAverage();
  const gripStrengthAverages = this.calculateGripStrengthAverages();
  const seatedMedBallAverage = this.calculateSeatedMedBallAverage();
  const averagePctl = this.calculateAveragePctl();
  const cognitiveAveragePctl = this.calculateCognitiveAveragePctl();
  const torqueAveragePctl = this.calculateTorqueAveragePctl();
  const torqueAveragePctlForCRandCL = this.calculateTorqueAveragePctlForCRandCL();
  const performanceMetricsAverage = this.calculatePerformanceMetricsAverage();
  const nutritionMetricsAverage = this.calculateNutritionMetricsAverage();
  const sleepAndRestAverage = this.calculateSleepAndRestAverage();
  const hydrationAverage = this.calculateHydrationAverage();
  const questionaryAverage = this.calculateQuestionaryAverage();

  // Combine all averages into an array
  const averages = [
    parseInt(this.fitness?.pfaVO2 ?? 0),
    parseInt(this.percentages.pfaStandingBroadJump??this.fitness?.pfaStandingBroadJump ?? 0),
    parseInt(this.percentages.pfaSitNReach ?? this.fitness?.pfaSitNReach ?? 0),
    parseInt(this.fitness?.pfaAppleysScratchTest ?? 0),
    tTestAverage,
    speedAverage,
    pushUpsAverage,
    curlupAverage,
    lowerAverage,
    fitnessAverages,
    gripStrengthAverages,
    seatedMedBallAverage,
    averagePctl,
    cognitiveAveragePctl,
    torqueAveragePctl,
    torqueAveragePctlForCRandCL,
    performanceMetricsAverage,
    nutritionMetricsAverage,
    sleepAndRestAverage,
    hydrationAverage,
    questionaryAverage
  ];

  // Calculate the total and overall average
  const total = averages.reduce((sum, value) => sum + value, 0);
  return averages.length > 0 ? parseFloat((total / averages.length).toFixed(2)) : 0;
}


}