import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgModule, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { User } from '../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { UserRegisterService } from '../../services/user-register.service';
import { PrivilegeService } from '../../services/privilage.service';
import { FormsModule, NgModel } from '@angular/forms';
import { Location } from '@angular/common';

interface IRow {}

@Component({
  selector: 'app-pending-assessment-details',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular,FormsModule],
  templateUrl: './pending-assessment-details.component.html',
  styleUrl: './pending-assessment-details.component.css'
})
export class PendingAssessmentDetailsComponent implements OnInit {

  Response: any;
  detail: any;
  Id: any;
  Status: any;
  privilege: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;

  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  org: any;
  role: any;
  selectedPlayer: any;
  OrgAdmin: boolean | undefined;
  Coach: boolean | undefined;
  teamid: any;
  assessorId: number = 11;
  tests: any;
  test: any;
  groupedData: { [key: string]: any[] } = {};
  selectdata: any;
  category: any;
  constructor(
    
    private userRegisterService:UserRegisterService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private priService: PrivilegeService,
    private ngZone: NgZone, 
    private assessmentService: AssessmentService,
    private location: Location,
    private router: Router 
  ) {
    this.route.queryParams.subscribe(params => {
      this.CoachId = params['id'];
      this.asmId = params['asmId'];
      this.category = params['category'];
      console.log(this.CoachId,"ID");
    });
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Coach = this.privilege?.isCoach ?? false;
    this.Admin = this.privilege.isAdmin;
    this.OrgAdmin = this.privilege.isOrgAdmin;
    
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role=this.detail.user.usrRole;
      this.Status = 0;
    });
    this.privilege = this.priService.getPrivileges();
    this.getCoachdata();
    this.getPendingAssessment()
  }
  getPendingAssessment() {

    this.assessmentService.getAssessmentTest(this.asmId).subscribe(
      (response) => {
        this.test=response;
        this.groupDataBySsName();
        console.log(response,"test")
        const uniqueAssessments = new Map<number, any>();
      },
      (error) => {
        console.error('Error retrieving pending assessments', error);
      }
    );

}
groupDataBySsName() {
  this.groupedData = this.test.reduce((acc: { [x: string]: any[]; }, item: { ssName: string | number; }) => {
    // Check if the group exists, if not, create it
    if (!acc[item.ssName]) {
      acc[item.ssName] = [];
    }
    // Add the current item to the group
    acc[item.ssName].push(item);
    return acc;
  }, {});
  console.log(this.groupedData,"data");
}
  getCoachdata() {
    this.assessmentService.getPlayerByAssessmentId(this.asmId).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.category);
      console.log('Assessment submitted', response);
      this.teamid=response[0].currentClubTeam;
      console.log(this.teamid,"teamId")
      this.rowData = this.selectdata.map((item:any) => ({
        "Player Name": `${item.usrFullName}`,
        PlayerId:`${item.aplPlayer}`,
        teamId:`${item.teamId}`,
        id:`${item.coaId}`,
        orgId:`${item.usrOrganization}`,
        "Email" :`${item.usrEmail}`,
        "Phone No": `${item.usrPhoneNo}`,
        "DOB": new Date(item.usrDoB).toLocaleDateString(),
      }));
 
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'Player Name' },
      { field: 'Email' },
      { field: 'DOB'},
      { field: 'Phone No' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
            this.selectedPlayer = _params.data;
            console.log('Selected player:', this.selectedPlayer);
            if(this.role == 2){
              this.ngZone.run(() => {
                this.router.navigate(['/report'], {
                  queryParams: { 
                    id: _params.data.PlayerId,
                    orgId: _params.data.orgId ,
                                    
                  }
                });
                // Show the modal
                // this.openModalWithPlayerDetails(this.selectedPlayer.teamId, this.selectedPlayer.PlayerId);
              //  const modalElement = document.getElementById('playerDetailModal');
              //   if (modalElement) {
              //     this.openModalWithPlayerDetails();
              //     const modalInstance = new (window as any).bootstrap.Modal(modalElement);
              //     modalInstance.show();
              //   }
              });

            }else{
              this.ngZone.run(() => {
                this.router.navigate(['/pendingform'], {
                  queryParams: { 
                    teamId: _params.data.teamId, 
                    athleteId: _params.data.PlayerId ,
                    asmId:this.asmId
                  }
                });
              });
            }
           
            // this.getProfileImage(_params.data.PlayerId).subscribe((imageUrl:any) => {
            // this.selectedImageUrl = imageUrl.changingThisBreaksApplicationSecurity as string;
        
            // });
           
            
            
          });
          container.appendChild(viewButton);
          return container;
        },
        width: 120,
      },
    ];

   
    if (this.Admin) {
      return columns.filter((col) => col.field !== 'Action');
    } else if (this.Isplayer) {
      return columns.filter((col) => col.field !== 'playername');
    } else {
      return columns.filter((col) => col.field !== 'Assessor');
    }
    
  }

  openModalWithPlayerDetails() {
    // Fetch data when role == 2
    this.assessmentService.getTestDetails(this.teamid, this.assessorId).subscribe(
      (data) => {
        console.log('Fetched data:', data);
        this.tests=data;
      },
      (error) => {
        console.error('Error fetching tests:', error);
      }
    );
  }

  onBack():void{
    this.location.back();
  }

}
