import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridOptions } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { NgToastModule } from "ng-angular-popup";
import { FormsModule } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { DomSanitizer } from "@angular/platform-browser";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedService } from "../../services/shared.service";
import { AssessmentService } from "../../services/assessment.service";
import { UserRegisterService } from "../../services/user-register.service";
import { NotificationService } from "../../services/Notifications.service";
interface IRow {
  OrgName : string;
  TeamName: string;
  CoachName:string;
  SportName:string;
}
@Component({
  selector: 'app-assign-team-coach',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule,NgToastModule,NgSelectModule],
  templateUrl: './assign-team-coach.component.html',
  styleUrl: './assign-team-coach.component.css'
})
export class AssignTeamCoachComponent implements OnInit {
  detail: any;
  org: any;
  statusOptions: any[] = [];
  statusPlayer: any[] = [];
  Trainer: any[] = [];
  selectedTeam: any;
  selectedPlayer:any;
  selectedCoach: string = '';
  selectedOrg : string = '';
  selectedOrgTrainer: string = '';
  orgName: string = '';
  organization: any;
  colDefs: ColDef[] = [];
  rowData: IRow[] = [];
  teamName: any;
  Organisation:boolean = false;
  gridOptions: GridOptions = {
    rowSelection: 'multiple', // Enable multi-row selection
    suppressRowClickSelection: true,
  };
  users: any;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  
  organizations: any[] = [];
  isEditMode: boolean = false;
  selectedOrgId: number | null = null;
  SelectOrg: boolean = false;
  trainerName: any;
  selectedTeamId: number | null = null;
  selectedOrgFilter: any;
  selectedSportFilter:any;
  filteredTeams: any;
  fiterOrg: boolean = false;
  gridApi: any;

  constructor(private assessmentService: AssessmentService,private http:HttpClient,private router: Router, private notify: NotificationService,
    private route: ActivatedRoute, private sharedService: SharedService,private sanitizer: DomSanitizer,
    private userRegisterService: UserRegisterService, private cdRef: ChangeDetectorRef,private ngZone: NgZone) {
      this.onAssignTeamCoachClick();
    }


  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      console.log(this.org,"org")
    });
    this.onAssignTeamCoachClick();
    this.loadOrgainsation();
    //this.loadOrganizations();
  }

  onAssignTeamCoachClick() {
    this.assessmentService.getOrgTrainer("11").subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.statusOptions = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );

    this.assessmentService.getOrgTrainer("1").subscribe(
      (data) => {
        this.statusPlayer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  } 
  loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.organization = data;
        console.log(this.organization, 'organization');
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }
  updateSelection() {
    // Collect the selected team and coach values
    const selectedTeam = this.selectedTeam;
    const selectedCoach = this.selectedCoach;
  
    const athleteIds = this.selectedPlayer.map((user: any) => user.usrId.toString());

    // Prepare the data object to be passed
    const updateData = {
      TeamId: selectedTeam,
      CoachId: selectedCoach,
      AthleteIds: athleteIds // This contains the data of the selected rows
    };
  
    console.log(updateData,"selection"); // Debugging: Check if the data is being gathered correctly
  
    // Now, pass this data to a service or handle it as needed
    this.assessmentService.updateUserSelection(updateData).subscribe(
      response => {
        this.notify.success('Selection updated successfully');
      },
      error => {
        this.notify.success('Selection updated successfully');
        console.error('Error updating selection:', error);
      }
    );
    this.loadOrgainsationUsers();
    this.getColumnDefinitions();
  }

  onSelectTeam() {
    
  }

  onSelectPlayers() {
    
  }

  

  
  // openManageTeamModal(){
  //   // Use Bootstrap's modal method to show the modal
  //   const fileUploadModal = document.getElementById('teamcreateModal1')!;
  //     const modal = new (window as any).bootstrap.Modal(fileUploadModal);
  //     modal.show();
  // }
  // openAddTeamModal(){
  //   const fileUploadModal = document.getElementById('teamcreateModal')!;
  //     const modal = new (window as any).bootstrap.Modal(fileUploadModal);
  //     modal.show();
  // }

  EditOrg(){
    this.SelectOrg = true;
    this.assessmentService.getOrgTrainer("11").subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.Trainer = this.Trainer.filter(selected => selected.usrOrganization === Number(this.selectedOrg));
  }

  createOrgModel() {
    // Use Bootstrap's modal method to show the modal
    const fileUploadModal = document.getElementById('orgcreateModal')!;
      const modal = new (window as any).bootstrap.Modal(fileUploadModal);
      modal.show();
  }

  onSelectOrg() {
    // Reset the rowData to clear the grid
    this.rowData = [];

    // Filter the Trainer, statusOptions, and statusPlayer based on the selected organization
    this.Trainer = this.Trainer.filter(selected => selected.usrOrganization === Number(this.selectedOrgTrainer));
    this.statusOptions = this.statusOptions.filter(selected => selected.teamOrganization === Number(this.selectedOrgTrainer));
    this.statusPlayer = this.statusPlayer.filter(selected => selected.usrOrganization === Number(this.selectedOrgTrainer));

    // Reload the organization users based on the selected organization
    this.loadOrgainsationUsers();

    // Refresh column definitions if needed
    this.getColumnDefinitions();

    // Set Organisation flag to true
    this.Organisation = true;

    // Refresh the grid if gridApi is available
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
    }
  }

  // Method to load organization-specific users
  loadOrgainsationUsers() {
    this.userRegisterService.getOrgTeams(this.selectedOrgTrainer).subscribe((response: any) => {
      this.users = response;
      console.log(this.users, 'register');

      // Mapping the response to rowData
      this.rowData = response.map((item: any) => ({
        TeamName: `${item.teamName}`,
        SportName: `${item.sportName}`,
        CoachName: `${item.usrFullName}`,
        CoachId: `${item.teamCoach}`
      }));
      console.log(this.rowData, 'row');

      // Update the grid with new data
      if (this.gridApi) {
        this.gridApi.setRowData(this.rowData);
      }

      // Refresh the column definitions
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Data not found', error);
    });
  }

  // Define the column structure for the Ag-Grid
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: "TeamName" },
      { field: "SportName" },
      { field: "CoachName" },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.title = "View";
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/team-player'], {
                queryParams: { id: _params.data.CoachId },
              });
            });
          });
          container.appendChild(viewButton);

          return container;
        },
        width: 120
      }
    ];

    // Ensure the method returns the columns array
    return columns;
  }

  // Grid's onGridReady event handler to get gridApi reference
  onGridReady(params: any) {
    this.gridApi = params.api;
  }


  clearSelection() {
    this.selectedPlayer = [];
  }
  isSelected(item: any): boolean {
    return this.selectedPlayer && this.selectedPlayer.includes(item);
  }




  // Load existing organizations
  // loadOrganizations() {
  //   this.assessmentService.getOrgainsation().subscribe(
  //     (data) => {
  //       this.organizations = data;
  //     },
  //     (error) => {
  //       console.error('Error fetching organizations:', error);
  //     }
  //   );
  // }

  // Open the Manage Organization modal
  openManageOrgModal() {
    const modal = new (window as any).bootstrap.Modal(document.getElementById('manageOrgModal'));
    modal.show();
  }

  // Open Add Organization modal
  openAddOrgModal() {
    this.isEditMode = false;
    this.orgName = ''; // Clear input field for adding
    this.selectedOrgId = null;

    const modal = new (window as any).bootstrap.Modal(document.getElementById('orgModal'));
    modal.show();
  }

  // Open Edit Organization modal
  openEditOrgModal(org: any) {
    this.isEditMode = true;
    this.orgName = org.orgName;  // Assuming org has an 'orgName' property
    this.selectedOrgId = org.orgId; // Assuming org has an 'id' property

    const modal = new (window as any).bootstrap.Modal(document.getElementById('orgModal'));
    modal.show();
  }

  // Add or update organization
  submitOrganization() {
    if (this.isEditMode && this.selectedOrgId) {
      this.updateOrganization();
    } else {
      this.createOrganization();
    }
  }

  // Create new organization
  createOrganization() {
    if (this.orgName) {
      this.selectedOrgId = 0;
      this.assessmentService.AddOrganisation( this.selectedOrgId,this.orgName).subscribe(
        (response) => {
          console.log('Organization created successfully:', response);
          this.notify.success('Organization created successfully');
          this.loadOrgainsation();  // Reload the list
          this.closeOrgModal();  // Close the add/edit modal
        },
        (error) => {
          this.notify.success('Organization created successfully');
          console.error('Error creating organization:', error);
        }
      );
    } else {
      alert('Please enter an organization name.');
    }
  }

  // Update existing organization
  updateOrganization() {
    if (this.orgName && this.selectedOrgId) {
      this.assessmentService.AddOrganisation(this.selectedOrgId, this.orgName).subscribe(
        (response) => {
          console.log('Organization updated successfully:', response);
          this.notify.success('Organization updated successfully');
          this.loadOrgainsation();  // Reload the list
          this.closeOrgModal();  // Close the add/edit modal
        },
        (error) => {
          this.notify.success('Organization updated successfully');
          console.error('Error updating organization:', error);
        }
      );
    } else {
      alert('Please enter an organization name.');
    }
  }

  // Close the Add/Edit modal
  closeOrgModal() {
    const modal = (window as any).bootstrap.Modal.getInstance(document.getElementById('orgModal'));
    modal.hide();
  }

  closeTeamModal() {
    const modal = (window as any).bootstrap.Modal.getInstance(document.getElementById('teamcreateModal'));
    modal.hide();
    this.fiterOrg = false;
  }

  filterTeams() {
    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.statusOptions = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    if (this.selectedOrg) {
        this.filteredTeams =this.statusOptions.filter(selected => selected.teamOrganization === Number(this.selectedOrg));
    } else {
        this.filteredTeams = this.statusOptions;
    }
    this.fiterOrg = true;
}

// Method to open the Manage Teams modal
openManageTeamModal() {
    this.fiterOrg = false;
    this.teamName = ''; // Clear input field for adding
    this.selectedOrg = '';
    const modal = new (window as any).bootstrap.Modal(document.getElementById('teamcreateModal1'));
    modal.show();
}

// Method to open the modal for adding a new team
openAddTeamModal() {
    this.isEditMode = false;
    this.fiterOrg = false;
    this.teamName = ''; // Clear input field for adding
    this.selectedOrg = '';
    this.selectedOrgFilter='';

    const modal = new (window as any).bootstrap.Modal(document.getElementById('teamcreateModal'));
    modal.show();
}

// Method to open the modal for editing an existing team
openEditTeamModal(team: any) {
    this.isEditMode = true;
    this.teamName = team.teamName; // Assuming team has a 'name' property
    this.selectedOrgFilter = this.selectedOrg; // Assuming team has an 'organizationId' property
    this.selectedTeamId = team.teamId; // Assuming team has an 'id' property

    const modal = new (window as any).bootstrap.Modal(document.getElementById('teamcreateModal'));
    modal.show();
}

// Method to save team (both add and edit)
saveTeam() {
    if (this.isEditMode) {
        this.updateTeam(); // Update existing team
    } else {
        this.createTeam(); // Create new team
    }
}

// Method to create a new team
createTeam() {
  if (this.selectedOrgFilter && this.teamName) {
    this.selectedTeamId = 0 ;
    this.selectedSportFilter = 0;
    this.assessmentService.AddTeam(this.selectedTeamId,this.selectedOrgFilter,this.teamName,this.selectedSportFilter).subscribe(
      response => {
        console.log('Team created successfully:', response);
        this.notify.success('Team created successfully');
        // Handle success (e.g., close modal, reset fields)
        this.closeTeamModal();
      },
      error => {
        this.notify.success('Team created successfully');
        console.error('Error creating team:', error);
        // Handle error
      }
    );
  } else {
    alert('Please select an organization and enter a team name.');
  }
    const modal = (window as any).bootstrap.Modal.getInstance(document.getElementById('teamcreateModal'));
    modal.hide();
}

// Method to update an existing team
updateTeam() {
  this.selectedSportFilter = 0;
  if (this.selectedOrgFilter && this.teamName) {
    this.assessmentService.AddTeam(this.selectedTeamId,this.selectedOrgFilter,this.teamName,this.selectedSportFilter).subscribe(
      response => {
        console.log('Team updated successfully:', response);
        this.notify.success('Team updated successfully');
        // Handle success (e.g., close modal, reset fields)
        this.closeTeamModal();
      },
      error => {
        this.notify.success('Team updated successfully');
        console.error('Error creating team:', error);
        // Handle error
      }
    );
  } else {
    alert('Please select an organization and enter a team name.');
  }
    const modal = (window as any).bootstrap.Modal.getInstance(document.getElementById('teamcreateModal'));
    modal.hide();
}
  
}