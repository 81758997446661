import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { NotificationService } from '../../services/Notifications.service';
 
import { UserRegisterService } from '../../services/user-register.service';
import { ChatService } from '../../services/chat.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PrivilegeService } from '../../services/privilage.service';
import { Privilege } from '../../model/Privillage';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
@Component({
  selector: 'app-pending-asessment-form',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './pending-asessment-form.component.html',
  styleUrls: ['./pending-asessment-form.component.css']
})
export class PendingAsessmentFormComponent implements OnInit {
  UserId: any;
  name: any;
  athleteId: any;
  teamId: any;
  ImageUrl: any;
  showImage: boolean = false;
  edit: boolean = false;
 
 
  groupedFields: { [key: string]: any } = {
    playerId: null,
    assessmentDate: null
  };
  formData: { [key: string]: any } = {
  };
  roleId: any;
  assesserId: any;
  assessment: any;
  id: any;
  asmId: any;
  catId: any;
  chat: any;
  athletedata: any;
  apiResponse: any;
  msg: any;
  assessmentsData: any[] = [];
  summary: any = {
    ausId: 0,
    ausAssessment: '',
    ausAthlete: '',
    ausSummary: '',
    ausActionPlan: ''
  };
  Plan: any;
  selectdata: any;
  category: any;
  scoreOptions = [
    { value: 1, label: 'Score 1' },
    { value: 2, label: 'Score 2' },
    { value: 3, label: 'Score 3' }
  ];
  selectTest: any;
  playerList: any[]=[];
  rowData: any[]=[];
  playerName: any;
  privilege:Privilege;
  IsAdmin: boolean;
  image: any;
 orgId:number=0;
  jsonData: any;
  sport: any;
  constructor(
    private assessmentService: AssessmentService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private location: Location,
    private notify: NotificationService, private chatService: ChatService,private priService:PrivilegeService,
    private sanitizer: DomSanitizer, private userRegisterService: UserRegisterService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.privilege = this.priService.getPrivileges();
    this.IsAdmin = this.privilege.isAdmin;
    if (this.assesserId) {
      this.userRegisterService.fetchUser(this.assesserId).subscribe(
        (data) => {
          this.roleId = data[0].usrRole;
        })
    }
  }
 
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.athleteId = params['athleteId'];
      this.teamId = params['teamId'];
      this.assesserId = params['assessor'];
      this.id = params['id'];
      this.category = +params['category'];
      this.formData['playerId'] = this.athleteId;
      this.getProfileImage();
    });
    this.sharedService.detail$.subscribe((data: any) => {
      this.UserId = data.user.usrId;
      this.name = data.user.usrFullName;
      this.roleId = data.user.usrRole;
 
      if (!this.assesserId) {
        this.assesserId = this.UserId;
      }
 
      if (this.id) {
        this.loadAssessmentDetails(this.id); // Load details when in edit mode
      }
    });
    //this.getAllAssessmentData()
    this.assessmentService.getTestDetails(this.teamId, this.assesserId).subscribe(
      (data) => {
        // Define the test names to exclude
        const excludedTestNames = [
          "24 Hours Recall questionaire",
          "Hydration questionaire",
          "Sleep questionnaire"
        ];
   
        // Filter out the assessments based on the specified conditions
        this.selectTest = data.filter((assessment: any) =>
          assessment.testCategory === this.category &&
          !excludedTestNames.includes(assessment.testName) // Exclude specific test names
        );
   
        // Further filter based on astAssessment ID
        this.selectdata = this.selectTest.filter((assessment: any) =>
          assessment.astAssessment == this.id
        );
   
        // Group the filtered data by subheading
        this.groupFieldsBySubHeading(this.selectdata);
   
        // Get the asmId from the first assessment
        this.asmId = this.selectTest.length > 0 ? this.selectTest[0].astAssessment : null;
      },
      (error: any) => {
      } 
    );
    
    this.GetPlayer();
}
 
  GetPlayer(){
    this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.category);
      // Create a map to store unique assessments based on PlayerId (or any unique identifier)
      const uniqueAssessments = new Map<number, any>();
 
      // Filter the response to only include unique assessments
      this.selectdata.forEach((item: any) => {
        // Only add the item if it does not exist in the map
        if (!uniqueAssessments.has(item.aplPlayer)) {
          uniqueAssessments.set(item.aplPlayer, {
            "Player Name": `${item.usrFullName}`,
            Name: `${item.usrFullName}`,
            aplPlayer: `${item.aplPlayer}`,
            teamId: `${item.teamId}`,
            aplAssessment: `${item.aplAssessment}`,
            "Email": `${item.usrEmail}`,
            PlayerEmail: `${item.usrEmail}`,
            "Phone No": `${item.usrPhoneNo}`,
            PhoneNo: `${item.usrPhoneNo}`,
            "DOB": new Date(item.usrDoB).toLocaleDateString(),
            "status": `${item.aplStatus}`,
            "sport":`${item.asiPrimarySport}`
          });
        }
      });
 
      // Convert the unique assessments map back to an array for your rowData
      this.rowData = Array.from(uniqueAssessments.values());
      this.playerName=  this.rowData.filter((assessment: any) => assessment.aplPlayer === this.athleteId);
      this.sport=this.playerName[0].sport;
      this.playerList = this.rowData;
 
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
 
  // Fetch assessment data and populate form in edit mode
  loadAssessmentDetails(id: string) {
    this.assessmentService.getcardioById(id).subscribe(
      (response: any) => {
        this.populateFormForEdit(response); // Populate the form with fetched data
      },
      (error: any) => {
        console.error('Error fetching assessment details:', error);
      }
    );
  }
 
  // Group fields by subHeading and initialize formData with testColumnName for a new form
  groupFieldsBySubHeading(data: any) {
    this.groupedFields = data.reduce((acc: any, item: any) => {
      const subHeading = item.testSubHeading;
      if (!acc[subHeading]) {
        acc[subHeading] = [];
      }
      acc[subHeading].push(item);
 
      // Initialize formData with testColumnName for a new form
      this.formData[item.testColumnName] = null; // or any default value for new form
      return acc;
    }, {});
 
    this.getCardiology()
    this.getfitness()
    this.getCognitive()
    this.GetLevelOne()
    this.getinjuryanalysis()
   this. GetAthelete()
    this.getSportsPerformanceAnalysis()
    this.GetAthelete()
  }
 
  populateFormForEdit(assessmentData: any) {
 
    // Iterate over each key in the assessmentData and assign it to formData
    for (const key in assessmentData) {
      if (assessmentData.hasOwnProperty(key)) {
        const trimmedKey = key.trim();
        // Check if the key corresponds to any testColumnName
        if (this.formData[trimmedKey] !== undefined) {
          this.formData[trimmedKey] = assessmentData[key];
        }
      }
    }
  }
  onBack():void{
    this.location.back();
  }
  // Submit the form data
  onSubmit() {
    if (this.category === 4) {
      this.formData['pfaAthlete'] = this.athleteId;
      this.formData['pfaDate'] = this.formData['assessmentDate'];
      this.formData['pfaFollowUpDate'] = this.formData['assessmentDate'];
      this.formData['pfaUpdatedOn'] = this.formData['assessmentDate'];
      this.formData['pfaAssessor'] = this.assesserId;
      this.formData['pfaCreatedBy'] = this.assesserId;
      this.formData['pfaAssessment'] = this.id;
      // this.catId = 4;
      this.assessmentService.addPhysicalFitnessDetail([this.formData]).subscribe(
        response => {
 
          this.assessmentService.updateAssessmentStatus(this.formData['pfaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
 
            });
          this.checkUserSummary()
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else if (this.category === 7) {
      this.formData['coaAthlete'] = this.athleteId;
      this.formData['coaDate'] = this.formData['assessmentDate'];
      this.formData['coaAssessor'] = this.assesserId;
      this.formData['coaCreatedBy'] = this.assesserId;
      this.formData['coaAssessment'] = this.id;
      // this.catId = 7;
      this.assessmentService.addcordio([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['coaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else if (this.category === 8) {
      this.formData['cnaAthlete'] = this.athleteId;
      this.formData['cnaDate'] = this.formData['assessmentDate'];
      this.formData['cnaAssessor'] = this.assesserId;
      this.formData['cnaCreatedBy'] = this.assesserId;
      this.formData['cnaAssessment'] = this.id;
      this.formData['cnaUpdatedOn'] = Date;
      // this.catId = 8;
      this.assessmentService.addcognitive([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['cnaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
          this.notify.success("Data updated successfully");
       
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else if (this.category === 9) {
      this.formData['loaAthlete'] = this.athleteId;
      this.formData['loaAsessmentDate'] = this.formData['assessmentDate'];
      this.formData['loaAssessor'] = this.assesserId;
      this.formData['loaAssessment'] = this.id;
      this.formData['sportName'] = this.sport;
      // this.catId = 9;
      this.assessmentService.addLevelOneDetail([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['loaAthlete'], this.id,this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    }
    else if(this.category==10){
      this.formData['ipaAthlete'] =this.athleteId;
      this.formData['ipaAssessmentDate'] = this.formData['assessmentDate'];
      this.formData['ipaAssessor'] = this.assesserId;
      this.formData['ipaAssessment'] = this.id;
      // this.catId = 10;
      this.assessmentService.addIPADetails([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['ipaAthlete'], this.id, this.category,this.assesserId).subscribe(
            response => {
              
            });
            this.checkUserSummary()
          this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    }
    else if(this.category === 11){
      this.formData['spaAthlete'] = this.athleteId;
      this.formData['spaDate'] = this.formData['assessmentDate'];
      this.formData['spaAssessor'] = this.assesserId;
      this.formData['spaAssessment'] = this.id;
      // this.catId=11;
      this.assessmentService.SaveSportsperformance([this.formData]).subscribe(
        response => {
          this.assessmentService.updateAssessmentStatus(this.formData['spaAthlete'],this.id,this.category,this.assesserId).subscribe(
            response => {
              this.checkUserSummary()
            });
            this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    } else {
      this.formData['naAthlete'] = this.athleteId;
      this.formData['naDate'] = this.formData['assessmentDate'];
      this.formData['naAssessor'] = this.assesserId;
      this.formData['naAssessment'] = this.id;
     
      this.assessmentService.addnutritional([this.formData]).subscribe(
        response => {
         
          this.assessmentService.updateAssessmentStatus(this.formData['naAthlete'],this.id,this.category,this.assesserId).subscribe(
            response => {
             
            });
            this.checkUserSummary()
            this.notify.success("Data updated successfully");
         
        },
        error => {
          this.notify.failed("Error submitting assessment");
          console.error('Error submitting assessment', error);
        }
      );
    }
 
  }
 
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
         
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
        this.showImage = true;
          }
      );
        console.error('Error fetching image', error);
      }
    );
  }
 
 
  getCardiology() {
   
    this.clearPreviousData()
    if (this.id && this.category == 7) {
      this.assessmentService.getCardioByAsm(this.athleteId, this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.edit=true;
       
          if (this.roleId == 2) {
            this.selectdata = response.filter((assessment: any) => assessment.coaAssessor == this.assesserId);
          } else {
            this.selectdata = response.filter((assessment: any) => assessment.coaAssessor === this.UserId);
          }
          const data =   this.selectdata[0];
          this.formData['coaId'] = data.coaId;
          this.formData['coaAthlete'] = data.coaAthlete;
          this.formData['coaCreatedOn'] = data.coaCreatedOn ;  
          this.formData['assessmentDate'] = data.coaCreatedOn ? data.coaCreatedOn.split('T')[0] : '';
          this.formData['coaAssessor'] = data.coaAssessor;
          this.formData['coa800mRun'] = data.coa800mRun;
          this.formData['coaYoYoTest'] = data.coaYoYoTest;
          this.formData['coaRAST'] = data.coaRAST;
          this.formData['coaFatigueIndex'] = data.coaFatigueIndex;
          this.formData['coaCreatedBy'] = data.coaCreatedBy;
          this.assessmentsData.push(data);
        } else{
          this.edit=false;
        }
        this.getProfileImage();
      },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
  GetAthelete(){
    this.clearPreviousData()
    if(this.id && this.category == 5){
    this.assessmentService.getNutritionalByAsm(this.athleteId, this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.edit=true;
     
        if (this.roleId == 2) {
          this.selectdata = response.filter((assessment: any) => assessment.naAssessor == this.assesserId);
        } else {
          this.selectdata = response.filter((assessment: any) => assessment.naAssessor === this.UserId);
        }
        const data =   this.selectdata[0];
        this.formData['naId'] = data.naId;
        this.formData['naDate'] = data.naDate;
        this.formData['assessmentDate'] = data.naDate ? data.naDate.split('T')[0] : '';
        this.formData['naAssessor'] = data.naAssessor;
        this.formData['naAthlete'] = data.naAthlete;
        this.formData['naAssessorName'] = data.assessorFullName;
        this.formData['naAthleteName'] = data.athleteFullName;
        this.formData['naBodyWeight'] = data.naBodyWeight;
        this.formData['naBodyHeight'] = data.naBodyHeight;
        this.formData['naBMI'] = data.naBMI;
        this.formData['naBodyFat'] = data.naBodyFat;
        this.formData['naFatFreeWeight'] = data.naFatFreeWeight;
        this.formData['naSubcutaneousFat'] = data.naSubcutaneousFat;
        this.formData['naVisceralFat'] = data.naVisceralFat;
        this.formData['naBodyWater'] = data.naBodyWater;
        this.formData['naSkeletalMuscle'] = data.naSkeletalMuscle;
        this.formData['naLeanMass'] = data.naLeanMass;
        this.formData['naBoneMass'] = data.naBoneMass;
        this.formData['naProtein'] = data.naProtein;
        this.formData['naBMR'] = data.naBMR;
        this.formData['naMetabolicAge'] = data.naMetabolicAge;
        this.formData['naHealthScore'] = data.naHealthScore;
   
      } else{
        this.edit=false;
      }
    },
    (error: any) => {
      console.error('Error fetching Assessment data', error);
    }
  );
  }
  }
  getfitness() {
    this.clearPreviousData()
    if (this.id && this.category == 4) {
      this.assessmentService.getFitnessByAsm(this.athleteId, this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.edit=true;
       
          if (this.roleId == 2) {
            this.selectdata = response.filter((assessment: any) => assessment.pfaAssessor == this.assesserId);
          } else {
            this.selectdata = response.filter((assessment: any) => assessment.pfaAssessor === this.UserId);
          }
         
   
          const data =   this.selectdata[0];
          this.formData['pfaId'] = data.pfaId;
          this.formData['pfaAthlete'] = data.pfaAthlete;
       
          this.formData['pfaDate'] = data.pfaDate ? data.pfaDate.split('T')[0] : '';
          this.formData['pfaAssessor'] = data.pfaAssessor;
          this.formData['usrDoB'] = data.usrDoB ? data.usrDoB.split('T')[0] : '';
          this.formData['usrGender'] = data.usrGender;
          this.formData['athleteName'] = data.athleteName;
          this.formData['assessorName'] = data.assessorName;
          this.formData['pfaHeight'] = data.pfaHeight;
          this.formData['pfaWeight'] = data.pfaWeight;
          this.formData['pfaBMI'] = data.pfaBMI;
          //this.formData['pfaPartialCurlUp'] = data.pfaPartialCurlUp;
          //this.formData['pfaSingleLegBalanceEyesClose'] = data.pfaSingleLegBalanceEyesClose;
          this.formData['pfaYBalanceTest1'] = data.pfaYBalanceTest1;
          this.formData['pfaYBalanceTest2'] = data.pfaYBalanceTest2;
          this.formData['pfaYBalanceTest3'] = data.pfaYBalanceTest3;
          this.formData['pfaStarExcursionTest1'] = data.pfaStarExcursionTest1;
          this.formData['pfaStarExcursionTest2'] = data.pfaStarExcursionTest2;
          this.formData['pfaStarExcursionTest3'] = data.pfaStarExcursionTest3;
          this.formData['pfaPushups'] = data.pfaPushups;
          this.formData['pfaPullups'] = data.pfaPullups;
          this.formData['pfaPullAndHang'] = data.pfaPullAndHang;
          this.formData['pfaSquat'] = data.pfaSquat;
          this.formData['pfaSingleLegSquat'] = data.pfaSingleLegSquat;
          this.formData['pfaPartialCurlUp'] = data.pfaPartialCurlUp;
          this.formData['pfaGripStrengthRight'] = data.pfaGripStrengthRight;
          this.formData['pfaGripStrengthLeft'] = data.pfaGripStrengthLeft;
          this.formData['pfaSitNReach'] = data.pfaSitNReach;
          this.formData['pfaAppleysScratchTest'] = data.pfaAppleysScratchTest;
          this.formData['pfa50mSprint'] = data.pfa50mSprint;
          this.formData['pfa30mSprint'] = data.pfa30mSprint;
          this.formData['pfa10mShuttleRun'] = data.pfa10mShuttleRun;
          this.formData['pfaTTest'] = data.pfaTTest;
          this.formData['pfaStandingBroadJump'] = data.pfaStandingBroadJump;
          this.formData['pfaVerticalJump'] = data.pfaVerticalJump;
          this.formData['pfaSeatedMedBallThrow'] = data.pfaSeatedMedBallThrow;
          this.formData['pfaInterpretation'] = data.pfaInterpretation;
          this.formData['pfaRecommendations'] = data.pfaRecommendations;
          this.formData['pfaFollowUpDate'] = data.pfaFollowUpDate ? data.pfaFollowUpDate.split('T')[0] : '';
          this.formData['pfaAssessment'] = data.pfaAssessment;
          this.formData['pfaCreatedBy'] = data.pfaCreatedBy;
          this.formData['pfaCreatedOn'] = data.pfaCreatedOn ? data.pfaCreatedOn.split('T')[0] : '';
          this.formData['pfaUpdatedBy'] = data.pfaUpdatedBy;
          this.formData['pfaUpdatedOn'] = data.pfaUpdatedOn ? data.pfaUpdatedOn.split('T')[0] : '';
          this.formData['pfaSingleLegBalanceLeftEyesOpen'] = data.pfaSingleLegBalanceLeftEyesOpen;
          this.formData['pfaSingleLegBalanceLeftEyesClose'] = data.pfaSingleLegBalanceLeftEyesClose;
          this.formData['pfaSingleLegBalanceRightEyesOpen'] = data.pfaSingleLegBalanceRightEyesOpen;
          this.formData['pfaSingleLegBalanceRightEyesClose'] = data.pfaSingleLegBalanceRightEyesClose;
          this.formData['pfaUpperBodyPower'] = data.pfaUpperBodyPower;
          this.formData['pfaUpperBodyForce'] = data.pfaUpperBodyForce;
          this.formData['pfaLowerBodyForce'] = data.pfaLowerBodyForce;
          this.formData['pfaLowerBodyPower'] = data.pfaLowerBodyPower;
          this.formData['pfaLevel'] = data.pfaLevel;
          this.formData['pfaVO2'] = data.pfaVO2;
          this.formData['pfaYoYoTest'] = data.pfaYoYoTest;
          this.formData['pfaSpeed'] = data.pfaSpeed;
          this.assessmentsData.push(data);
        } else{
          this.edit=false;
        }
        this.getProfileImage();
      },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
  getCognitive() {
    this.clearPreviousData()
    if (this.id && this.category == 8) {
 
      this.assessmentService.getCognitiveByAsm(this.athleteId, this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.edit=true;
       
          if (this.roleId == 2) {
            this.selectdata = response.filter((assessment: any) => assessment.cnaAssessor == this.assesserId);
          } else {
            this.selectdata = response.filter((assessment: any) => assessment.cnaAssessor === this.UserId);
          }
          const data =   this.selectdata[0];
          this.formData['cnaId'] = data.cnaId;
          this.formData['cnaAthlete'] = data.cnaAthlete;
          this.formData['cnaAssessor'] = data.cnaAssessor;
          this.formData['usrDoB'] = data.usrDoB ? data.usrDoB.split('T')[0] : '';
          this.formData['usrGender'] = data.usrGender;
          this.formData['athleteName'] = data.athleteName;
          this.formData['cnaRulerDrop'] = data.cnaRulerDrop;
          this.formData['cnaBlazepods'] = data.cnaBlazepods;
          this.formData['cnaBlazepodsTime'] = data.cnaBlazepodsTime;
          this.formData['cnaWallToss'] = data.cnaWallToss;
          this.formData['cnaPlateTaping'] = data.cnaPlateTaping;
          this.formData['assessorName'] = data.assessorName;
          this.formData['cnaAssessment'] = data.cnaAssessment;
          this.formData['cnaCreatedBy'] = data.cnaCreatedBy;
          this.formData['cnaCreatedOn'] = data.cnaCreatedOn ? data.cnaCreatedOn.split('T')[0] : '';
          this.formData['cnaUpdatedBy'] = data.cnaUpdatedBy;
          this.formData['cnaUpdatedOn'] = data.cnaUpdatedOn ? data.cnaUpdatedOn.split('T')[0] : '';
          this.assessmentsData.push(data);
        } else{
          this.edit=false;
        }
        this.getProfileImage();
      },
        (error: any) => {
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
  GetLevelOne() {
    this.clearPreviousData()
    this.assessmentService.getLevelOneByAsm(this.athleteId, this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.edit=true;
     
        if (this.roleId == 2) {
          this.selectdata = response.filter((assessment: any) => assessment.loaAssessor == this.assesserId);
        } else {
          this.selectdata = response.filter((assessment: any) => assessment.loaAssessor === this.UserId);
        }
       
 
        const data =   this.selectdata[0];
        this.formData['loaId'] = data.loaId;
        this.formData['loaName'] = data.loaName;
        this.formData['loaAthlete'] = data.loaAthlete;
        this.formData['loaRegisterNumber'] = data.loaRegisterNumber;
        this.formData['aggAge'] = data.aggAge;
        this.formData['sportName'] = data.sportName;
        this.formData['aggGender'] = data.aggGender;
        this.formData['genderName'] = data.genderName;
        this.formData['loaHeight'] = data.loaHeight;
        this.formData['loaWeight'] = data.loaWeight;
        this.formData['loaRulerDrop'] = data.loaRulerDrop;
        this.formData['loaHandGrip'] = data.loaHandGrip;
        this.formData['loaSeatedMedBall'] = data.loaSeatedMedBall;
        this.formData['loaSitnReach'] = data.loaSitnReach;
        this.formData['loaVerticalJump'] = data.loaVerticalJump;
        this.formData['loaWallToss'] = data.loaWallToss;
        this.formData['loa30mSprint'] = data.loa30mSprint;
        this.formData['loa6x10ShuttleRun'] = data.loa6x10ShuttleRun;
        this.formData['loa800mRun'] = data.loa800mRun;
        this.formData['loaAsessmentDate'] = data.loaAsessmentDate ? data.loaAsessmentDate.split('T')[0] : '';
        this.formData['assessmentDate'] = data.loaAsessmentDate ? data.loaAsessmentDate.split('T')[0] : '';
      }
      else{
        this.edit=false;
      }
    },
      (error: any) => {
        console.error('Error fetching assessment data', error);
      }
    );
 
  }
  getSportsPerformanceAnalysis(){
    this.clearPreviousData()
    if(this.id && this.category == 11){
    this.assessmentService.getSportsperformanceByAsm(this.athleteId,this.id).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.edit=true;
     
        if (this.roleId == 2) {
          this.selectdata = response.filter((assessment: any) => assessment.spaAssessor == this.assesserId);
        } else {
          this.selectdata = response.filter((assessment: any) => assessment.spaAssessor === this.UserId);
        }
       
 
        const data =   this.selectdata[0];
        this.formData['spaId']= data.spaId;
        this.formData['spaAthlete']= data.spaAthlete;
        this.formData['spaAssessor'] = data.spaAssessor;
        this.formData['spaAssessment'] = data.spaAssessment;
        this.formData['spaDate'] = data.spaDate ; // Convert date format
        this.formData['assessmentDate']= data.spaDate ? data.spaDate.split('T')[0] : '';
        this.formData['spaAverageTorqueCR'] = data.spaAverageTorqueCR;
        this.formData['spaAverageTorqueCL'] = data.spaAverageTorqueCL;
        this.formData['spaPeakTorqueCR'] = data.spaPeakTorqueCR;
        this.formData['spaPeakTorqueCL'] = data.spaPeakTorqueCL;
        this.formData['spaAverageTorqueIR'] = data.spaAverageTorqueIR;
        this.formData['spaAverageTorqueIL'] = data.spaAverageTorqueIL;
        this.formData['spaPeakTorqueIR'] = data.spaPeakTorqueIR;
        this.formData['spaPeakTorqueIL'] = data.spaPeakTorqueIL;
        this.formData['spaJumpHeight']= data.spaJumpHeight;
        this.formData['spaFlightTime']= data.spaFlightTime;
        this.formData['spaVelocity'] = data.spaVelocity;
        this.formData['spaForce'] = data.spaForce;
        this.formData['spaPower'] = data.spaPower;
        this.assessmentsData.push(data);
      }
      else{
        this.edit=false;
      }
      this.getProfileImage() ;
    },
    (error: any) => {
      console.error('Error fetching assessment data', error);
    }
  );
  }
  }
  getinjuryanalysis() {
 
    this.clearPreviousData()
    if (this.id && this.category == 10) {
      this.assessmentService.getInjuryByAsm(this.athleteId, this.id).subscribe((response: any) => {
        if (response && response.length > 0) {
          this.edit=true;
       
          if (this.roleId == 2) {
            this.selectdata = response.filter((assessment: any) => assessment.ipaAssessor == this.assesserId);
          } else {
            this.selectdata = response.filter((assessment: any) => assessment.ipaAssessor === this.UserId);
          }
         
   
          const data =   this.selectdata[0];
          this.formData['ipaId'] = data.ipaId;
          this.formData['ipaAthlete'] = data.ipaAthlete;
          this.formData['ipaAssessor'] = data.ipaAssessor;
          this.formData['athleteName']=data.athleteName;
       
          this.formData['ipaAssessmentDate'] = data.ipaAssessmentDate ; // Convert date format
          this.formData['assessmentDate'] = data.ipaAssessmentDate ? data.ipaAssessmentDate.split('T')[0] : '';
 
          this.formData['ipaAssessment'] = data.ipaAssessment;
          this.formData['ipaDeepSquat'] = data.ipaDeepSquat;
          this.formData['ipaInlineLunge'] = data.ipaInlineLunge;
          this.formData['ipaHurdleStep'] = data.ipaHurdleStep;
          this.formData['ipaActiveSLR'] = data.ipaActiveSLR;
          this.formData['ipaShoulderMobility'] = data.ipaShoulderMobility;
          this.formData['ipaPushUp'] = data.ipaPushUp;
          this.formData['ipaRotaryStability'] = data.ipaRotaryStability;
          this.formData['ipaTotalScore'] = data.ipaTotalScore;
          this.assessmentsData.push(data);
        } else{
          this.edit=false;
        }
        this.getProfileImage();
      },
        (error: any) => {
         
          console.error('Error fetching assessment data', error);
        }
      );
    }
  }
 
  checkUserSummary() {
    this.assessmentService.checkUserSummary(this.athleteId, this.id).subscribe((response: any) => {
      if (!response) {
        // this.getCognitive();
        // this.getfitness();
        // this.getCardiology();
        // this.sendMessage() ;
      } else {
        this.getAllAssessmentData()
      }
 
    }, error => {
 
      console.error('Error getting details ', error);
    });
  }
  onAthleteChange(event: any) {
    const selectedValue = event.target.value;
    const selectedPlayer = this.playerList.find(player => player.aplPlayer === selectedValue);
    if (selectedPlayer) {
      this.id = selectedPlayer.aplAssessment;
      this.athleteId =selectedPlayer.aplPlayer;
      this.clearProfileImage();
      this.clearPreviousData();
      this.getProfileImage();
      this.getCardiology()
      this.getfitness()
      this.getCognitive()
      this.GetLevelOne()
      this.getinjuryanalysis()
      this. GetAthelete()
      this.getSportsPerformanceAnalysis()
      this.GetPlayer();
 
      };
    }
    clearPreviousData() {
   
      this.formData = {};
      this.selectdata = [];
      this.assessmentsData = []; 
   
  }
  clearProfileImage() {
    this.ImageUrl = null;   // Remove previous image URL
    this.showImage = false; // Hide image
}
  sendMessage() {
  //   const assessmentDataJson = JSON.stringify(this.jsonData);
  //   console.log(assessmentDataJson, "full data")
  //   const message = "in this data analyse and give me the overall summery of his performence without mention mark in three lines in one paragraph";
  //   const combinedMessage = `
  //   {
  //     "data": ${assessmentDataJson},
  //     "message": "${message}"
  //   }
  // `;
  //   console.log(combinedMessage, "message")
  //   this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
  //     const chatContent = chatResponse?.choices?.[0]?.message?.content;
  //     // this.chat = chatContent;
  //     // console.log(this.chat, "chat")
  //     if (chatContent) {
 
  //       try {
  //         this.chat = chatContent;
  //         console.log(this.chat, "chat")
  //         this.getActionPlan();
  //       } catch (error) {
  //         this.chat = chatContent;
  //         console.error('Error parsing chat response JSON:', error);
  //       }
  //     } else {
  //       console.error('Unexpected chat response structure:', chatResponse);
  //     }
 
  //   });
  }
  getActionPlan() {
  //   const assessmentDataJson = JSON.stringify(this.jsonData);
  //   console.log(assessmentDataJson, "full data")
  //   const message = "in this data analyse and give me the overall Key insights on injury assessments for the player based on provided data including isokinectic assessments And key insights and performance comparisons for the player based on the provided data without mention mark in three lines in one paragraph";
  //   const combinedMessage = `
  //   {
  //     "data": ${assessmentDataJson},
  //     "message": "${message}"
  //   }
  // `;
  //   console.log(combinedMessage, "message")
  //   this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
  //     const chatContent = chatResponse?.choices?.[0]?.message?.content;
  //     // this.chat = chatContent;
  //     // console.log(this.chat, "chat")
  //     if (chatContent) {
 
  //       try {
  //         this.Plan = chatContent;
  //         console.log(this.Plan, "plan")
  //         this.saveSummary();
  //       } catch (error) {
  //         this.chat = chatContent;
  //         console.error('Error parsing chat response JSON:', error);
  //       }
  //     } else {
  //       console.error('Unexpected chat response structure:', chatResponse);
  //     }
 
  //   });
  }
  saveSummary() {
    this.summary.ausAssessment = this.id;
    this.summary.ausAthlete = this.athleteId;
    this.summary.ausSummary = this.chat;
    this.summary.ausActionPlan = this.Plan;
    this.assessmentService.saveSummary([this.summary]).subscribe(response => {
      this.notify.success("Form submitted successfully");
      setTimeout(() => {
        this.router.navigate(['pendingform']);
      }, 1000);
    }, error => {
      console.error('Error submitting assessment', error);
 
    });
  }
  getAllAssessmentData() {
    forkJoin({
      fitnessData: this.assessmentService.getFitnessByAsm(this.athleteId, this.id),
      cognitiveData: this.assessmentService.getCognitiveByAsm(this.athleteId, this.id),
      injuryPreventionData: this.assessmentService.getInjuryByAsm(this.athleteId, this.id),
      nutritionData: this.assessmentService.getNutritionByAsm(this.athleteId, this.id),
      sportsPerformanceData: this.assessmentService.getSportsperformanceByAsm(this.athleteId, this.id),
      cardioData: this.assessmentService.getCardioByAsm(this.athleteId, this.id)
    }).subscribe(
      (result: any) => {
        const formattedData = this.formatAssessmentData(result);
        this.jsonData=formattedData;
        this.sendMessage();
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  formatAssessmentData(data: any) {
    return {
      fitness: data.fitnessData,         
      cognitive: data.cognitiveData,  
      injuryPrevention: data.injuryPreventionData,
      nutrition: data.nutritionData, 
      sportsPerformance: data.sportsPerformanceData, 
      cardio: data.cardioData 
    };
  }
}