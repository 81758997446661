import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, ViewChild,OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink, RouterModule,ActivatedRoute} from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FileuploadComponent } from '../../../assessment/fileupload/fileupload.component';
import { User } from '../../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { Privilege } from '../../../model/Privillage';
import { AssessmentService } from '../../../services/assessment.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
import { PrivilegeService } from '../../../services/privilage.service';
import { Location } from '@angular/common';


interface IRow {}

@Component({
  selector: 'app-nutrition-assessmentlist',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink,  FormsModule,AgGridAngular,FileuploadComponent],
  templateUrl: './nutrition-assessmentlist.component.html',
  styleUrl: './nutrition-assessmentlist.component.css'
})
export class NutritionAssessmentlistComponent {

  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
 
  user: User | null = null;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  ramPrivilege: PrivilegeEnum | undefined;
 
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  id:any;
  assessor: any;
  testCategory: any;
  selectedStatus: string = '0';
  selectdata: any;
  role: any;
  test: any;
  asmId: any;
  groupedData: { [key: string]: any[] } = {};
  Assessor: boolean;
  Coach: boolean;
 
  constructor(private assessmentService: AssessmentService, private location: Location,private route: ActivatedRoute, private router: Router, private sharedService: SharedService,
    private ngZone: NgZone,private userService:UserService,private priService:PrivilegeService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;

    this.Assessor = this.privilege.isAssessor;
    this.Coach = this.privilege.isCoach;


    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.assessor = params ['assessor'];
      this.testCategory = params ['testCategory'];
      console.log(this.id,"ID");
      this.getPendingAssessment();
    })
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.role=this.detail.user.usrRole;
      console.log(this.org,"org")
    });
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
  this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
    this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.testCategory);
  // Create a map to store unique assessments based on PlayerId (or any unique identifier)
  const uniqueAssessments = new Map<number, any>();
  console.log('Assessment submitted', response);

  // Filter the response to only include unique assessments
  this.selectdata.forEach((item: any) => {
    // Only add the item if it does not exist in the map
    if (!uniqueAssessments.has(item.aplPlayer)) {
      uniqueAssessments.set(item.aplPlayer, {
        "Player Name": `${item.usrFullName}`,
        PlayerId: `${item.aplPlayer}`,
        teamId: `${item.teamId}`,
        id: `${item.aplAssessment}`,
        "Email": `${item.usrEmail}`,
        "Phone No": `${item.usrPhoneNo}`,
        "DOB": new Date(item.usrDoB).toLocaleDateString(),
        "status": `${item.aplStatus}`,
      });
    }
  });

  // Convert the unique assessments map back to an array for your rowData
  this.rowData = Array.from(uniqueAssessments.values());

  this.colDefs = this.getColumnDefinitions();
}, error => {
  console.error('Error submitting assessment', error);
});
    
  }
 
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      {field:'PlayerId'},
      { field: 'Player Name' },
      { field: 'Email' },
      { field: 'DOB'},
      { field: 'Phone No' },
      {
        field: 'Status',
        cellRenderer: (_params: any) => {
          const statusElement = document.createElement('span');
          if (_params.data.status == 0) {
            statusElement.textContent = 'Not Completed';
            statusElement.style.color = 'red'; // Text color red for "Not Completed"
          } else if (_params.data.status == 1) {
            statusElement.textContent = 'Completed';
            statusElement.style.color = 'green'; // Text color green for "Completed"
          }
          return statusElement;
        }
      },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

      
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          if (_params.data.status == 0) {
            viewButton.disabled = true;
            // viewButton.style.cursor = 'not-allowed'; 
            // viewButton.style.opacity = '0.5';
          }
          viewButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/nutritiondetail'], {
                queryParams: { 
                  athleteId: _params.data.PlayerId, 
                  id: _params.data.id,
                }
              });
            });
          });
         
          // View button with eye icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.addEventListener('click', () => {
            this.ngZone.run(() => {
              this.router.navigate(['/pendingform'], {
                queryParams: { 
                  teamId: _params.data.teamId, 
                  athleteId: _params.data.PlayerId, 
                  category:this.testCategory,
                  id: _params.data.id ,
                  assessor: this.assessor
                                  
                }
              });
            });
          });
       
          container.appendChild(viewButton);
   
          container.appendChild(editButton);
       
       
          return container;
        },
        width: 120
      }

    ];
 
    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'Player Name');
    }else if (this.Coach){
      return columns;
    } else{
      return columns.filter(col =>col.field !== 'Assessor'&& col.field !== 'Player Name' && col.field !== 'Email' && col.field !== 'DOB' && col.field !== 'Phone No');
    }
  }
 
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  getPendingAssessment() {

    this.assessmentService.getAssessmentTest(this.id).subscribe(
      (response) => {
        this.test=response;
        this.groupDataBySsName();
        console.log(response,"test")
        const uniqueAssessments = new Map<number, any>();
      },
      (error) => {
        console.error('Error retrieving pending assessments', error);
      }
    );

}
groupDataBySsName() {
  this.groupedData = this.test.reduce((acc: { [x: string]: { [y: string]: any[]; }; }, item: { ssName: string; assesserName: string; }) => {
    if (!acc[item.ssName]) {
      acc[item.ssName] = {};
    }
    if (!acc[item.ssName][item.assesserName]) {
      acc[item.ssName][item.assesserName] = [];
    }
    acc[item.ssName][item.assesserName].push(item);
    return acc;
  }, {});
  console.log(this.groupedData, "Grouped data by ssName and assesserName");
}


onBack():void{
  this.location.back();
}
}
