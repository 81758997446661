<div class="body-wrapper">
    <div class="team-single">
        <div class="d-flex justify-content-between mb-3 gap-4">
            <div>
              <button class="btn btn-primary" (click)="onBack()">Back</button>
          </div>
          <div>
            <button type="button" class="accept"(click)="downloadPDF()">Download</button>  
          </div>
          </div>
        <h4 class="font-size38 sm-font-size32 xs-font-size30">{{athleteReport.athleteFullName}}</h4>
        <div class="contact-info-section margin-40px-tb" *ngFor="let athlete of athleteReport">

            <div id="pdf-content">
                <div class="row">
                    <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
                        <div class="col-lg-12">
                            <div class="card mb-4">
                                <div class="content-header">
                                    <h2 class="sub-title">Nutrition Analysis</h2>
                                </div>
                                <div class="content-body">
                                    <div class="mb-4">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">Sports Activity</p>
                                                <strong>82%</strong>
                                            </div>

                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar bg-green" role="progressbar" style="width: 82%"
                                                aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">Technique Analysis</p>
                                                <strong>42%</strong>
                                            </div>

                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar bg-yellow" role="progressbar" style="width: 49%"
                                                aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="clearfix">
                                            <div class="d-flex justify-content-between align-items-center mb-3">
                                                <p class="small-text">Performance Analysis</p>
                                                <strong>12%</strong>
                                            </div>

                                        </div>
                                        <div class="progress progress-xs">
                                            <div class="progress-bar bg-red" role="progressbar" style="width: 12%"
                                                aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="bg-light-blue">
                                <h4 class="side-Title">{{athlete.usrFullName}}</h4>
                                <p class="side-content">Athlete</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 card col-md-7">
                        <div class="card-body">
                            <div class="team-single-text padding-50px-left sm-no-padding-left">
                                <div class="mobile-res d-flex justify-content-between mb-4">
                                    <div>
                                        <h4 class="Title">Player ID : {{athlete.naAthlete}}</h4>
                                        <a href="">{{athlete.usrEmail}}</a>
                                    </div>
                                    <div>
                                        <h4 class="sub-title">{{athlete.naDate | date: 'dd-MM-yyyy'}}</h4>
                                        <p>Assessment Date:</p>
                                    </div>
                                </div>
                                <div class="contact-info-section margin-40px-tb">
                                    <ul class="list-style9 no-margin">
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">BodyWeight:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naBodyWeight}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">BodyHeight:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naBodyHeight}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">BodyFat:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naBodyFat}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">BMI:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naBMI}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">Fat Free Weight:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naFatFreeWeight}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">Skeletal Muscle:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naSkeletalMuscle}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">BoneMass:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naBoneMass}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">BMR:</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naBMR}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">

                                                    <strong class="margin-10px-left ">MetabolicAge</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naMetabolicAge}}</p>
                                                </div>
                                            </div>

                                        </li>
                                        <li>

                                            <div class="row">
                                                <div class="col-md-5 col-5">
                                                   
                                                    <strong class="margin-10px-left">HealthScore</strong>
                                                </div>
                                                <div class="col-md-7 col-7">
                                                    <p>{{athlete.naHealthScore}}</p>
                                                </div>
                                            </div>

                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>