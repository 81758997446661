<body>
  <div class="site" id="page">
    <div class="container">
      <div class="form-box">
        <div class="progress">
          <div class="back-icon">
            <a *ngIf="this.orgId === undefined" href="/">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
            <a *ngIf="this.orgId !== undefined" href="registerrequest">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
          </div>
          <div class="side-bar">
            <div class="logo">
              <a *ngIf="this.orgId === undefined" href="/">
                <span>sports</span>
                design
              </a>
              <a *ngIf="this.orgId !== undefined" href="registerrequest">
                <span>sports</span>
                design
              </a>
            </div>
            <ul class="progress-steps">
              <li class="step active">
                <span>1</span>
                <p>Step<br><span>Personal Information</span></p>
              </li>
              <li class="step">
                <span>2</span>
                <p>Step<br><span>Medical History Information</span></p>
              </li>
              <li class="step">
                <span>3</span>
                <p>Step<br><span>Sports-Specific Information</span></p>
              </li>
            </ul>
          </div>
        </div>

        <form (ngSubmit)="submitForm()" #registerform="ngForm">

          <div class="form-one form-step active usrprofile">
            <div class="bg-svg"></div>
            <h2>Player Registration</h2>
            <p>Please provide your name, email address, and phone number.</p>
            <div class="form-custom form-container">
              <div class="input-fields">
                <label>Full Name <span class="required">*</span></label>
              
                <input type="text" placeholder="Enter full name" required [(ngModel)]="formData.usrFullName"
                  name="usrFullName" #usrFullName="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrFullName.invalid,
                  'has-value': formData.usrFullName,
                  'error': usrFullName.invalid && isSubmitted,
                }">
              </div>
              <div class="input-fields">
                <label>Date of Birth <span class="required">*</span></label>
              
                <input type="date" required [(ngModel)]="formData.usrDoB" name="usrDoB" #usrDoB="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrDoB.invalid,
                  'has-value': formData.usrDoB,
                  'error': usrDoB.invalid && isSubmitted
                }" [max]="today">
              </div>
              <div class="input-fields">
                <label>Gender <span class="required">*</span></label>
               
                <select required required [(ngModel)]="formData.usrGender" name="usrGender">
                  <option value="" disabled selected>Select gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Others</option>
                </select>
              </div>
              <div class="input-fields">
                <label>Height <span class="required">*</span></label>
                
                <input type="number" placeholder="Enter Height" required [(ngModel)]="formData.usrHeight"
                  name="usrHeight" #usrHeight="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrHeight.invalid,
                  'has-value': formData.usrHeight,
                  'error': usrHeight.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Weight <span class="required">*</span></label>
                
                <input type="number" placeholder="Enter Weight" required [(ngModel)]="formData.usrWeight"
                  name="usrWeight" #usrWeight="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrWeight.invalid,
                  'has-value': formData.usrWeight,
                  'error': usrWeight.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Religion <span class="required">*</span></label>
              
                <input type="text" placeholder="Enter Religion" required [(ngModel)]="formData.usrReligion"
                  name="usrReligion" #usrReligion="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrReligion.invalid,
                  'has-value': formData.usrReligion,
                  'error': usrReligion.invalid && isSubmitted
                }" inputmode="text" pattern="[A-Za-z\s]*" onkeypress="return /[a-zA-Z\s]/.test(event.key)">
                <!-- Error Message -->
                <div *ngIf="usrReligion.errors?.['pattern'] && usrReligion.touched" class="error-message">
                  Only letters are allowed.
                </div>

              </div>
              <div class="input-fields">
                <label>Category <span class="required">*</span></label>
            
                <select required required [(ngModel)]="formData.usrCaste" name="usrCaste">
                  <option value="" disabled selected>Select Caste</option>
                  <option value="1">General</option>
                  <option value="2">SC</option>
                  <option value="3">ST</option>
                  <option value="4">OBC</option>
                </select>
              </div>
              <div class="input-fields">
                <label>Address <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Address" required [(ngModel)]="formData.usrAddress"
                  name="usrAddress" #usrAddress="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrAddress.invalid,
                  'has-value': formData.usrAddress,
                  'error': usrAddress.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Phone Number <span class="required">*</span></label>
              
                <input
                  type="text"
                  placeholder="Enter Phone Number"
                  required
                  [(ngModel)]="formData.usrPhoneNo"
                  name="usrPhoneNo"
                  #usrPhoneNo="ngModel"
                  [ngClass]="{
                    'invalid-field': registerform.submitted && usrPhoneNo.invalid,
                    'has-value': formData.usrPhoneNo,
                    'error': usrPhoneNo.invalid && isSubmitted
                  }"
                  maxlength="10"
                  (input)="validatePhoneNumber($event)"
                />
              
                <div *ngIf="(usrPhoneNo.errors?.['minlength'] && usrPhoneNo.touched) || (usrPhoneNo.errors?.['required'] && usrPhoneNo.touched)" class="error-message">
                  Phone number must be  10 digits long.
                </div>
              </div>
              



              <div class="input-fields">
                <label>Email Address <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Email Address" required [(ngModel)]="formData.usrEmail"
                  name="usrEmail" #usrEmail="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrEmail.invalid,
                  'has-value': formData.usrEmail,
                  'error': usrEmail.invalid && isSubmitted
                }">

              </div>
              <div class="input-fields">
                <label>Create Password <span class="required">*</span></label>
              
                <input type="password" placeholder="Enter Password" required [(ngModel)]="formData.usrPassword"
                  name="usrPassword" #usrPassword="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrPassword.invalid,
                  'has-value': formData.usrPassword,
                  'error': usrPassword.invalid && isSubmitted
                }">

              </div>
              <div class="input-fields">
                <label>Emergency Contact Name <span class="required">*</span></label>
            
                <input type="text" placeholder="Enter Name" required [(ngModel)]="formData.usrECName" name="usrECName"
                  #usrECName="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrECName.invalid,
                  'has-value': formData.usrECName,
                  'error': usrECName.invalid && isSubmitted
                }"inputmode="text" pattern="[A-Za-z\s]*" onkeypress="return /[a-zA-Z\s]/.test(event.key)">
                <!-- Error Message -->
                <div *ngIf="usrECName.errors?.['pattern'] && usrECName.touched" class="error-message">
                  Only letters are allowed.
                </div>
              </div>
              <div class="input-fields">
                <label>Relationship <span class="required">*</span></label>
             
                <input type="text" placeholder="Enter Relationship" required [(ngModel)]="formData.usrECRelationship"
                  name="usrECRelationship" #usrECRelationship="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrECRelationship.invalid,
                  'has-value': formData.usrECRelationship,
                  'error': usrECRelationship.invalid && isSubmitted
                }" inputmode="text" pattern="[A-Za-z\s]*" onkeypress="return /[a-zA-Z\s]/.test(event.key)">
                <!-- Error Message -->
                <div *ngIf="usrECRelationship.errors?.['pattern'] && usrECRelationship.touched" class="error-message">
                  Only letters are allowed.
                </div>
              </div>
              <div class="input-fields">
                <label>Emergency Contact Phone Number <span class="required">*</span></label>
              
                <input
                  type="text"
                  placeholder="Phone Number"
                  required
                  [(ngModel)]="formData.usrECPhoneNo"
                  name="usrECPhoneNo"
                  #usrECPhoneNo="ngModel"
                  [ngClass]="{
                    'invalid-field': registerform.submitted && usrECPhoneNo.invalid,
                    'has-value': formData.usrECPhoneNo,
                    'error': usrECPhoneNo.invalid && isSubmitted
                  }"
                  
                  maxlength="10"
                  (input)="validateEcPhoneNumber($event)" 
                />
              
                
                <div
                  *ngIf="(usrECPhoneNo.errors?.['minlength'] && usrECPhoneNo.touched) || (usrECPhoneNo.errors?.['required'] && usrECPhoneNo.touched)"
                  class="error-message"
                >
                  Phone number must be 10 digits long.
                </div>
              </div>
              
              <!-- <div class="input-fields">
                <label>Profile Image </label>
                <input type="file" class="form-control"  [(ngModel)]="formData.usrprofile" name="usrECPhoneNo" placeholder="Enter Phone Number" required
                #usrprofile="ngModel"
                [ngClass]="{
                  'invalid-field': registerform.submitted && usrprofile.invalid,
                  'has-value': formData.usrprofile
                }" />
              </div> -->

              <div class="input-fields">
                <label>Organization <span class="required">*</span></label>
                
                <select [(ngModel)]="formData.usrOrganization" name="usrOrganization" required
                  #usrOrganization="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && usrOrganization.invalid,
                  'has-value': formData.usrOrganization,
                  'error': usrOrganization.invalid && isSubmitted
                }">
                  <option value="" disabled>Select Organization</option>
                  <option *ngFor="let organization of Orgainsation" [value]="organization.orgId">
                    {{ organization.orgName }}
                  </option>
                </select>

              </div>
              <div class="input-fields">
                <label>class <span class="required">*</span></label>
                
                <select required required [(ngModel)]="formData.usrClass" name="usrClass">
                  <option value="" disabled selected>Select Class</option>
                  <option value="1">Middle School</option>
                  <option value="2">High School</option>
                  <option value="3">College</option>
                </select>
              </div>

            </div>
          </div>
          <div class="form-two form-step ">
            <div class="bg-svg"></div>
            <h2>Medical History</h2>
            <p>Please provide your medical history information.</p>
            <div class="form-custom form-container">
              <div class="input-fields">
                <label>Allergies <span class="required">*</span></label>
            
                <input type="text" placeholder="Enter Allergies" required [(ngModel)]="formData.umhAllergies"
                  name="umhAllergies" #umhAllergies="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && umhAllergies.invalid,
                  'has-value': formData.umhAllergies,
                  'error': umhAllergies.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Current Medications <span class="required">*</span></label>
               
                <input type="text" placeholder="Enter Current Medications" required
                  [(ngModel)]="formData.umhCurrentMedications" name="umhCurrentMedications"
                  #umhCurrentMedications="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && umhCurrentMedications.invalid,
                  'has-value': formData.umhCurrentMedications,
                  'error': umhCurrentMedications.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Previous Injuries <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Previous Injuries" required
                  [(ngModel)]="formData.umhPreviousInjuries" name="umhPreviousInjuries" #umhPreviousInjuries="ngModel"
                  [ngClass]="{
                  'invalid-field': registerform.submitted && umhPreviousInjuries.invalid,
                  'has-value': formData.umhPreviousInjuries,
                  'error': umhPreviousInjuries.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Chronic Conditions <span class="required">*</span></label>
               
                <input type="text" placeholder="Enter Chronic Conditions" required
                  [(ngModel)]="formData.umhChronicConditions" name="umhChronicConditions"
                  #umhChronicConditions="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && umhChronicConditions.invalid,
                  'has-value': formData.umhChronicConditions,
                  'error': umhChronicConditions.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Surgeries <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Surgeries" required [(ngModel)]="formData.umhSurgeries"
                  name="umhSurgeries" #umhSurgeries="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && umhSurgeries.invalid,
                  'has-value': formData.umhSurgeries,
                  'error': umhSurgeries.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Family Medical History <span class="required">*</span></label>
                <input type="text" placeholder="Enter Family Medical History" required
                  [(ngModel)]="formData.umhFamilyMedicalHistory" name="umhFamilyMedicalHistory"
                  #familyMedicalHistory="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && familyMedicalHistory.invalid,
                  'has-value': formData.umhFamilyMedicalHistory,
                  'error': familyMedicalHistory.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Sleep Patterns <span class="required">*</span></label>
                <input type="text" placeholder="Enter Sleep Patterns" required [(ngModel)]="formData.uliSleepPatterns"
                  name="uliSleepPatterns" #uliSleepPatterns="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && uliSleepPatterns.invalid,
                  'has-value': formData.uliSleepPatterns,
                  'error': uliSleepPatterns.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Diet <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Diet" required [(ngModel)]="formData.uliDiet" name="uliDiet"
                  #uliDiet="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && uliDiet.invalid,
                  'has-value': formData.uliDiet,
                  'error': uliDiet.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Hydration <span class="required">*</span></label>
               
                <input type="text" placeholder="Enter Hydration" required [(ngModel)]="formData.uliHydration"
                  name="uliHydration" #uliHydration="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && uliHydration.invalid,
                  'has-value': formData.uliHydration,
                  'error': uliHydration.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Stress Levels <span class="required">*</span></label>
               
                <input type="text" placeholder="Enter Stress Levels" required [(ngModel)]="formData.uliStressLevels"
                  name="uliStressLevels" #uliStressLevels="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && uliStressLevels.invalid,
                  'has-value': formData.uliStressLevels,
                  'error': uliStressLevels.invalid && isSubmitted
                }">
              </div>
            </div>
          </div>
          <div class="form-three form-step">
            <div class="bg-svg"></div>
            <h2>Sports-Specific Information</h2>
            <p>Please provide your sports-specific information.</p>
            <div class="form-custom form-container">
              <div class="input-fields">
                <label>Primary Sport <span class="required">*</span></label>
               
                <input type="text" placeholder="Enter Primary Sport" required [(ngModel)]="formData.asiPrimarySport"
                  name="asiPrimarySport" #asiPrimarySport="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiPrimarySport.invalid,
                  'has-value': formData.asiPrimarySport
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Position/Role <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Position/Role" required [(ngModel)]="formData.asiRole"
                  name="asiRole" #asiRole="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiRole.invalid,
                  'has-value': formData.asiRole
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Secondary Sport <span class="required">*</span></label>
                
                <input type="text" placeholder="Enter Secondary Sport" required [(ngModel)]="formData.asiSecondarySport"
                  name="asiSecondarySport" #asiSecondarySport="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiSecondarySport.invalid,
                  'has-value': formData.asiSecondarySport
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Event <span class="required">*</span></label>
              
                <input type="text" placeholder="Enter Event" required [(ngModel)]="formData.asiEvent" name="asiEvent"
                  #asiEvent="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiEvent.invalid,
                  'has-value': formData.asiEvent
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Sports Category <span class="required">*</span></label>
                
                <input type="text" placeholder="Sports Category" required [(ngModel)]="formData.asiCategory"
                  name="asiCategory" #asiCategory="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiCategory.invalid,
                  'has-value': formData.asiCategory
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <!-- <div class="input-fields">
                <label>Current Club/Team</label>
                <input type="text" placeholder="Enter Current Club/Team" required
                  [(ngModel)]="formData.asiCurrentClubTeam" name="asiCurrentClubTeam" #asiCurrentClubTeam="ngModel"
                  [ngClass]="{
                  'invalid-field': registerform.submitted && asiCurrentClubTeam.invalid,
                  'has-value': formData.asiCurrentClubTeam
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div> -->

              <div class="input-fields">
                <label>Coach <span class="required">*</span></label>
               
                <select [(ngModel)]="formData.asiCoach" name="asiCoach" required #asiCoach="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiCoach.invalid,
                  'has-value': formData.asiCoach
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
                  <option value="" disabled>Select Coach</option>
                  <option *ngFor="let trainer of Trainer" [value]="trainer.usrId">
                    {{ trainer.usrFullName }}
                  </option>
                </select>

              </div>

              <!-- <div class="input-fields">
                <label>Coach’s Name</label>
                <input type="text" placeholder="Enter Coach’s Name" required [(ngModel)]="formData.asiCoach" name="asiCoach" [value]="1"
               
              (input)="fetchCoach($event)"
              (change)="onAthleteSelected($event)"
                #asiCoach="ngModel"              
                [ngClass]="{
                  'invalid-field': registerform.submitted && asiCoach.invalid,
                  'has-value': formData.asiCoach
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
                <datalist id="athleteList">
                  <option *ngFor="let trainer of trainer" [value]="trainer.usrFullName"></option>
                </datalist>
              </div> -->
              <div class="input-fields">
                <label>Frequency Per Week <span class="required">*</span></label>
               
                <input type="number" placeholder="Enter Frequency Per Week" required
                  [(ngModel)]="formData.asiTrainingFrequency" name="asiTrainingFrequency"
                  #asiTrainingFrequency="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiTrainingFrequency.invalid,
                  'has-value': formData.asiTrainingFrequency
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Duration Per Session <span class="required">*</span></label>
                <input type="number" placeholder="Enter Duration Per Session" required
                  [(ngModel)]="formData.asiTrainingDuration" name="asiTrainingDuration" #asiTrainingDuration="ngModel"
                  [ngClass]="{
                  'invalid-field': registerform.submitted && asiTrainingDuration.invalid,
                  'has-value': formData.asiTrainingDuration
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Participation <span class="required">*</span></label>
                <input type="text" placeholder="Enter Competition Level" required
                  [(ngModel)]="formData.asiCompetitionLevel" name="asiCompetitionLevel" #asiCompetitionLevel="ngModel"
                  [ngClass]="{
                  'invalid-field': registerform.submitted && asiCompetitionLevel.invalid,
                  'has-value': formData.asiCompetitionLevel
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <!-- <div class="input-fields">
                <label>Medal Won</label>
                <input type="text" placeholder="Enter Career Highlights" required
                  [(ngModel)]="formData.asiCareerHighlights" name="asiCareerHighlights" #asiCareerHighlights="ngModel"
                  [ngClass]="{
                  'invalid-field': registerform.submitted && asiCareerHighlights.invalid,
                  'has-value': formData.asiCareerHighlights
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div> -->
              <div class="input-fields">
                <label>Medal Won <span class="required">*</span></label>
                <select required required [(ngModel)]="formData.asiCareerHighlights" name="asiCareerHighlights">
                  <option value="" disabled selected>Select Class</option>
                  <option value="1">District Level</option>
                  <option value="2">State</option>
                  <option value="3">SGFI</option>
                  <option value="4">Nationals</option>
                </select>
              </div>
              <div class="input-fields">
                <label>Short-term Goals <span class="required">*</span></label>
                <input type="text" placeholder="Enter Short-term Goals" required
                  [(ngModel)]="formData.asiShortTermGoals" name="ShortTermGoals" #ShortTermGoals="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && ShortTermGoals.invalid,
                  'has-value': formData.asiShortTermGoals
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <div class="input-fields">
                <label>Long-term Goals <span class="required">*</span></label>
                <input type="text" placeholder="Enter Long-term Goals" required [(ngModel)]="formData.asiLongTermGoals"
                  name="asiLongTermGoals" #asiLongTermGoals="ngModel" [ngClass]="{
                  'invalid-field': registerform.submitted && asiLongTermGoals.invalid,
                  'has-value': formData.asiLongTermGoals
                ,
                  'error': usrFullName.invalid && isSubmitted
                }">
              </div>
              <re-captcha *ngIf="this.orgId === undefined" (resolved)="resolvedCaptcha($event)"
                siteKey="6LfWeiAqAAAAABk1FIax7vg1M55Nj8sVOKtWrpdv"></re-captcha>
            </div>
          </div>
          <div class="btn-group">
            <button type="button" class="btn-prev" (click)="prevStep()" [disabled]="activeStep === 1">
              Back
            </button>
            <button type="button" class="btn-next" (click)="nextStep(registerform)">
              Next Step
            </button>
            <!-- <button type="submit" [disabled]="!captchaResponse" class="btn-submit" *ngIf="activeStep === steps.length">
              Submit
            </button> -->
            <button type="submit" class="btn-submit" *ngIf="activeStep === steps.length">
              Submit
            </button>
          </div>
        </form>

      </div>
    </div>
    <div class="toaster">
      <ng-toast />
    </div>
  </div>
</body>