import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { CommonModule } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';

@Component({
  selector: 'app-physicalfitness-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './physicalfitness-detail.component.html',
  styleUrl: './physicalfitness-detail.component.css'
})
export class PhysicalfitnessDetailComponent  implements OnInit {
  selectedDate: string = ''; // Holds the selected date from the date picker
  id: any;
  athleteReport: any;
  athleteId: any;

  constructor(private router: Router, private route: ActivatedRoute,private assessmentService: AssessmentService,private location: Location) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
      console.log(this.id,"ID");
    });
  
  }

  ngOnInit(): void {
    if(this.athleteId){
    this.assessmentService.getPhysicalFitnessByAsm(this.athleteId,this.id).subscribe((response: any) => {

      this.athleteReport = response;

    });
  }else{
    this.assessmentService.fetchAtletefitness(this.id).subscribe((response: any) => {

      this.athleteReport = response;
  })
  }
  }
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('PhysicalFitness.pdf');
      });
    }
  }

  onBack():void{
    this.location.back();
  }

}