import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridOptions } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from "@angular/router";
import { UserRegisterService } from "../../../services/user-register.service";
import { NgToastModule } from "ng-angular-popup";
import { FormsModule } from "@angular/forms";
import { NotificationService } from "../../../services/Notifications.service";
import { AssessmentService } from "../../../services/assessment.service";
import { SharedService } from "../../../services/shared.service";
import { HttpClient } from "@angular/common/http";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { environment } from '../../../../environments/environment.prod';
import { DomSanitizer } from "@angular/platform-browser";
import { NgSelectModule } from "@ng-select/ng-select";
import saveAs from "file-saver";
 
interface IRow {}
@Component({
  selector: 'app-register',
  standalone: true,
  imports: [CommonModule, RouterOutlet, AgGridAngular, RouterLink, RouterModule, FormsModule,NgToastModule,NgSelectModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  users: string | null = null;
  isAdmin: boolean = false;
  isPlayer: boolean = false;
  isAthlete: boolean = false;
  isEditMode: boolean = false;
  selectedRows: any[] = [];
  organization: any[] = [];
  sport: any[] = [];
  
  UserDetail: any = {
    UsrId:null,
    usrFullName: null,
    usrDoB: null,
    roleName: null,
    usrAddress: null,
    usrPhoneNo: null,
    usrOrganization:null,
    orgName:null,
    usrGender:null,
    usrEmail: null,
    usrClass:null,
    usrECName: null,
    usrECRelationship: null,
    usrECPhoneNo: null,
    umhAllergies: null,
    umhCurrentMedications: null,
    umhPreviousInjuries: null,
    umhChronicConditions: null,
    umhSurgeries: null,
    umhFamilyMedicalHistory: null,
    uliSleepPatterns: null,
    uliDiet: null,
    uliHydration: null,
    uliStressLevels: null,
   
    asiPrimarySport: null,
    asiSecondarySport: null,
    asiCurrentClubTeam: null,
    asiCoach: null,
    asiTrainingFrequency: null,
    asiCompetitionLevel: null,
    asiCareerHighlights: null,
    asiShortTermGoals: null,
    asiLongTermGoals: null
  };
  detail: any;
  selectedstatus: any;
  org: any;
  filesSelected: boolean = false;
  nutrition: any;
  selectedFiles: any;
  duplicates: string[] = [];
  ImageUrl: any;
  showImage: boolean = false;
  gridOptions: GridOptions = {
    rowSelection: 'multiple', // Enable multi-row selection
    suppressRowClickSelection: true,
    onSelectionChanged: this.onSelectionChanged.bind(this)
  };
  showCheckboxes: boolean = false;
  Trainer: any[]=[];
  gridApi: any;
  statusOptions: any[]=[];
  role: any;
  active: any;
  trainer: any[]=[];
  roleId: any;
  teams: any[]=[];

  sportName: any;
  coach: any;
  constructor(private assessmentService: AssessmentService,private http:HttpClient,private router: Router, private notify: NotificationService,
              private route: ActivatedRoute, private sharedService: SharedService,private sanitizer: DomSanitizer,
              private userRegisterService: UserRegisterService, private cdRef: ChangeDetectorRef,private ngZone: NgZone) {

                this.route.queryParams.subscribe(params => {
                  if(params['usrActive']!=undefined){
                  this.selectedStatus = parseInt( params['usrActive']);
                this.onStatusChange();
                  }
                });
              }
 
  activeStep: number = 1;
  formSteps!: NodeListOf<Element>;
  themeClass = "ag-theme-quartz";
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  usrActive: number | null = null;
  usrRole: number | null = null;
  formSubmitted: boolean = false;
  selectedStatus: number = 0;
  selectedTeam: any;
  selectedCoach: string = '';
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true
  };
 
  // statusOptions = [
  //   { value: 0, name: 'FC GOA' },
  //   { value: 1, name: 'Fire City' },
  //   { value: 2, name: 'FC' },
  //   { value: 3, name: 'Titans' }
  // ];
  getOrganizationName(orgId: string): string {
    const org = this.organization.find(o => o.orgId === orgId);
    return org ? org.orgName : ''; // Returns the organization name or an empty string if not found
}
getSportName() {
  // Fetch sports data first
  this.assessmentService.getSport().subscribe(
    (data) => {
      this.sport = data; 
     
     
      const matchedSport = this.sport.find((s: any) => s.sportId === this.UserDetail.usrSport);
      this.sportName = matchedSport ? matchedSport.sportName : null; // Set the sport name
      console.log(this.sportName, "this.sportName");
    },
    (error) => {
      console.error('Error fetching sports:', error);
    }
  );
}

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
    });
    this.loadOrgainsation();
    this.route.queryParams.subscribe(params => {
      this.usrActive = +params['usrActive'] || 0;
      this.loadUsers();
    });
    this.formSteps = document.querySelectorAll('.form-step');
    this.updateProgress();
    this.getSportName();
  }

  selectRole(role: string, roleId: any) {
    // Set all role flags to false
    localStorage.setItem('isCoach', 'false');
    localStorage.setItem('isSupport', 'false');
  
    // Set the selected role flag to true
    switch (role) {
      case 'Trainer':
        localStorage.setItem('isCoach', 'true');
        break;
      case 'Science Staff':
        localStorage.setItem('isSupport', 'true');
        break;
      case 'Manager':
      case 'Assessor':
      case 'Player':
        break;
    }
  
    this.ngZone.run(() => {
      if (role === 'Player') {
        this.router.navigate(['/player-registration'], { queryParams: { Org: this.org } });
      } else {
        // Pass the roleId in the query params for other roles
        this.router.navigate(['/registration'], { queryParams: { Org: this.org, id: roleId } });
      }
    });
  }
  
  loadOrgainsation() {
    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.organization = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.teams = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
  }
  nextStep(): void {
    this.activeStep++;
    if (this.activeStep > this.formSteps.length) {
      this.activeStep = this.formSteps.length;
    }
    this.updateProgress();
  }
  prevStep(): void {
    this.activeStep--;
    if (this.activeStep < 1) {
      this.activeStep = 1;
    }
    this.updateProgress();
  }
 
  updateProgress(): void {
    this.formSteps.forEach((step, i) => {
      if (i === (this.activeStep - 1)) {
        step.classList.add('active');
        this.formSteps[i].classList.add('active');
      } else {
        step.classList.remove('active');
        this.formSteps[i].classList.remove('active');
      }
    });
 
    const prevButton = document.querySelector('.btn-prev') as HTMLButtonElement;
    const nextButton = document.querySelector('.btn-next') as HTMLButtonElement;
    const submitButton = document.querySelector('.btn-submit') as HTMLButtonElement;
 
    if (prevButton) {
      if (this.activeStep === 1) {
        prevButton.disabled = true;
        submitButton.hidden=true;
      } else if (this.activeStep === this.formSteps.length) {
        nextButton.disabled = true;
        submitButton.hidden=false;
      } else {
        prevButton.disabled = false;
        nextButton.disabled = false;
        submitButton.hidden=true;
      }
    }
 
 
  }
  onStatusChange() {
    this.usrActive = this.selectedStatus;
    this.loadUsers();
  }
 
  onSelectTeam() {
   
  }
 
  loadUsers() {
    if (this.usrActive !== null) {
      this.userRegisterService.getAllUsers(this.usrActive, this.org).subscribe((response: any) => {
        this.users = response;
        this.rowData = response.map((item: any) => ({
          UserId: `${item.usrId}`,
          UserName: `${item.usrFullName}`,
          Role: `${item.roleName}`,
          Date: new Date(item.usrDoB).toLocaleDateString(),
          PlayerId: `${item.usrId}`,
          usrRole: `${item.usrRole}`,
          usrActive: `${item.usrActive}`,
          usrOrgId:`${item.usrOrganization}`
          
       
        }));
 
        this.colDefs = this.getColumnDefinitions();
        
      }, error => {
        console.error('data not found', error);
      });
    }
  }
 
  onAssignTeamCoachClick() {
    this.showCheckboxes = true; // Show checkboxes when button is clicked
 
    this.assessmentService.getTeams().subscribe(
      (data) => {
        this.statusOptions = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
 
    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.colDefs = this.getColumnDefinitions(); // Refresh column definitions
  }
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [];
  
    if (this.showCheckboxes) {
      // Add the checkbox column if showCheckboxes is true
      columns.push({
        headerCheckboxSelection: true,  // Enable header checkbox selection
        checkboxSelection: true,        // Enable row selection with checkbox
        width: 80,                      // Set the desired width for the checkbox column
        minWidth: 80,                   // Set the minimum width to prevent expansion
        maxWidth: 80                    // Set the maximum width to prevent resizing
      });
    }
  
    columns.push(
      { field: "UserName" },
      { field: "Role" },
      { field: "Date" },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
  
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.title = "View";
          viewButton.addEventListener('click', () => {
            const userId = _params.data.UserId;
            this.isEditMode = false; // Set view mode
            this.showUserDetails(userId);
          });
          container.appendChild(viewButton);
  
          // Edit button with edit icon
          const editButton = document.createElement('button');
          editButton.classList.add('btn');
          editButton.innerHTML = '<i class="fa fa-edit"></i>';
          editButton.title = "Edit";
          editButton.addEventListener('click', () => {
            const userId = _params.data.UserId;
            this.isEditMode = true; // Set edit mode
            this.showUserDetails(userId);
            this.updateProgress();
          });
          container.appendChild(editButton);
  
          if (this.usrActive !== 1) {
            // Check button with check icon
            const checkButton = document.createElement('button');
            checkButton.classList.add('btn');
            checkButton.innerHTML = '<i class="fa fa-check"></i>';
            checkButton.title = "Accept";
            checkButton.addEventListener('click', () => {
              const userId = _params.data.UserId;
              const isActive = 1;
  
              this.userRegisterService.updateUserStatus(userId, isActive)
                .subscribe(response => {
                  this.ngZone.run(() => {
                    this.notify.success("User status updated successfully");
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000); // Delayed to allow toast to show
                },
                error => {
                  this.ngZone.run(() => {
                    this.notify.failed('Error updating user status');
                  });
                  console.error('Error updating user status', error);
                });
            });
            container.appendChild(checkButton);
          }
  
          if (this.usrActive !== 2) {
            // Cross button with cross icon
            const crossButton = document.createElement('button');
            crossButton.classList.add('btn');
            crossButton.innerHTML = '<i class="fa fa-times"></i>';
            crossButton.title = "Reject";
            crossButton.addEventListener('click', () => {
              const userId = _params.data.UserId;
              const isActive = 2;
  
              this.userRegisterService.updateUserStatus(userId, isActive)
                .subscribe(response => {
                  this.ngZone.run(() => {
                    this.notify.success("User Declined");
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000); // Delayed to allow toast to show
                },
                error => {
                  this.ngZone.run(() => {
                    this.notify.failed('Error declining user');
                  });
                  console.error('Error declining user', error);
                });
            });
            container.appendChild(crossButton);
          }
  
          return container;
        },
        width: 120
      },
      {
        headerName: 'Report',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
          this.role = _params.data.usrRole;
          this.active = _params.data.usrActive;
  
          // Ensure the view button is only shown when usrRole === 1 and usrActive === 1
          if (this.role == 1 && this.active == 1) {
            const viewButton = document.createElement('button');
            viewButton.classList.add('btn');
            viewButton.innerHTML = '<i class="fa fa-eye"></i>';
            viewButton.title = "View Report";
  
            // Add click event to navigate to report page
            viewButton.addEventListener('click', () => {
              this.ngZone.run(() => {
                this.router.navigate(['/report'], { 
                  queryParams: { id: _params.data.PlayerId, orgId: _params.data.usrOrgId,user:5 } 
                });
              });
            });
            container.appendChild(viewButton);
          }
  
          return container;
        },
        width: 100
      }
    );
  
    if (this.isAdmin) {
      return columns;
    } else if (this.isPlayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
  
 
  onSelectionChanged(event: any) {
    this.selectedRows = event.api.getSelectedRows();
  }
 
  onGridReady(params: any) {
    this.gridApi = params.api;
  }
 
  updateSelection() {
    // Collect the selected team and coach values
    const selectedTeam = this.selectedTeam;
    const selectedCoach = this.selectedCoach;
 
    // Collect the selected rows data (checkbox data)
    const selectedRowsData = this.gridApi.getSelectedRows(); // Assuming `gridApi` is a reference to your grid instance
 
    const athleteIds = selectedRowsData.map((user: any) => user.UserId);
    // Prepare the data object to be passed
    const updateData = {
      TeamId: selectedTeam,
      CoachId: selectedCoach,
      AthleteIds: athleteIds // This contains the data of the selected rows
    };
 
 // Debugging: Check if the data is being gathered correctly
 
    // Now, pass this data to a service or handle it as needed
    this.assessmentService.updateUserSelection(updateData).subscribe(
      response => {
        this.notify.success('Selection updated successfully');
      },
      error => {
        this.notify.failed('Error updating selection');
        console.error('Error updating selection:', error);
      }
    );
  }
 
 
  ChangePasswordModal(): void {
    // Reset the form fields to empty
    this.nutrition.naAthleteName = '';
    this.nutrition.email = '';
    this.nutrition.newPassword = '';
 
  }
 
  showChangePasswordModal(userId: number): void {
    this.userRegisterService.fetchUser(userId).subscribe(userData => {
      this.UserDetail = userData[0];
      this.activeStep = 1; // Reset step to the first one whenever user details are shown
 
      // Populate the form fields with user details
      this.nutrition.naAthleteName = this.UserDetail.usrFullName;
      this.nutrition.email = this.UserDetail.usrEmail;
 
      this.cdRef.detectChanges();
 
      // Show the modal
      const modalElement = document.getElementById('changePasswordModal')!;
      const modal = new (window as any).bootstrap.Modal(modalElement);
      modal.show();
    });
  }
 
  showUserDetails(userId: number): void {
    this.activeStep = 1;
    this.userRegisterService.fetchUser(userId).subscribe(userData => {
      this.UserDetail = userData[0];
 
      this.isAthlete = this.UserDetail.roleName === "Athlete";
      this.coach=this.UserDetail.roleName==="Trainer";
      this.cdRef.detectChanges();
 
      // Show the modal
      const modalElement = document.getElementById('customModal')!;
      const modal = new (window as any).bootstrap.Modal(modalElement);
      modal.show();
    });
  }
 
  onModalClose() {
  // Loop through all form steps
  this.formSteps.forEach((step, i) => {
    if (i === 0) {
      // Add 'active' class to the first step (stepOne)
      step.classList.add('active');
    } else {
      // Remove 'active' class from all other steps
      step.classList.remove('active');
    }
  });
 
  // Optionally reset the activeStep to 1 (first step)
  this.activeStep = 1;
  this.checkbutton();
 
  }
 
  checkbutton() {
    const prevButton = document.querySelector('.btn-prev') as HTMLButtonElement;
    const nextButton = document.querySelector('.btn-next') as HTMLButtonElement;
    const submitButton = document.querySelector('.btn-submit') as HTMLButtonElement;
 
    if (this.activeStep === 1) {
      prevButton.disabled = true;
      nextButton.disabled = false;
      submitButton.hidden=true;
    } else if (this.activeStep === this.formSteps.length) {
      nextButton.disabled = true;
      submitButton.hidden=false;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
      submitButton.hidden=true;
    }
  }
 
 
  saveUserDetails(): void {
   // this.trainerDetail.usrActive = this.orgId
   this.onModalClose();
    const formElement = document.getElementById('userForm') as HTMLFormElement;
 
    if (!formElement) {
      console.error('Form element not found');
      return;
    }
 
    const formData = new FormData(formElement);
    const data: any = {};
   
    formData.forEach((value, key) => {
      data[key] = value;
    });
 
   
    // Check if the user is a player
    if (this.isAthlete) {
      this.UserDetail=data;
      this.formSubmitted = true;
      this.userRegisterService.userRegister([this.UserDetail]).subscribe(response =>{
        if (response[0].usrId) {
          this.notify.success("Form submitted successfully");
         
        } 
       
      }, error => {
        this.notify.failed('Error submitting form');
        console.error('Error submitting form', error);
      });
 
    } else {
      // Send only specific fields for non-players
      this.UserDetail = {
        usrId:data.usrId,
        usrFullName: data.usrFullName,
        usrDoB: data.usrDoB,    
        usrGender:data.usrGender,
        usrRole: data.usrRole,
        usrAddress: data.usrAddress,
        usrPhoneNo: data.usrPhoneNo,
        usrOrganization: this.org,
        usrReligion:data.usrReligion,
        usrCaste:data.usrCaste,
        usrEmail: data.usrEmail,
        usrECName: data.usrECName,
        usrECRelationship: data.usrECRelationship,
        usrECPhoneNo: data.usrECPhoneNo,
     
      };
    this.assessmentService.addTrainer([this.UserDetail]).subscribe(
      (response: any) => {
        const modalElement = document.getElementById('customModal')!;
          const modal = new (window as any).bootstrap.Modal(modalElement);
          modal.hide();
          this.loadUsers();
        this.notify.success("Data submitted successfully!");
        setTimeout(() => {
       
        }, 1000);
      },
      (error: any) => {
        this.notify.failed('Error submitting form');
        setTimeout(() => {
 
        }, 1000);
        console.error('Error submitting form', error);
      });
 
  }
  }



  saveUserDetail(event: Event): void {
    event.preventDefault(); // Prevent default form submission
    
    

    const formElement = document.getElementById('userForm') as HTMLFormElement;
  
    if (!formElement) {
      console.error('Form element not found');
      return;
    }
  
    const formData = new FormData(formElement);
    const data: any = {};
  
    formData.forEach((value, key) => {
      data[key] = value;
    });
  
    let UserData: any = {};
    if (this.isAthlete) {
      UserData = data;
      this.formSubmitted = true;
  
      this.userRegisterService.userRegister([UserData]).subscribe(
        (response) => {
          if (response[0].usrId) {
            this.notify.success("Form submitted successfully");
            const modalElement = document.getElementById('customModal')!;
            const modal = new (window as any).bootstrap.Modal(modalElement);
            modal.hide();
          }
        },
        (error) => {
          this.notify.failed('Error submitting form');
          console.error('Error submitting form', error);
        }
      );
    } else {
      UserData = {
        usrId: data.usrId,
        usrFullName: data.usrFullName,
        usrDoB: data.usrDoB,
        usrGender: data.usrGender,
        usrRole: data.usrRole,
        usrAddress: data.usrAddress,
        usrPhoneNo: data.usrPhoneNo,
        usrOrganization: data.usrOrganization,
        usrReligion:data.usrReligion,
        usrCaste:data.usrCaste,
        usrEmail: data.usrEmail,
        usrECName: data.usrECName,
        usrECRelationship: data.usrECRelationship,
        usrECPhoneNo: data.usrECPhoneNo,
      };
  
      this.assessmentService.addTrainer([UserData]).subscribe(
        (response: any) => {
          this.notify.success("Data submitted successfully!");
          const modalElement = document.getElementById('customModal')!;
          const modal = new (window as any).bootstrap.Modal(modalElement);   
          modal.hide();
          this.loadUsers();
    
        },
        (error: any) => {
          this.notify.failed('Error submitting form');
          console.error('Error submitting form', error);
        }
      );
    }
  }
  
  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles != null) {
      this.filesSelected = true;
    }
  }
  
  clearSelectedFiles(fileInput: any) {
    this.selectedFiles = '';
    this.filesSelected = false;
    if (fileInput) {
      fileInput.value = '';
    }
  }
  
  
  
 
  registerUpload() {
    const formData = new FormData();
    for (let i = 0; i < this.selectedFiles.length; i++) {
      formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
    }
 
    this.http.post(environment.BaseUrl+environment.RegisterUploaded, formData).subscribe(
      (response: any) => {
        this.duplicates = response.duplicates || [];
        this.notify.success(response.message);
      },
      (error: any) =>{
        this.duplicates = error.error.duplicates || [];
        this.openModal();
        console.error('File upload failed!', error)}
    );
  }
 
  openModal() {
    const modalElement = document.getElementById('duplicateModal')!;
      const modal = new (window as any).bootstrap.Modal(modalElement);
      modal.show();
  }
 
  openFileUploadModal() {
    // Use Bootstrap's modal method to show the modal
    const fileUploadModal = document.getElementById('fileUploadModal')!;
      const modal = new (window as any).bootstrap.Modal(fileUploadModal);
      modal.show();
  }
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('ExistingEmail.pdf');
      });
    }
  }
  downloadTemplate() {
      const fileUrl = 'assets/Templates/PlayerRegister_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'PlayerRegister_Template.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
   
  }

  getClassName(classId: number): string {
    switch (classId) {
      case 1:
        return 'Middle School';
      case 2:
        return 'High School';
      case 3:
        return 'College';
      default:
        return ''; // Return an empty string if no match
    }
  } 
  getTeamNameById(teamId: string): string {
    const convertid =  Number(teamId);
    const team = this.teams.find(t => t.teamId === convertid );
    return team ? team.teamName : 'N/A'; // Return 'N/A' if no matching team is found
  }
  getCoachNameById(coachId: any): string {
    console.log(coachId,'coach')
    console.log(this.trainer,'tariner')
    const coach = this.trainer.find(t => t.usrId === coachId);
    return coach ? coach.usrFullName : 'N/A';
  }
  validatePhoneNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();  // Prevent input if it's not a number (0-9)
    }
  }
  validateECPhoneNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();  // Prevent input if it's not a number (0-9)
    }
  }
 
  
}
