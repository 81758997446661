import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { AgGridAngular } from 'ag-grid-angular';
import { User } from '../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { UserRegisterService } from '../../services/user-register.service';
import { NotificationService } from '../../services/Notifications.service';
import { NgToastModule } from 'ng-angular-popup';

interface IRow {}

@Component({
  selector: 'app-pending-assessment',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular,NgToastModule],
  templateUrl: './pending-assessment.component.html',
  styleUrls: ['./pending-assessment.component.css']
})
export class PendingAssessmentComponent {
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;

  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Admin: boolean | undefined;
  org: any;
  role: any;
  gridApi: any;

  constructor(
    private assessmentService: AssessmentService,
    private userservice: UserRegisterService,
    private sharedService: SharedService,
    private notify: NotificationService,
    private ngZone: NgZone, 
    private router: Router 
  ) {}

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role=this.detail.user.usrRole;
      this.Status = 0;
    });

    this.getPendingAssessment();
  }

  getPendingAssessment() {
    if(this.role == 2){
      this.assessmentService.getpendingAssessment().subscribe(
        (response) => {
          const uniqueAssessments = new Map<number, any>();
          console.log(response,"response before filter")
          const filteredResponse = response.filter((item: any) => item.astStatus !== 2);
          console.log(filteredResponse,"response after filter")
          filteredResponse.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                sscategory: item.sscategory,
                astStatus: item.astStatus
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
  
          this.rowData = Array.from(uniqueAssessments.values());
          this.colDefs = this.getColumnDefinitions();
        },
        (error) => {
          console.error('Error retrieving pending assessments', error);
        }
      );
    }if (this.role == 1) {
      this.userservice.fetchUser(this.Id).subscribe(
        (userResponse) => { 
          const currentClubTeam = userResponse[0].asiCurrentClubTeam;
    
          this.assessmentService.getpendingAssessment().subscribe(
            (assessmentResponse) => {
    
              // Filter assessments where asmTeam matches asiCurrentClubTeam
              const filteredAssessments = assessmentResponse.filter((item: any) => {
                return item.asmTeam == currentClubTeam;
              });
              const filteredResponse = filteredAssessments.filter((item: any) => item.astStatus !== 2);
              // Create a map to store unique assessments
              const uniqueAssessments = new Map<number, any>();
              filteredResponse.forEach((item: any) => {
                if (!uniqueAssessments.has(item.asmId)) {
                  uniqueAssessments.set(item.asmId, {
                    AssessmentName: item.asmName,
                    TeamName: item.teamName,
                    CoachName: item.coachName,
                    OrganizationName: item.orgName,
                    Description: item.asmDescription,
                    category: item.testCategory,
                    Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                    testNames: [item.testName],
                    CoachId: item.asmCoach,
                    id: item.asmId,
                    sscategory: item.sscategory,
                    astStatus: item.astStatus
                  });
                } else {
                  const existingAssessment = uniqueAssessments.get(item.asmId);
                  if (!existingAssessment.testNames.includes(item.testName)) {
                    existingAssessment.testNames.push(item.testName);
                  }
                }
              });
    
              // Assign filtered and processed data to rowData and set column definitions
              this.rowData = Array.from(uniqueAssessments.values());
              this.colDefs = this.getColumnDefinitions();
            },
            (error) => {
              console.error('Error retrieving pending assessments', error);
            }
          );
        },
        (error) => {
          console.error('Error retrieving user data', error);
        }
      );
    }else{
      this.assessmentService.getPendingAssessmentById(this.Id, this.Status).subscribe(
        (response) => {
          const uniqueAssessments = new Map<number, any>();
          const filteredResponse = response.filter((item: any) => item.astStatus !== 2);
          filteredResponse.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                category:`${item.testCategory}`,
                Description: `${item.asmDescription}`,
                Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                sscategory: item.sscategory,
                astStatus: item.astStatus
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
  
          this.rowData = Array.from(uniqueAssessments.values());
          this.colDefs = this.getColumnDefinitions();
        },
        (error) => {
          console.error('Error retrieving pending assessments', error);
        }
      );
    }

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  deleteAssessment(id: any) {
    this.assessmentService.deleteAssessmentById(id).subscribe((response) => {
      this.notify.success(response.message);
      const updatedRowData = this.rowData.filter((item: any) => item.id !== Number(id));

      if (this.gridApi) {
        const transaction = {
          remove: this.rowData.filter((item: any) => item.id === Number(id)),
        };
        this.gridApi.applyTransaction(transaction);
      } else {
        console.error('gridApi is not initialized');
      }
      this.rowData = updatedRowData;
      this.colDefs = this.getColumnDefinitions();
    });
  }
  

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'AssessmentName' },
      { field: 'TeamName' },
      { field: 'CoachName' },
      { field: 'OrganizationName' },
      { field: 'Description' },
      { field: 'Date' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
  
          // Create View Button
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
  
          // Disable viewButton if role == 1 and astStatus == 0
          if (this.role === 1 && _params.data.astStatus === 0) {
            viewButton.disabled = true;
          }
  
          // View Button click event
          viewButton.addEventListener('click', () => {
            if (this.role == 1) {
              this.ngZone.run(() => {
                this.router.navigate(['/report'], {
                  queryParams: {
                    athleteId: this.Id,
                  }
                });
              });
            } else {
              this.ngZone.run(() => {
                this.router.navigate(['/PendingAssessmentdetail'], {
                  queryParams: {
                    id: _params.data.CoachId,
                    asmId: _params.data.id,
                    category: _params.data.category
                  }
                });
              });
            }
          });
  
          container.appendChild(viewButton);
  
          // Conditionally add the Edit button based on role
          if (this.role !== 1) {
            const editButton = document.createElement('button');
            editButton.classList.add('btn');
            editButton.innerHTML = '<i class="fa fa-edit"></i>';
  
            // Edit Button click event
            editButton.addEventListener('click', () => {
              this.ngZone.run(() => {
                this.router.navigate(['/assignassessment'], {
                  queryParams: {
                    teamId: _params.data.teamId,
                    athleteId: _params.data.PlayerId,
                    assessment: _params.data.AssessmentName,
                    sscategory: _params.data.sscategory
                  }
                });
              });
            });
  
            container.appendChild(editButton);
          }
  
          if (this.role !== 1) {
          // Conditionally show delete button or green tick icon
          if (_params.data.astStatus === 1) {
            // Show green tick icon
            const tickIcon = document.createElement('button');
            tickIcon.classList.add('btn');
            tickIcon.innerHTML = '<i class="fa fa-check"></i>';
            tickIcon.style.color = 'green';
            container.appendChild(tickIcon);
          } else {
            // Add Delete Button
            const deleteButton = document.createElement('button');
            deleteButton.classList.add('btn');
            deleteButton.innerHTML = '<i class="fa fa-trash"></i>';
  
            // Delete Button click event
            deleteButton.addEventListener('click', () => {
              if (confirm('Are you sure you want to delete this item?')) {
                // Execute the delete logic, e.g., calling a service to delete the item
                this.deleteAssessment(_params.data.id);
              }
            });
  
            container.appendChild(deleteButton);
          }
        }
          return container;
        },
        width: 160, // Increase width to accommodate the additional icons
      },
    ];
  
    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter((col) => col.field !== 'playername');
    } else {
      return columns.filter((col) => col.field !== 'Assessor');
    }
  }
  
}
