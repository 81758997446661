import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { FileuploadComponent } from '../../fileupload/fileupload.component';
import { User } from '../../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../../model/PrivilageEnum';
import { Privilege } from '../../../model/Privillage';
import { AssessmentService } from '../../../services/assessment.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
import { PrivilegeService } from '../../../services/privilage.service';



interface IRow {}

@Component({
  selector: 'app-physicalfitness-assessment',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLink, FormsModule,AgGridAngular,FileuploadComponent],
  templateUrl: './physicalfitness-assessment.component.html',
  styleUrl: './physicalfitness-assessment.component.css'
})
export class PhysicalfitnessAssessmentComponent {


  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  @Input() name: any;
 
  user: User | null = null;
 
  themeClass = 'ag-theme-quartz';
 
  rowData: IRow[] = [];
 
  colDefs: ColDef[] = [];
 
  ramPrivilege: PrivilegeEnum | undefined;
  selectedStatus: string = '0';
  selectdata: any;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  privilege: Privilege;
  Isplayer: boolean;
  Admin: boolean;
  detail: any;
  org: any;
  id:any;
  selecteddata: any;
  Userid: any;
  UserRole: any;
  coach: any;


  constructor(private assessmentService: AssessmentService, private router: Router, private sharedService: SharedService,
    private ngZone: NgZone,private userService:UserService,private priService:PrivilegeService) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Admin = this.privilege.isAdmin;
    this.coach = this.privilege.isCoach;
    }
 
  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.org=this.detail.user.usrOrganization;
      this.UserRole = this.detail.user.usrRole;
      this.Userid=this.detail.user.usrId;
   
      this.id = 4;
      console.log(this.org,"org")
    });
    this.user = this.userService.getUser();
    this.ramPrivilege = this.userService.getRamPrivilege();
    console.log(this.ramPrivilege,"Datapri");
    
    this.getPendingAssessment();

    
  }

  getPendingAssessment(){
    this.assessmentService.getPendingAllAssessment(this.id).subscribe(response => {
      this.selectdata = response.filter((assessment: any) => assessment.asmStatus == this.selectedStatus);
      this.selecteddata = response.filter((assessment: any) => assessment.astAssesser ===  Number(this.Userid) && assessment.astStatus === Number(this.selectedStatus));
      const uniqueAssessments = new Map<number, any>();
      const dataToUse = this.UserRole == 2 ? this.selectdata : this.selecteddata;
   
      // Loop through each item and ensure only unique asmId entries are kept
      dataToUse.forEach((item: any) => {
        if (!uniqueAssessments.has(item.asmId)) {
          uniqueAssessments.set(item.asmId, {
            'Assessment Name': `${item.asmName}`,
             Id: `${item.asmId}`,
            "Assessment Description": `${item.asmDescription}`,
            "Organization Name": `${item.orgName}`,
            "Coach Name": `${item.coachName}`,
            "Assessor Name": `${item.assesserName}`,        
            "Team Name": `${item.teamName}`,
             assessor: `${item.astAssesser}`,
             testCategory: `${item.testCategory}`,
            Date: new Date(item.asmDate).toLocaleDateString(),
          });
        }
      });
   
      // Convert the Map back to an array for `rowData`
      this.rowData = Array.from(uniqueAssessments.values());
      this.colDefs = this.getColumnDefinitions();
    }, error => {
      console.error('Error submitting assessment', error);
    });
  }
 
 
  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'Assessment Name' },
      { field: 'Assessment Description' },
      { field: 'Organization Name' },
      { field: 'Team Name'},
      { field: 'Coach Name' },
      { field: 'Assessor Name' },
      { field: 'Date'},
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';
 
          // View button with eye icon
          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
              this.ngZone.run(() => {
              this.router.navigate(['/PhysicalfitnessAssessmentList'], {
                queryParams: { 
                  id: _params.data.Id, 
                  assessor: _params.data.assessor,
                  testCategory: _params.data.testCategory ,

                }
              });
              })
          });
          container.appendChild(viewButton);
          return container;
        },
        width: 120
      }
    ];
 
    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter(col => col.field !== 'playername');
    } else {
      return columns.filter(col => col.field !== 'Assessor');
    }
  }
 
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

}
