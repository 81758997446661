import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AssessmentService } from '../../services/assessment.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cognative-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cognative-detail.component.html',
  styleUrl: './cognative-detail.component.css'
})
export class CognativeDetailComponent implements OnInit {
  id: any;
  athleteReport: any;
  athleteId:any;

  constructor(private router: Router,
     private route: ActivatedRoute,
     private assessmentService: AssessmentService,private location: Location) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId'];
      console.log(this.id,"ID");
    });
 
  }
 
  activities = [
    { name: 'Sports Activity', percentage: 82 },
    { name: 'Technique Analysis', percentage: 42 },
    { name: 'Performance Analysis', percentage: 100 }
  ];
 
  // Dynamically return the color class based on percentage
  getProgressColor(percentage: number): string {
    if (percentage >= 75) {
      return 'bg-green';
    } else if (percentage >= 40) {
      return 'bg-yellow';
    } else {
      return 'bg-red';
    }
  }
 
  ngOnInit(): void {
    if(this.athleteId){
    this.assessmentService.getCognitiveReportByAsm(this.athleteId,this.id).subscribe((response: any) => {
      this.athleteReport = response;
      console.log(this.athleteReport,"report")
 
    });
  }else{
    this.assessmentService.fetchcognitive(this.id).subscribe((response: any) => {
      this.athleteReport = response;
      console.log(this.athleteReport,"report")
 
    });
  }
  }
  
 
 
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('Cognitive-details.pdf');
      });
    }
  }

  onBack():void{
    this.location.back();
  }
 
}