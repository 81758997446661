import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private detailSubject: BehaviorSubject<any>;
  detail$: any;
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    
    const savedDetail = this.isBrowser ? sessionStorage.getItem('detail') : null;
    // Initialize detailSubject with the saved value or null
    this.detailSubject = new BehaviorSubject<any>(savedDetail ? JSON.parse(savedDetail) : null);
    // Initialize detail$ observable after detailSubject is initialized
    this.detail$ = this.detailSubject.asObservable();
  }

  setDetail(data: any) {
    this.detailSubject.next(data);
    if (this.isBrowser) {
      sessionStorage.setItem('detail', JSON.stringify(data));
    }
  }

  getDetail() {
    return this.detailSubject.value;
  }

  clearDetail() {
    this.detailSubject.next(null);
    if (this.isBrowser) {
      sessionStorage.removeItem('detail');
    }
  }
}
