<div class="body-wrapper">
    <div class="team-single">
      <div class="d-flex justify-content-between mb-3 gap-4">
        <div>
          <button class="btn btn-primary" (click)="onBack()">Back</button>
      </div>
      <div>
        <button type="button" class="accept"(click)="downloadPDF()">Download</button>  
      </div>
      </div>
      <div class="contact-info-section margin-40px-tb" *ngFor="let athlete of athleteReport">
        <div id="pdf-content">
          <div class="row">
            <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
              <div class="col-lg-12">
                <div class="card mb-4">
                  <div class="content-header">
                    <h2 class="sub-title">Physical Fitness Analysis</h2>
                  </div>
                  <div class="content-body">
                    <div class="mb-4">
                      <div class="clearfix">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="small-text">Sports Activity</p>
                          <strong>82%</strong>
                        </div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar bg-green" role="progressbar" style="width: 82%" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <div class="clearfix">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="small-text">Technique Analysis</p>
                          <strong>42%</strong>
                        </div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar bg-yellow" role="progressbar" style="width: 42%" aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <div class="clearfix">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <p class="small-text">Performance Analysis</p>
                          <strong>12%</strong>
                        </div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar bg-red" role="progressbar" style="width: 12%" aria-valuenow="12" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="bg-light-blue">
                  <h4 class="side-Title">{{athlete.usrFullName}}</h4>
                  <p class="side-content">Athlete</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 card col-md-7">
              <div class="card-body">
                <div class="team-single-text padding-50px-left sm-no-padding-left">
                  <div class="mobile-res d-flex justify-content-between mb-4">
                    <div>
                      <h4 class="Title">Player ID : {{athlete.pfaAthlete}}</h4>
                    </div>
                    <div>
                      <h4 class="sub-title">{{athlete.pfaDate | date: 'dd-MM-yyyy'}}</h4>
                      <p>Assessment Date</p>
                    </div>
                  </div>
                  <div class="contact-info-section margin-40px-tb">
                    <ul class="list-style9 no-margin">
                      <li *ngIf="athlete.athleteName">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Athlete Name :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.athleteName}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaHeight">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Height :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaHeight}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaWeight">
                        <div class="row" >
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Weight :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaWeight}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaBMI">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">BMI :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaBMI}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaSingleLegBalanceEyesOpen">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Strength:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaSingleLegBalanceEyesOpen}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaPushups">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Push ups :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaPushups}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaPullups">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Pull ups :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaPullups}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaPullAndHang">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Pull and Hang :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaPullAndHang}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaSquat">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Squat :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaSquat}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaSingleLegSquat">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Single Leg Squat :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaSingleLegSquat}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaPartialCurlUp">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Partial Curl up :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaPartialCurlUp}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaGripStrengthRight">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Grip Strength Right :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaGripStrengthRight}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaGripStrengthLeft">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Grip Strength Left :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaGripStrengthLeft}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaSitNReach">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Sit N reach :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaSitNReach}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaAppleysScratchTest">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Apple Scratch Test :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaAppleysScratchTest}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfa50mSprint">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">50 m Sprint:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfa50mSprint}}</p>
                          </div>
                        </div>
                      </li>
                      <li  *ngIf="athlete.pfa30mSprint">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">30 m Sprint:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfa30mSprint}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfa10mShuttleRun">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">10 m ShuttleRun:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfa10mShuttleRun}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaTTest">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">TTest :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaTTest}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.pfaStandingBroadJump">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Standing BroadJump :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaStandingBroadJump}}</p>
                          </div>
                        </div>
                      </li>

                      <li *ngIf="athlete.pfaVerticalJump">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Vertical Jump :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaVerticalJump}}</p>
                          </div>
                        </div>
                      </li>

                      <li *ngIf="athlete.pfaSeatedMedBallThrow">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">SeatedMedBallThrow :</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.pfaSeatedMedBallThrow}}</p>
                          </div>
                        </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end of pdf-content -->
      </div> <!-- end of contact-info-section -->
    </div> <!-- end of team-single -->
  </div> <!-- end of body-wrapper -->