import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AssessmentService } from '../services/assessment.service';
import { NotificationService } from '../services/Notifications.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { PrivilegeService } from '../services/privilage.service';
import { Privilege } from '../model/Privillage';

interface IRow { }

@Component({
  selector: 'app-hydration-question',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLink, HttpClientModule, NgToastModule],
  templateUrl: './hydration-question.component.html',
  styleUrl: './hydration-question.component.css'
})
export class HydrationQuestionComponent implements OnInit {
  currentSection = 1;
  formValues: any = {
    athleteId: '',
    nutrition: {
      postMeal: '',
      mealQuality: '',
      hydration: '',
      supplements: ''
    },
  };
  mode: any;
  teamId: any;
  athleteId: any;
  id: any;
  assessor: any;
  categoryId: any;
  hanId: any;
  ImageUrl: any;
  showImage: boolean = false;
  selectdata: any;
  rowData: IRow[] = [];
  playerList: any[] = [];
  privilege: Privilege;
  Admin: boolean;
  playerName: any;
  image: any;

  constructor(
    private assessmentService: AssessmentService,
    private notify: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private location: Location,
    private priService:PrivilegeService
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Admin = this.privilege.isAdmin;
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      this.teamId = params['teamId'];
      this.athleteId = params['athleteId'];
      this.id = params['id'];
      this.assessor = params['assessor'];
      this.categoryId = params['categoryId'];
    })
  }
  ngOnInit(): void {
    this.getProfileImage();
    this.getHydrationData(this.athleteId, this.id);
    this.GetPlayer();
}

GetPlayer() {
  this.assessmentService.getPlayerByAssessmentId(this.id).subscribe(response => {
    this.selectdata = response.filter((assessment: any) => assessment.aplCategory == this.categoryId);
    // Create a map to store unique assessments based on PlayerId (or any unique identifier)
    const uniqueAssessments = new Map<number, any>();
    console.log('Assessment submitted', response);

    // Filter the response to only include unique assessments
    this.selectdata.forEach((item: any) => {
      // Only add the item if it does not exist in the map
      if (!uniqueAssessments.has(item.aplPlayer)) {
        uniqueAssessments.set(item.aplPlayer, {
          "Player Name": `${item.usrFullName}`,
          Name: `${item.usrFullName}`,
          aplPlayer: `${item.aplPlayer}`,
          teamId: `${item.teamId}`,
          aplAssessment: `${item.aplAssessment}`,
          "Email": `${item.usrEmail}`,
          PlayerEmail: `${item.usrEmail}`,
          "Phone No": `${item.usrPhoneNo}`,
          PhoneNo: `${item.usrPhoneNo}`,
          "DOB": new Date(item.usrDoB).toLocaleDateString(),
          "status": `${item.aplStatus}`,
        });
      }
    });

    // Convert the unique assessments map back to an array for your rowData
    this.rowData = Array.from(uniqueAssessments.values());
    this.playerName = this.rowData.filter((assessment: any) => assessment.aplPlayer === this.athleteId);
    this.playerList = this.rowData;

  }, error => {
    console.error('Error submitting assessment', error);
  });
}

onAthleteChange(event: any) {
  const selectedValue = event.target.value;
  const selectedPlayer = this.playerList.find(player => player.aplPlayer === selectedValue);
  if (selectedPlayer) {
    const athleteId = selectedPlayer.aplPlayer;   
    const assessmentId = selectedPlayer.aplAssessment; 
    this.athleteId =selectedPlayer.aplPlayer

    this.getProfileImage();
    this.getHydrationData(athleteId, assessmentId);
    this.formValues = {
      athleteId: '',
      nutrition: {
        postMeal: '',
        mealQuality: '',
        hydration: '',
        supplements: ''
      },
    };
    this.GetPlayer();
  }
}

  getHydrationData(athleteId: any, id: any): void {
    const atheleId = Number(athleteId);
    const asmId = Number(id);
    this.assessmentService.getHydrationandNutritionByAsm(athleteId, id).subscribe(response => {
      if (response.length > 0) {
        const hydrationData = response[0];
        console.log(response, "response data");
        this.hanId = hydrationData.hanId || '';
        this.formValues.nutrition.postMeal = hydrationData.answer1 || '';
        this.formValues.nutrition.mealQuality = hydrationData.answer2 || '';
        this.formValues.nutrition.hydration = hydrationData.answer3 || '';
        this.formValues.nutrition.supplements = hydrationData.answer4 || '';

        // Structure the questions and answers into JSON format
        const hydrationJson = {
          athleteId: athleteId,
          hanId: this.hanId,
          questions: [
            {
              "question": "Do you consume a meal or snack rich in protein and carbohydrates within 30-60 minutes after training or competition?",
              "answer": this.formValues.nutrition.postMeal || ''
            },
            {
              "question": "How would you rate the quality of your post-training/competition meals?",
              "answer": this.formValues.nutrition.mealQuality || ''
            },
            {
              "question": "How much water or sports drinks do you consume post-training?",
              "answer": this.formValues.nutrition.hydration || ''
            },
            {
              "question": "Do you use any supplements (e.g., protein, electrolytes) for recovery?",
              "answer": this.formValues.nutrition.supplements || ''
            }
          ]
        };

        console.log(hydrationJson);
        const jsonStr = JSON.stringify(hydrationJson, null, 2);
      }
    }, error => {
      console.error('Error fetching sleep data', error);
    });
  }
  getProfileImage() {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(this.athleteId).subscribe(
      (response) => {
        if (response) {
          this.showImage = true;
           this.image = "assets/image/profileimg.jpg"
          console.log(this.image ,"image")
          
        }
      },
      (error) => {
        this.assessmentService.getImage(this.athleteId).subscribe(
          (imageBlob) => {
        const objectURL = URL.createObjectURL(imageBlob);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.image = this.ImageUrl.changingThisBreaksApplicationSecurity
        console.log(  this.image,"this.image")
        this.showImage = true;
          }
      );
        console.error('Error fetching image', error);
      }
    );
  }
 
 

  // Method to check if all questions in the current section are answered
  isSectionComplete(section: number): boolean {
    switch (section) {
      case 1:
        return (
          this.formValues.nutrition.postMeal !== '' &&
          this.formValues.nutrition.mealQuality !== '' &&
          this.formValues.nutrition.hydration !== '' &&
          this.formValues.nutrition.supplements !== ''
        );

      default:
        return false;
    }
  }

  back():void{
    this.location.back();
  }

  submit(): void {
    const submissionData = [{
      hanId: this.hanId ? Number(this.hanId) : 0,
      hanAthleteId: Number(this.athleteId),
      hanAssessmentId: Number(this.id),
      hanAssessor: Number(this.assessor),
      hanQ1: this.formValues.nutrition.postMeal,
      hanQ2: this.formValues.nutrition.mealQuality,
      hanQ3: this.formValues.nutrition.hydration,
      hanQ4: this.formValues.nutrition.supplements,
      hanCreatedBy: Number(this.assessor),
    }];
    console.log("Submission Data:", submissionData);

    this.assessmentService.saveHydrationandNutrition(submissionData).subscribe(response => {
      this.updateAssessmentStatus();
      console.log("Response from API:", response);
      this.notify.success("Answers submitted successfully");
      }, error => {
      console.error('Error submitting assessment', error);
    });
  }

  updateAssessmentStatus(): void {
    this.assessmentService.updateAssessmentStatus(
      Number(this.athleteId),
      Number(this.id),
      Number(this.categoryId),
      Number(this.assessor)
    ).subscribe(response => {
      console.log("Assessment status updated:", response);
    }, error => {
      console.error('Error updating assessment status', error);
    });
  }

  isReadOnly(): boolean {
    return this.mode === 'view';
  }
}

