import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../services/Notifications.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AssessmentService } from '../../services/assessment.service';

@Component({
  selector: 'app-fileupload',
  standalone: true,
  imports: [FormsModule,CommonModule],
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  @Input() name: any;
  selectedFiles: any;
  selectedAssessment: any;
  assessments: any[] = []; // Declare an array to hold assessments

  constructor(private http: HttpClient, private notify: NotificationService, private router: Router,private assessmentService: AssessmentService) {}

  ngOnInit() {
    if (this.name === 'CSS Health & Fitness') {
      this.fetchAssessments(); // Fetch assessments on initialization
    }
  }
    
    fetchAssessments() {
      // Fetch assessments from your API or set static values
      this.assessmentService.getAllAssessment().subscribe(response => {
        console.log('Assessment submitted', response);
        this.assessments = response
      }, error => {
        console.error('Error submitting assessment', error);
      });
    }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
  }

  onUpload() {
    const formData = new FormData();
    if (this.name === "CSS Health & Fitness") {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('file', this.selectedFiles[i], this.selectedFiles[i].name);
      }
      // Use selectedAssessment in the URL
      console.log(`${environment.BaseUrl}${environment.uploadAssessment}${this.selectedAssessment}`)
      this.http.post(`${environment.BaseUrl}${environment.uploadAssessment}${this.selectedAssessment}`, formData).subscribe(
        (response: any) => this.notify.success('Files uploaded successfully!'),
        (error: any) => console.error('File upload failed!', error)
      );
    } else if (this.name !== "levelone") {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
      }
      this.http.post(environment.BaseUrl + environment.UploadFiles, formData).subscribe(
        (response: any) => this.notify.success('Files uploaded successfully!'),
        (error: any) => console.error('File upload failed!', error)
      );
    }else {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('file', this.selectedFiles[i], this.selectedFiles[i].name);
      }
      this.http.post(environment.BaseUrl + environment.LevelOnefileUpload, formData).subscribe(
        (response) => {
          console.log(response, "Res");
          if (response) {
            this.notify.success('Files uploaded successfully!');
            setTimeout(() => {
              this.router.navigate(['levelonelist']);
            }, 1000);
          }
        },
        (error: any) => console.error('File upload failed!', error)
      );
    }
  }

  downloadTemplate(fileName: string) {
    if (this.name === "CSS Health & Fitness") {
      const fileUrl = 'assets/Templates/assessmentForm_Template.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'assessmentForm_Template.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
    } else {
      const fileUrl = `assets/Templates/${fileName}` + '.xlsx';
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        saveAs(blob, 'levelone.xlsx');
      }, error => {
        console.error('Error downloading file', error);
      });
    }
  }
}
