<div class="text-center" *ngIf="this.spaPerformersReport.length === 0">
  !No Data
</div>
<div class="row" *ngIf="this.spaPerformersReport.length > 0">
    <div class="col-sm-8 flex-column d-flex stretch-card">
      <div class="row">
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <div class="card bg-blue">
            <div class="card-body text-white">
              <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h3>
              <div class="progress mb-3">
                <!-- Use Angular's property binding to set the width of the progress bar -->
                <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                  [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <p class="pb-0 mb-0">Overall Average</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <div class="card player-diffrence-border">
            <div class="card-body">
              <h2 class="text-dark mb-2 font-weight-bold"> {{ reportphysiologyCount }}</h2>
              <h4 class="card-title mb-2">Players Count</h4>

            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <!-- <div class="card justify-content-center player-diffrence-border">
            <div class="card-body ms-4">
              <img class="pro-img"
              src="{{spaPerformersReport[0]?.image}}">
            </div>
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 grid-margin d-flex stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="card-title mb-2">{{ sportTitle }}</h4>
                <div class="dropdown">
                  <!-- <a href="#" class="text-success btn btn-link Overall"><i class="mdi mdi-refresh"></i></a> -->
                  <a href="#" class="text-success btn btn-link Overall dropdown-toggle dropdown-arrow-none"
                     data-bs-toggle="dropdown" id="settingsDropdownsales">
                    <i class="mdi mdi-dots-horizontal"></i></a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="settingsDropdownsales">
                    <a class="dropdown-item" (click)="filterPerformers('All','Sport')">
                      <i class="mdi mdi-grease-pencil text-primary"></i>
                      All
                    </a>
                 <a class="dropdown-item" (click)="filterPerformers('Excellent','Sport')">
                   <i class="mdi mdi-grease-pencil text-primary"></i>
                   Excellent
                 </a>
                 <a class="dropdown-item" (click)="filterPerformers('Good','Sport')">
                   <i class="mdi mdi-delete text-primary"></i>
                   Good
                 </a>
                 <a class="dropdown-item" (click)="filterPerformers('Average','Sport')">
                   <i class="mdi mdi-grease-pencil text-primary"></i>
                   Average
                 </a>
                 <a class="dropdown-item" (click)="filterPerformers('Poor','Sport')">
                   <i class="mdi mdi-delete text-primary"></i>
                   Poor
                 </a>
               </div>
                </div>
              </div>
              <div id="new-chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 flex-column d-flex stretch-card">
      <div class="row flex-grow">
        <div class="col-sm-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div>
                <h4 class="card-title fw-semibold">Top Performer</h4>
                <h4 class="font-weight-bold text-name">Name : {{ spaPerformers[0]?.usrFullName }}</h4>
                <h4 class="font-weight-bold text-id">ID :
                  {{ spaPerformers[0]?.spaAthlete }}
                </h4>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-4 mt-lg-0">
                  <div class="bg-blue text-white px-4 py-4 card">
                    <div class="row">
                      <div class="col-sm-6 pl-lg-5">
                        <h2>{{ spaPerformers[0]?.zScoreSpaForce || 0 }}%</h2>
                        <p class="mb-0 text-small">Force (N)</p>
                      </div>
                      <div class="col-sm-6 climate-info-border mt-lg-0 mt-2">
                        <h2>{{ spaPerformers[0]?.zScoreSpaPower || 0 }}%</h2>
                        <p class="mb-0 text-small">Power (W)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3 mt-md-1">
                <div class="col-6">
                  <div class=" purchase-detail-legend d-flex flex-column align-items-center justify-content-center">
                    <div class="circular-progress me-2"
                      style="--value: {{ spaPerformers[0]?.zScoreSpaForce || 0 }}; --color: #ff5521;">
                      <svg width="60" height="60" class="progress-circle">
                        <circle class="background" cx="30" cy="30" r="27" stroke="#e6e6e6" stroke-width="6"
                          fill="none"></circle>
                        <circle class="circle" cx="30" cy="30" r="27" stroke="#ff5521" stroke-width="6" fill="none"
                          [attr.stroke-dasharray]="169.65"
                          [attr.stroke-dashoffset]="169.65 - (spaPerformers[0]?.spaAverageTorqueIR / 100 * 169.65)"
                          stroke-linecap="round">
                        </circle>
                      </svg>
                    </div>
                    <div>
                      <p class="font-weight-medium text-dark text-small">Force (N)</p>
                      <h3 class="font-weight-bold text-dark mb-0">{{ spaPerformers[0]?.zScoreSpaForce || 0 }}%
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class=" purchase-detail-legend d-flex flex-column align-items-center justify-content-center">
                    
                    <div class="circular-progress me-2"
                      style="--value: {{spaPerformers[0]?.zScoreSpaPower || 0 }}; --color: #ff5521;">
                      <svg width="60" height="60" class="progress-circle">
                        <circle class="background" cx="30" cy="30" r="27" stroke="#e6e6e6" stroke-width="6"
                          fill="none"></circle>
                        <circle class="circle" cx="30" cy="30" r="27" stroke="#ff5521" stroke-width="6" fill="none"
                          [attr.stroke-dasharray]="169.65"
                          [attr.stroke-dashoffset]="169.65 - (spaPerformers[0]?.zScoreSpaPower / 100 * 169.65)"
                          stroke-linecap="round">
                        </circle>
                      </svg>
                    </div>
                    <div>
                      <p class="font-weight-medium text-dark text-small">Power (W)</p>
                      <h3 class="font-weight-bold text-dark mb-0">{{spaPerformers[0]?.zScoreSpaPower || 0}}%
                      </h3>                      
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <h4 class="card-title fw-semibold" >International Benchmark (IBM)</h4>
      <div class="card">
        <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
            <thead>
              <tr>
                <th>Test</th>
                <th>IBM Male</th>
                <th>IBM Female</th>
                <th>Team Average Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Concentric Right Average Torque</td>
                <td>150-200</td>
                <td> 130-180 </td>
                <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Concentric Right Peak Torque</td>
                <td>180-240</td>
                <td> 160-220 </td>
                <td>{{ this.averagePeakgTorqueCR | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Concentric Left Average Torque</td>
                <td>150-200</td>
                <td> 130-180 </td>
                <td>{{ this.averagePeakTorqueCL | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Concentric Left Peak Torque</td>
                <td>180-240</td>
                <td> 160-220 </td>
                <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Isokinetic Left Average Torque</td>
                <td>180-240</td>
                <td> 160-220 </td>
                <td>{{ this.averageAvgTorqueIL | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Isokinetic Left Peak Torque</td>
                <td>250-300</td>
                <td> 240-300 </td>
                <td>{{ this.averagePeakTorqueIL | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Isokinetic Right Average Torque</td>
                <td>180-240</td>
                <td> 160-220 </td>
                <td>{{ this.averageAvgTorqueIR | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td>Isokinetic Right Peak Torque</td>
                <td>250-300</td>
                <td>  240-300 </td>
                <td>{{ this.averagePeakTorqueIR | number: '1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
    <div class="row row-cards row-deck">
      <div class="d-sm-flex justify-content-between align-items-center mb-2">
        <h4 class="card-title fw-semibold">Sports {{header}} Performers</h4>
        <div>
          <div class="select-wrapper">
            <i class="bx bx-cog select-icon"></i>
            <select [(ngModel)]="selectedStatus" (change)="onPhysiologyStatusChange()" class="form-select">
              <option selected disabled>Select Option</option>
              <option value="0">All</option>
              <option value="1">Excellent</option>
              <option value="2">Good</option>
              <option value="3">Average</option>
              <option value="4">Poor</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Top Performers Section -->
      <div class="col-12">
        <div class="card">
          <h4 class="m-3" *ngIf="chat == undefined">Sports {{header}} Performers</h4>
          <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th>Profile</th>
                  <th>Athletes</th>
                  <th>Average TorqueCR</th>
                  <th>Average TorqueCL</th>
                  <th>Peak TorqueCR</th>
                  <th>Peak TorqueCL</th>
                  <th>Average TorqueIR</th>
                  <th>Average TorqueIL</th>
                  <th>Peak TorqueIR</th>
                  <th>Peak TorqueIL</th>
                  <th>Z Score</th>
                </tr>
              </thead>
              <tbody *ngIf="spaPerformers && spaPerformers.length > 0; else noData">
                <tr *ngFor="let athlete of spaPerformers">
                    <td class="text-center">
                      <img class="avatar"
                      src="{{athlete.image}}">
                      </td>
                      <td>
                        <div>{{ athlete.usrFullName }}</div>
                        <div class="small text-muted">ID: {{ athlete.spaAthlete }}</div>
                      </td>
                  <td>{{ athlete.spaAverageTorqueCR }}</td>
                  <td>{{ athlete.spaAverageTorqueCL }}</td>
                  <td>{{ athlete.spaPeakTorqueCR }}</td>
                  <td>{{ athlete.spaPeakTorqueCL }}</td>
                  <td>{{ athlete.spaAverageTorqueIR }}</td>
                  <td>{{ athlete.spaAverageTorqueIL }}</td>
                  <td>{{ athlete.spaPeakTorqueIR }}</td>
                  <td>{{ athlete.spaPeakTorqueIL }}</td>
                  <td>{{ athlete.overallZScore }}%</td>
                  
                </tr>
              </tbody>
               <!-- Template for when there is no data -->
               <ng-template #noData>
                <tr>
                  <td colspan="8" class="text-center">Data not available</td>
                </tr>
              </ng-template>
            </table>
           
          </div>
        </div>
      </div>
    </div>
    <!--IsoKinetics-->    
   
 <!--Concentric-->    
 <div class="row">
  <div class="col-sm-12 grid-margin d-flex stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="card-title mb-2">{{ concentricTitle }}</h4>
          <div class="dropdown">
            <!-- <a href="#" class="text-success btn btn-link Overall"><i class="mdi mdi-refresh"></i></a> -->
            <a href="#" class="text-success btn btn-link Overall dropdown-toggle dropdown-arrow-none"
               data-bs-toggle="dropdown" id="settingsDropdownsales">
              <i class="mdi mdi-dots-horizontal"></i></a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="settingsDropdownsales">
              <a class="dropdown-item" (click)="filterPerformers('All','Concentric')">
                <i class="mdi mdi-grease-pencil text-primary"></i>
                All
              </a>
           <a class="dropdown-item" (click)="filterPerformers('Excellent','Concentric')">
             <i class="mdi mdi-grease-pencil text-primary"></i>
             Excellent
           </a>
           <a class="dropdown-item" (click)="filterPerformers('Good','Concentric')">
             <i class="mdi mdi-delete text-primary"></i>
             Good
           </a>
           <a class="dropdown-item" (click)="filterPerformers('Average','Concentric')">
             <i class="mdi mdi-grease-pencil text-primary"></i>
             Average
           </a>
           <a class="dropdown-item" (click)="filterPerformers('Poor','Concentric')">
             <i class="mdi mdi-delete text-primary"></i>
             Poor
           </a>
         </div>
          </div>
        </div>
        <div id="concentric-chart"></div>
      </div>
    </div>
  </div>
</div>
<!-- Top Performers Section -->

<div class="row row-cards row-deck">
  <div class="col-12">
    <h4 class="card-title fw-semibold" >International Benchmark (IBM)</h4>
    <div class="card">
      <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
      <div class="table-responsive">
        <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
          <thead>
            <tr>
              <th>Test</th>
              <th>IBM Male</th>
              <th>IBM Female</th>
              <th>Team Average Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Concentric Right Average Torque</td>
              <td>150-200</td>
              <td> 130-180 </td>
              <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
            </tr>
            <tr>
              <td>Concentric Right Peak Torque</td>
              <td>180-240</td>
              <td> 160-220 </td>
              <td>{{ this.averagePeakgTorqueCR | number: '1.2-2' }}</td>
            </tr>
            <tr>
              <td>Concentric Left Average Torque</td>
              <td>150-200</td>
              <td> 130-180 </td>
              <td>{{ this.averagePeakTorqueCL | number: '1.2-2' }}</td>
            </tr>
            <tr>
              <td>Concentric Left Peak Torque</td>
              <td>180-240</td>
              <td> 160-220 </td>
              <td>{{ this.averageAvgTorqueCR | number: '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <div class="d-sm-flex justify-content-between align-items-center mb-2">
      <h4 class="card-title fw-semibold" >Concentric {{conheader}} Performers</h4>
      <div>
        <div class="select-wrapper">
          
          <select [(ngModel)]="selectedConcentricStatus" (change)="loadConcentricTopPerformersData()" class="form-select">
            <option selected disabled>Select Option</option>
            <option value="0">All</option>
              <option value="1">Excellent</option>
              <option value="2">Good</option>
              <option value="3">Average</option>
              <option value="4">Poor</option>
          </select>
        </div>
      </div>
    </div>
<div class="col-12">
<div class="card">
  <h4 class="m-3" *ngIf="chat == undefined">{{conheader}} Performers</h4>
  <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
  <div class="table-responsive">
    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
      <thead>
        <tr>
          <th>Profile</th>
          <th>Athletes</th>
          <th>Average TorqueCR</th>
          <th>Average TorqueCL</th>
          <th>Peak TorqueCR</th>
          <th>Peak TorqueCL</th>
          <th>Average TorqueIR</th>
          <th>Average TorqueIL</th>
          <th>Peak TorqueIR</th>
          <th>Peak TorqueIL</th>
          <th>Z Score</th>
        </tr>
      </thead>
      <tbody *ngIf="ConcentricPerformers && ConcentricPerformers.length > 0; else noData">
        <tr *ngFor="let athlete of ConcentricPerformers">
            <td class="text-center">
              <img class="avatar"
              src="{{athlete.image}}">
              </td>
              <td>
                <div>{{ athlete.usrFullName }}</div>
                <div class="small text-muted">ID: {{ athlete.spaAthlete }}</div>
              </td>
          <td>{{ athlete.spaAverageTorqueCR }}</td>
          <td>{{ athlete.spaAverageTorqueCL }}</td>
          <td>{{ athlete.spaPeakTorqueCR }}</td>
          <td>{{ athlete.spaPeakTorqueCL }}</td>
          <td>{{ athlete.spaAverageTorqueIR }}</td>
          <td>{{ athlete.spaAverageTorqueIL }}</td>
          <td>{{ athlete.spaPeakTorqueIR }}</td>
          <td>{{ athlete.spaPeakTorqueIL }}</td>
          <td>{{ athlete.overallZScore }}%</td>
          
        </tr>
      </tbody>
       <!-- Template for when there is no data -->
       <ng-template #noData>
        <tr>
          <td colspan="8" class="text-center">Data not available</td>
        </tr>
      </ng-template>
    </table>
   
  </div>
</div>

<div class="col-sm-12 grid-margin d-flex stretch-card">
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="card-title mb-2">{{ isokineticTitle }}</h4>
        <div class="dropdown">
          <!-- <a href="#" class="text-success btn btn-link Overall"><i class="mdi mdi-refresh"></i></a> -->
          <a href="#" class="text-success btn btn-link Overall dropdown-toggle dropdown-arrow-none"
             data-bs-toggle="dropdown" id="settingsDropdownsales">
            <i class="mdi mdi-dots-horizontal"></i></a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
            aria-labelledby="settingsDropdownsales">
            <a class="dropdown-item" (click)="filterPerformers('All','Isokinetic')">
              <i class="mdi mdi-grease-pencil text-primary"></i>
              All 
            </a>
         <a class="dropdown-item" (click)="filterPerformers('Excellent','Isokinetic')">
           <i class="mdi mdi-grease-pencil text-primary"></i>
           Excellent
         </a>
         <a class="dropdown-item" (click)="filterPerformers('Good','Isokinetic')">
           <i class="mdi mdi-delete text-primary"></i>
           Good
         </a>
         <a class="dropdown-item" (click)="filterPerformers('Average','Isokinetic')">
           <i class="mdi mdi-grease-pencil text-primary"></i>
           Average
         </a>
         <a class="dropdown-item" (click)="filterPerformers('Poor','Isokinetic')">
           <i class="mdi mdi-delete text-primary"></i>
           Poor
         </a>
       </div>
        </div>
      </div>
      <div id="isokinetic-chart"></div>
    </div>
  </div>
</div>
<!-- Top Performers Section -->
<div class="row row-cards row-deck">
  
<div class="col-12">
  <h4 class="card-title fw-semibold" >International Benchmark (IBM)</h4>
  <div class="card">
    <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
    <div class="table-responsive">
      <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
        <thead>
          <tr>
            <th>Test</th>
            <th>IBM Male</th>
            <th>IBM Female</th>
            <th>Team Average Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Isokinetic Right Average Torque</td>
            <td>180-240</td>
            <td> 160-220 </td>
            <td>{{ this.averageAvgTorqueIR | number: '1.2-2' }}</td>
          </tr>
          <tr>
            <td>Isokinetic Right Peak Torque</td>
            <td>250-300</td>
                <td> 240-300 </td>
            <td>{{ this.averagePeakTorqueIR | number: '1.2-2' }}</td>
          </tr>
          <tr>
            <td>Isokinetic Left Average Torque</td>
            <td>180-240</td>
            <td> 160-220 </td>
            <td>{{ this.averageAvgTorqueIL | number: '1.2-2' }}</td>
          </tr>
          <tr>
            <td>Isokinetic Left Peak Torque</td>
            <td>250-300</td>
                <td> 240-300 </td>
            <td>{{ this.averagePeakTorqueIL | number: '1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="d-sm-flex justify-content-between align-items-center mb-2">
  <h4 class="card-title fw-semibold" > Isokinetic {{isoheader}} Performers</h4>
  <div>
    <div class="select-wrapper">
      
      <select [(ngModel)]="selectedIsoKineticStatus" (change)="loadIsokineticTopPerformersData()" class="form-select">
        <option selected disabled>Select Option</option>
        <option value="0">All</option>
        <option value="1">Excellent</option>
        <option value="2">Good</option>
        <option value="3">Average</option>
        <option value="4">Poor</option>
      </select>
    </div>
  </div>
</div> 
<div class="col-12">
<div class="card">
  <h4 class="m-3" *ngIf="chat == undefined">{{isoheader}} Performers</h4>
  <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
  <div class="table-responsive">
    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
      <thead>
        <tr>
          <th>Profile</th>
          <th>Athletes</th>
          <th>Average TorqueCR</th>
          <th>Average TorqueCL</th>
          <th>Peak TorqueCR</th>
          <th>Peak TorqueCL</th>
          <th>Average TorqueIR</th>
          <th>Average TorqueIL</th>
          <th>Peak TorqueIR</th>
          <th>Peak TorqueIL</th>
          <th>Z Score</th>
        </tr>
      </thead>
      <tbody *ngIf="IsokineticPerformers && IsokineticPerformers.length > 0; else noData">
        <tr *ngFor="let athlete of IsokineticPerformers">
            <td class="text-center">
              <img class="avatar"
              src="{{athlete.image}}">
              </td>
              <td>
                <div>{{ athlete.usrFullName }}</div>
                <div class="small text-muted">ID: {{ athlete.spaAthlete }}</div>
              </td>
          <td>{{ athlete.spaAverageTorqueCR }}</td>
          <td>{{ athlete.spaAverageTorqueCL }}</td>
          <td>{{ athlete.spaPeakTorqueCR }}</td>
          <td>{{ athlete.spaPeakTorqueCL }}</td>
          <td>{{ athlete.spaAverageTorqueIR }}</td>
          <td>{{ athlete.spaAverageTorqueIL }}</td>
          <td>{{ athlete.spaPeakTorqueIR }}</td>
          <td>{{ athlete.spaPeakTorqueIL }}</td>
          <td>{{ athlete.overallZScore }}%</td>
          
        </tr>
      </tbody>
       <!-- Template for when there is no data -->
       <ng-template #noData>
        <tr>
          <td colspan="8" class="text-center">Data not available</td>
        </tr>
      </ng-template>
    </table>
   
  </div>
</div>
</div>