import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgToastModule } from 'ng-angular-popup';
import { environment } from '../../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../../services/shared.service';
 
@Component({
  selector: 'app-single-imageupload',
  standalone: true,
  imports: [CommonModule,RouterOutlet,NgToastModule,RouterLink],
  templateUrl: './single-imageupload.component.html',
  styleUrl: './single-imageupload.component.css'
})
export class SingleImageuploadComponent {
 
  selectedFiles: File[] = [];
  detail: any;
  UserId: any;
  constructor(private http:HttpClient,private sharedService: SharedService,private router:Router) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
    });
 
  }
 
 
  onFileSelected(event: any) {
    this.selectedFiles = Array.from(event.target.files) as File[];
  }
 
  onSubmit() {
    const formData = new FormData();
    this.selectedFiles.forEach((file) => {
      var name = this.UserId + '.jpg' || ' .png' || ' .jpeg';
      formData.append('files', file, name);
    });
 
    this.http.post(environment.BaseUrl + environment.uploadImageUrl, formData, { responseType: 'text' })
      .subscribe((response) => {
        console.log('Upload successful', response);
          location.reload();
      }, (error) => {
        console.error('Upload failed', error);
      });
  }
 
 
 
}