<div class="modal fade" id="fileUploadModal" tabindex="-1" aria-labelledby="fileUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
    <div class="modal-content" style="max-height: 250px;">
      <div class="modal-header">
        <h5 class="modal-title" id="fileUploadModalLabel">{{ name }} Files Upload</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-2" *ngIf="name === 'CSS Health & Fitness'">
          <select [(ngModel)]="selectedAssessment" class="form-select mt-2">
            <option value="" disabled>Select Assessment</option> <!-- Placeholder option -->
            <option *ngFor="let assessment of assessments" [value]="assessment.asmId">{{ assessment.asmName }}</option>
          </select>
        </div>        
        <input type="file" multiple (change)="onFileSelected($event)" />
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="downloadTemplate(name)">Download Template</button>
        <button type="submit" class="btn btn-primary" (click)="onUpload()"
                [disabled]="!selectedFiles || selectedFiles.length === 0">
          <span class="btnText">Upload</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
    </div>
  </div>
</div>