<div class="body-wrapper">
    <div class="team-single">
      <div class="d-flex justify-content-between mb-3 gap-4">
        <div>
          <button class="btn btn-primary" (click)="onBack()">Back</button>
      </div>
      <div>
        <button type="button" class="accept"(click)="downloadPDF()">Download</button>  
      </div>
      </div>
      <div class="contact-info-section margin-40px-tb" *ngFor="let athlete of athleteReport">
        <div id="pdf-content">
          <div class="row">
            <div class="col-lg-4 col-md-5 xs-margin-30px-bottom">
                <div class="col-lg-12">
                    <div class="card mb-4">
                      <div class="content-header">
                        <h2 class="sub-title">Sports Performance Analysis</h2>
                      </div>
                      <div class="content-body">
                        <!-- Sports Activity -->
                        <div class="mb-4" *ngFor="let activity of activities">
                          <div class="clearfix">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="small-text">{{ activity.name }}</p>
                              <strong>{{ activity.percentage }}%</strong>
                            </div>
                          </div>
                          <div class="progress progress-xs">
                            <div class="progress-bar"
                                 [ngClass]="getProgressColor(activity.percentage)"
                                 role="progressbar"
                                 [style.width]="activity.percentage + '%'"
                                 [attr.aria-valuenow]="activity.percentage"
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
              <div class="col-lg-12">
                <div class="bg-light-blue">
                  <h4 class="side-Title">{{athlete.usrFullName}}</h4>
                  <p class="side-content">Athelete</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 card col-md-7">
              <div class="card-body">
                <div class="team-single-text padding-50px-left sm-no-padding-left">
                  <div class="mobile-res d-flex justify-content-between mb-4">
                    <div>
                      <h4 class="Title">Athlete Details</h4>
                    </div>
                    <div>
                      <h4 class="sub-title">{{ athlete.spaDate | date: 'dd-MM-yyyy' }}</h4>
                      <p>Assessment Date</p>
                    </div>
                  </div>
                  <div class="contact-info-section margin-40px-tb">
                    <ul class="list-style9 no-margin">
                      <li *ngIf="athlete.ipaAthlete">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Athlete ID:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaAthlete}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete. spaAverageTorqueCR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left"> Average TorqueCR:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete. spaAverageTorqueCR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaAverageTorqueCL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left"> Average TorqueCL:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaAverageTorqueCL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaPeakTorqueCR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueCR:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaPeakTorqueCR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaPeakTorqueCL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueCL:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaPeakTorqueCL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaAverageTorqueIR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">AverageTorqueIR:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaAverageTorqueIR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaAverageTorqueIL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Average TorqueIL:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaAverageTorqueIL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaPeakTorqueIR">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueIR:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaPeakTorqueIR}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaPeakTorqueIL">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Peak TorqueIL:</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaPeakTorqueIL}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaJumpHeight">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Jump Height (cm):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaJumpHeight}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaFlightTime">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Flight Time  (ms):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaFlightTime}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaVelocity">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Velocity (m/s):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaVelocity}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaForce">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Force (N):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaForce}}</p>
                          </div>
                        </div>
                      </li>
                      <li *ngIf="athlete.spaPower">
                        <div class="row">
                          <div class="col-md-5 col-5">
                            <strong class="margin-10px-left">Power (W):</strong>
                          </div>
                          <div class="col-md-7 col-7">
                            <p>{{athlete.spaPower}}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end of pdf-content -->
      </div> <!-- end of contact-info-section -->
    </div> <!-- end of team-single -->
  </div>