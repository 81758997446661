import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Input, EventEmitter, Output, OnChanges, SimpleChanges,ChangeDetectorRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ChatService } from '../../services/chat.service';
import { PlayerDashboardComponent } from '../../player-dashboard/player-dashboard.component';
@Component({
  selector: 'app-sport-performance',
  standalone: true,
  imports: [CommonModule, PlayerDashboardComponent, HttpClientModule, FormsModule],
  templateUrl: './sport-performance.component.html',
  styleUrl: './sport-performance.component.css'
})
export class SportPerformanceComponent implements OnChanges{
  @Input() orgId: string | undefined;
  @Input() userMessage: string = '';
  @Input() selectedTeamId: any = null;
  @Output() chatData: EventEmitter<string> = new EventEmitter<string>(); 
  user: any;
  analysisTitle: string = 'Sports Performance Analysis'; // Default title
  apiResponse: any;
  topPerformers: any;
  athletedata: any;
  leastPerformers: any;
  selectedImageUrl: any;
  selectedStatus: number = 0;
  selectedGender: string = '';
  List: any;
  isLoading = false;
  c3: any;
  Response: any;
  chat: any;
  Performers: any;
  header: any;
  concentricTitle: any;
  sportTitle: any;
  isokineticTitle: any;
  privilege: any;
  Isplayer: boolean = false;
  Admin: boolean = false;
  OrgAdmin: boolean = false;
  selectedOption: string = 'Level One'; // Default value
  public imageUrls: any[] = [];
  detail: any;
  UserId: any;
  org: any;
  ID: any;
  role: any;
  spaPerformersReport: any[] = [];
  reportCount: number = 0;  // Variable to store the count
  TopPerformer: any = null;  // Add this line
  averageOverallPercentage: any = null;  // Add this line
  nutrionReport: any[] = [];
  nutritiontopPerformer: any = null;
  averageNutritionOverallPercentage: any = null;
  reportNutritionCount: number = 0;
  fitnessTopPerformer: any[] = [];
  nutritionTop5Performer: any[] = [];
  SportsPerformers: any[] = [];
  ConcentricPerformers: any[] = [];
  IsokineticPerformers: any[] = [];
   Cardio: boolean = false;
  reportphysiologyCount: number = 0;
  physiologytopPerformer: any = null;
  averagePhysiologyOverallPercentage: any = null;
  physiologyTop5Performer: any[] = [];
  spaPerformers: any[] = [];
  admitcardReport: any;
  msg: any;
  nutResponse: any;
  coaResponse: any;
  nutPerformers: any;
  coaPerformers: any;
  pfaResponse: any;
  plaResponse: any;
  plaPerformers: any;
  VideoAnalysis: boolean = false;
  PhysicalFitness: boolean = false;
  Nutrition: boolean = false;
  averageOverallZScore: number = 0;
  Cognitive: boolean = false;
  CognitivePerformers: any[] = [];
  cognitiveResponse: any[] = [];
  Orgainsation: any;
  selectedOrg: string = ''; 
  trainerId: any;
  selectedTrainer: string = '';
  Coach: boolean = false;
  Trainer: any;
  fitnessOverallPercentage: any;
  cognitiveTop5Performers: any[] = [];
  teams:any;
  selectedConcentricStatus: number =0;
  selectedIsoKineticStatus: number =0;
  gender: number = 1;
  sport: number = 11;
  IBMReference: any;
  averageSpaJumpHeight: any;
  averageSpaFlightTime: any;
  averageSpaVelocity: any;
  averageSpaPower: any;
  averageSpaForce: any;
  averageAvgTorqueCR: any;
  averageAvgTorqueCL: any;
  averagePeakgTorqueCR: any;
  averagePeakTorqueCL: any;
  averageAvgTorqueIR: any;
  averageAvgTorqueIL: any;
  averagePeakTorqueIR: any;
  averagePeakTorqueIL: any;
  isoheader: any;
  conheader: any;
  ImageUrl: any;
  showImage: boolean=false;
  image: any;
  constructor(
    private router: Router,
    private assessmentService: AssessmentService,
    private priService: PrivilegeService,
    private userService: UserService,
    private sharedService: SharedService, 
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService, @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.UserId = this.detail.user.usrId;
      this.org = this.detail.user.usrOrganization;
    });
 }
  onStatusChange() {
    this.loadTopPerformersData();
  }
  onPhysiologyStatusChange() {
    this.loadSportsTopPerformersData();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTeamId'] && !changes['selectedTeamId'].isFirstChange()) {
      // Respond to the team change
      this.onTeamSelected(this.selectedTeamId);
    }
  }
  async ngOnInit(): Promise<void> {

    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege?.isPlayer ?? false;
    this.Admin = this.privilege?.isAdmin ?? false;
    this.OrgAdmin = this.privilege?.isOrgAdmin ?? false;
    this.VideoAnalysis = this.privilege?.isVideoAnalysis ?? false;
    this.Nutrition = this.privilege?.isNutrition ?? false;
    this.PhysicalFitness = this.privilege?.isPhysicalFitness ?? false;
    this.Cognitive = this.privilege?.isCognitive ?? false;
    this.Coach = this.privilege?.isCoach ?? false;
    this.user = this.userService.getUser();

    this.assessmentService.getOrgainsation().subscribe(
      (data) => {
        this.Orgainsation = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );

    this.assessmentService.getTrainer().subscribe(
      (data) => {
        this.Trainer = data;
      },
      (error) => {
        console.error('Error fetching coaches:', error);
      }
    );
    this.isLoading = true;
    this.chat = '';
    this.athletedata = undefined;
    this.selectedStatus = 0;
    this.isLoading = false;
    await this.getSportsPerformaceAnalysis();
    this.loadSportsTopPerformersData();
    this.loadIsokineticTopPerformersData();
    this.loadConcentricTopPerformersData();
    this.isLoading = false;
    this.assessmentService.getTeams().subscribe((data: any[]) => {
      this.teams = data;
    });

    this.assessmentService.getInterNationalBenchMark(this.gender, this.sport).subscribe(response => {
      this['IBMReference'] = response;
  }, error => {
      console.error("Error fetching IBMReference:", error);
  });
  }

  orgStudents(){
    if (this.Admin || this.OrgAdmin) {
      this.assessmentService.getAdmitCard(this.org).subscribe((response: any) => {
        this.admitcardReport = response;
      });
    }
  }

  sendMessage() {
      this.apiResponse = this.plaResponse;
      this.msg = " give me result with their plaAthlete,athleteFullName and result in both text and json format";


    const assessmentDataJson = JSON.stringify(this.apiResponse);
    const message = "in this data give me " + this.userMessage + this.msg;
    const combinedMessage = `
      {
        "data": ${assessmentDataJson},
        "message": "${message}"
      }
    `;
    this.isLoading = true;
    this.chatService.getChatResponse(combinedMessage).subscribe(async chatResponse => {
      const chatContent = chatResponse?.choices?.[0]?.message?.content;
      if (chatContent) {
        try {
          // Extract JSON array or object from the chat response
          const jsonMatch = chatContent.match(/\[.*?\]/s) || chatContent.match(/\{.*?\}/s);
          if (jsonMatch) {
            const jsonString = jsonMatch[0].trim();
            this.athletedata = JSON.parse(jsonString);
            const textContent = chatContent.replace(jsonString, '').trim();
            this.chat = textContent;
          } else {
            this.chat = chatContent;
            console.error('No JSON block found in the chat content.');
          }
        } catch (error) {
          this.chat = chatContent;
          console.error('Error parsing chat response JSON:', error);
        }
      } else {
        console.error('Unexpected chat response structure:', chatResponse);
      }

      this.isLoading = false;
      this.chatData.emit(this.chat);

      // Update each performer with matching assessment data and image URL
      this.athletedata.forEach((performer: any) => {
        if (this.selectedOption == 'Physical Fitness') {
          const matchingAssessment = this.apiResponse.find((item: any) => item.pfaId === performer.pfaId)
          if (matchingAssessment) {
            Object.assign(performer, matchingAssessment);
            this.getProfileImage(performer.spaAthlete)
            this.getSportsPerformaceAnalysis();
            this.loadTopPerformersData();
          } else {
            console.error(`No matching assessment found`);
          }
        }
      });
    }, error => {
      console.error('Error from Chat Service:', error);
      this.isLoading = false;
    });

  }

  getFilteredChat(): string {
    const jsonIndex = this.chat.indexOf("In JSON format:");
    if (jsonIndex !== -1) {
      return this.chat.substring(0, jsonIndex);  
    }
    return this.chat;  
  }

  loadTopPerformersData() {
    if (this.athletedata === undefined) {
      this.Performers = this.Response;
      if (this.selectedStatus == 0) {
        this.Performers = this.Performers.sort((a: any, b: any) => b.loaAverageRange - a.loaAverageRange)
          .slice(0, 5);
        this.header = "Top";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      } else {
        this.Performers = this.Performers
          .sort((a: any, b: any) => a.loaAverageRange - b.loaAverageRange)
          .slice(0, 5);
        this.header = "Under";
        this.Performers.forEach((performer: any) => {
          this.getProfileImage(performer.loaAthlete)
        });
      }
    } else {
      this.Performers = this.athletedata;
      this.Performers.forEach((performer: any) => {
        this.getProfileImage(performer.loaAthlete)
      });
    }


  }

  loadSportsTopPerformersData() {
    
 this.spaPerformers = this.spaPerformersReport
 .sort((a: any, b: any) => b.overallZScore - a.overallZScore);

this.header = "";

if (this.selectedStatus == 0) {
} else {
 if (this.selectedStatus == 1) {
   this.spaPerformers = this.spaPerformers.filter((performer: any) => performer.overallZScore > 85)
     .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
   this.header = "Excellent";
 } else if (this.selectedStatus == 2) {
   this.spaPerformers = this.spaPerformers.filter((performer: any) => performer.overallZScore > 70 && performer.overallZScore <= 85)
     .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
   this.header = "Good";
 } else if (this.selectedStatus == 3) {
   this.spaPerformers = this.spaPerformers.filter((performer: any) => performer.overallZScore > 40 && performer.overallZScore <= 70)
     .sort((a: any, b: any) => b.overallZScore - a.overallZScore);
   this.header = "Average";
 } else {
   this.spaPerformers = this.spaPerformers.filter((performer: any) => performer.overallZScore <= 40)
     .sort((a: any, b: any) => a.overallZScore - b.overallZScore);
   this.header = "Poor";
 }

 this.spaPerformers = this.spaPerformers.slice(0, 5);

}
    // Fetch profile images for the selected performers
    this.spaPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.spaAthlete)
    });
  }

  loadIsokineticTopPerformersData() {

    
 this.IsokineticPerformers = this.spaPerformersReport
 .sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore);

this.isoheader = "";

if (this.selectedIsoKineticStatus == 0) {
} else {
 if (this.selectedIsoKineticStatus == 1) {
   this.IsokineticPerformers = this.IsokineticPerformers.filter((performer: any) => performer.overallIsokineticScore > 85)
     .sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore);
   this.isoheader = "Excellent";
 } else if (this.selectedIsoKineticStatus == 2) {
   this.IsokineticPerformers = this.IsokineticPerformers.filter((performer: any) => performer.overallIsokineticScore > 70 && performer.overallIsokineticScore <= 85)
     .sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore);
   this.isoheader = "Good";
 } else if (this.selectedIsoKineticStatus == 3) {
   this.IsokineticPerformers = this.IsokineticPerformers.filter((performer: any) => performer.overallIsokineticScore > 40 && performer.overallIsokineticScore <= 70)
     .sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore);
   this.isoheader = "Average";
 } else {
   this.IsokineticPerformers = this.IsokineticPerformers.filter((performer: any) => performer.overallIsokineticScore <= 40)
     .sort((a: any, b: any) => a.overallIsokineticScore - b.overallIsokineticScore);
   this.isoheader = "Poor";
 }
 this.IsokineticPerformers = this.IsokineticPerformers.slice(0, 5);
}
    this.IsokineticPerformers.forEach((performer: any) => {
      this.getProfileImage(performer.spaAthlete)
    }); 
    
    

  }
      loadConcentricTopPerformersData() {

    this.ConcentricPerformers = this.spaPerformersReport
    .sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore);

    this.conheader = "";

    if (this.selectedConcentricStatus == 0) {
    } else {
    if (this.selectedConcentricStatus == 1) {
      this.ConcentricPerformers = this.ConcentricPerformers.filter((performer: any) => performer.overallConcentricZScore > 85)
        .sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore);
      this.conheader = "Excellent";
    } else if (this.selectedConcentricStatus == 2) {
      this.ConcentricPerformers = this.ConcentricPerformers.filter((performer: any) => performer.overallConcentricZScore > 70 && performer.overallConcentricZScore <= 85)
        .sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore);
      this.conheader = "Good";
    } else if (this.selectedConcentricStatus == 3) {
      this.ConcentricPerformers = this.ConcentricPerformers.filter((performer: any) => performer.overallConcentricZScore > 40 && performer.overallConcentricZScore <= 70)
        .sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore);
      this.conheader = "Average";
    } else {
      this.ConcentricPerformers = this.ConcentricPerformers.filter((performer: any) => performer.overallConcentricZScore <= 40)
        .sort((a: any, b: any) => a.overallConcentricZScore - b.overallConcentricZScore);
      this.conheader = "Poor";
    }

    this.ConcentricPerformers = this.ConcentricPerformers.slice(0, 5);
    }

this.ConcentricPerformers.forEach((performer: any) => {
 this.getProfileImage(performer.spaAthlete)
});  
  }

  formnavigation() {
    this.router.navigate(['/nutrition']);
  }

  getProfileImage(userId: any) {
    this.ImageUrl = null;
    this.showImage = false;
 
    this.assessmentService.getEmptyImage(userId).subscribe(
      (response: any) => {
        if (response) {
          this.showImage = true;
          this.image = "assets/image/profileimg.jpg";
 
          this.updateAllPerformers(userId, this.image);
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        console.error('Error fetching empty image:', error);
        this.showImage = true;
 
        this.assessmentService.getImage(userId).subscribe(
          (imageBlob) => {
            const objectURL = URL.createObjectURL(imageBlob);
 
            this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
 
            if (this.ImageUrl) {
              this.image = this.ImageUrl.changingThisBreaksApplicationSecurity;
 
              this.updateAllPerformers(userId, this.image);
            } else {
              this.image = "assets/image/profileimg.jpg";
              this.updateAllPerformers(userId, this.image);
            }
 
            this.cdr.detectChanges();
          },
          (error) => {
            // Step 8: Handle errors when fetching the profile image
            console.error('Error fetching image:', error);
            this.image = "assets/image/profileimg.jpg"; // Fallback to default
            this.updateAllPerformers(userId, this.image);
          }
        );
      }
    );
  }
  updateAllPerformers(userId: any, image: string) {
    const updateOrAddPerformer = (performerList: any[]) => {
      const performerEntry = performerList.find((entry: { spaAthlete: any; }) => entry.spaAthlete === userId);
      if (performerEntry) {
        performerEntry.image = image;
      } else {
        performerList.push({ spaAthlete: userId, image: image });
      }
    };
 
    // Update or add performer image for all categories
    updateOrAddPerformer(this.IsokineticPerformers);
    updateOrAddPerformer(this.ConcentricPerformers);
    updateOrAddPerformer(this.SportsPerformers);
   
  }
 

  openImageModal(imageUrl: string) {
    this.selectedImageUrl = imageUrl;
    const modalElement = document.getElementById('imageModal');
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  fetchImageUrls() {
    this.imageUrls = this.apiResponse.map((athlete: any) => {
      if (athlete.usrImage) {
        return this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + athlete.usrImage);
      } else {
        return null;
      }
    });
  }

  async selectOrganisation(){
    this.org = this.selectedOrg;
    this.orgStudents();
    this.isLoading = true;
   if (this.selectedOption === 'Cardio') {
      await this.getSportsPerformaceAnalysis();
      this.loadTopPerformersData();
      this.isLoading = false;
    } 
  }

  async selectTrainer(){
    this.trainerId = this.selectedTrainer;
    this.isLoading = true;
   if (this.selectedOption === 'Cardio') {
      await this.getSportsPerformaceAnalysis();
      this.loadTopPerformersData();
      this.isLoading = false;
    } 
  }

  onTeamSelected(selectedValue: any): void { 
    this.selectedTeamId = (selectedValue === 'all') ? null : selectedValue;
 
    this.getSportsPerformaceAnalysis().then(() => {
      this.loadSportsTopPerformersData();
      this.loadIsokineticTopPerformersData();
      this.loadConcentricTopPerformersData();
    });
  }

  extractNumericParts(value: string): number[] | null {
    if (!value) return null;
    const parts = value.split('/').map(part => parseFloat(part.trim()));
    return parts.length === 2 ? parts : null; 
  }

  async getSportsPerformaceAnalysis(): Promise<void> {
    try {
      this.UserId = this.trainerId || this.UserId;

      this.plaResponse = (this.Coach || this.PhysicalFitness)
        ? await this.assessmentService.getSportsperformanceByOrg(this.org).toPromise()
        : (await this.assessmentService.getAllSportAnalysisByAssessment().toPromise())
          .filter((item: any) => this.orgId === "0" || item.usrOrganization === Number(this.orgId));

      if ((this.Admin && this.selectedTeamId != '0') || (this.OrgAdmin && this.selectedTeamId != '0')) {
        this.plaResponse = this.plaResponse.filter(
          (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
        );
      } else if (!this.Admin && !this.Coach && !this.OrgAdmin) {
        this.plaResponse = this.plaResponse.filter(
          (item: any) => item.spaAssessor === this.UserId
        );

        if (this.selectedTeamId != '0') {
          this.plaResponse = this.plaResponse.filter(
            (item: any) => item.asiCurrentClubTeam === this.selectedTeamId.toString()
          );
        }
      } else if (this.Coach) {
        this.plaResponse = this.plaResponse.filter(
          (item: any) => item.asiCoach === this.UserId
        );
      }

      this.plaPerformers = this.athletedata || this.plaResponse;
      this.spaPerformersReport = this.plaPerformers;
      this.reportphysiologyCount = this.spaPerformersReport.length;

      if (this.spaPerformersReport && this.spaPerformersReport.length > 0) {
        for (const report of this.spaPerformersReport) {
          const avgTorqueCR = this.extractNumericParts(report.spaAverageTorqueCR);
          const avgTorqueCL = this.extractNumericParts(report.spaAverageTorqueCL);
          const peakTorqueCR = this.extractNumericParts(report.spaPeakTorqueCR);
          const peakTorqueCL = this.extractNumericParts(report.spaPeakTorqueCL);
          const avgTorqueIR = this.extractNumericParts(report.spaAverageTorqueIR);
          const avgTorqueIL = this.extractNumericParts(report.spaAverageTorqueIL);
          const peakTorqueIR = this.extractNumericParts(report.spaPeakTorqueIR);
          const peakTorqueIL = this.extractNumericParts(report.spaPeakTorqueIL);

          // Calculate average torque for each metric
          report.AverageTorqueCR = avgTorqueCR ? Number(((avgTorqueCR[0] + avgTorqueCR[1]) / 2).toFixed(2)) : null;
          report.AverageTorqueCL = avgTorqueCL ? Number(((avgTorqueCL[0] + avgTorqueCL[1]) / 2).toFixed(2)) : null;
          report.PeakTorqueCR = peakTorqueCR ? Number(((peakTorqueCR[0] + peakTorqueCR[1]) / 2).toFixed(2)) : null;
          report.PeakTorqueCL = peakTorqueCL ? Number(((peakTorqueCL[0] + peakTorqueCL[1]) / 2).toFixed()) : null;
          report.AverageTorqueIR = avgTorqueIR ? Number(((avgTorqueIR[0] + avgTorqueIR[1]) / 2).toFixed(2)) : null;
          report.AverageTorqueIL = avgTorqueIL ? Number(((avgTorqueIL[0] + avgTorqueIL[1]) / 2).toFixed(2)) : null;
          report.PeakTorqueIR = peakTorqueIR ? Number(((peakTorqueIR[0] + peakTorqueIR[1]) / 2).toFixed(2)) : null;
          report.PeakTorqueIL = peakTorqueIL ? Number(((peakTorqueIL[0] + peakTorqueIL[1]) / 2).toFixed(2)) : null;
        }

        //   const calculateAverage = (data: any[], property: string): number => {
        //     const values = data.map((item: any) => item[property]);
        //     return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
        //   };

        // this.averageSpaJumpHeight = calculateAverage(this.spaPerformersReport, 'spaJumpHeight');
        // this.averageSpaFlightTime = calculateAverage(this.spaPerformersReport, 'spaFlightTime');
        // this.averageSpaVelocity = calculateAverage(this.spaPerformersReport, 'spaVelocity');
        // this.averageSpaPower = calculateAverage(this.spaPerformersReport, 'spaPower');
        // this.averageSpaForce = calculateAverage(this.spaPerformersReport, 'spaForce');

        const calculateAverage = (data: any[], property: string): number => {
          const values = data
            .map((item: any) => item[property])
            .filter((val: number) => val !== null && !isNaN(val));
          return values.length ? values.reduce((sum: number, value: number) => sum + value, 0) / values.length : 0;
        };

        // Calculate the average of AverageTorqueCR
        this.averageAvgTorqueCR = calculateAverage(this.spaPerformersReport, 'AverageTorqueCR');
        this.averageAvgTorqueCL = calculateAverage(this.spaPerformersReport, 'AverageTorqueCL');
        this.averagePeakgTorqueCR = calculateAverage(this.spaPerformersReport, 'PeakTorqueCR');
        this.averagePeakTorqueCL = calculateAverage(this.spaPerformersReport, 'PeakTorqueCL');
        this.averageAvgTorqueIR = calculateAverage(this.spaPerformersReport, 'AverageTorqueIR');
        this.averageAvgTorqueIL = calculateAverage(this.spaPerformersReport, 'AverageTorqueIL');
        this.averagePeakTorqueIR = calculateAverage(this.spaPerformersReport, 'PeakTorqueIR');
        this.averagePeakTorqueIL = calculateAverage(this.spaPerformersReport, 'PeakTorqueIL');


        const SportsKeys = [
          'AverageTorqueCR', 'AverageTorqueCL', 'PeakTorqueCR', 'PeakTorqueCL',
          'AverageTorqueIR', 'AverageTorqueIL', 'PeakTorqueIR', 'PeakTorqueIL',
          'spaJumpHeight', 'spaFlightTime', 'spaVelocity', 'spaPower', 'spaForce'
        ];
        const Concentrickeys = ['AverageTorqueCR', 'PeakTorqueCR', 'AverageTorqueCL', 'PeakTorqueCL'];
        const IsokineticKeys = ['AverageTorqueIR', 'PeakTorqueIR', 'AverageTorqueIL', 'PeakTorqueIL'];

        const calculateStats = (data: number[]): { mean: number, stdDev: number } => {
          const mean = data.reduce((sum, value) => sum + value, 0) / data.length;
          const stdDev = Math.sqrt(data.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / data.length);
          return { mean, stdDev };
        };

        const safeCalculateZScore = (value: number, mean: number, stdDev: number): number =>
          stdDev === 0 ? 0 : Number(((value - mean) / stdDev).toFixed(2));

        const generateChart = async (keys: string[], bindto: string, performerLabels: string[]) => {
          const chartData: [string, ...number[]][] = keys.map(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            return [
              key.replace('spa', '').replace(/([A-Z])/g, ' $1') + ' Z-Score',
              ...this.spaPerformersReport.map(report => report[zScoreKey] as number)
            ];
          });
          const c3 = (await import('c3')).default;
          if (chartData.length > 0) {
            c3.generate({
              bindto,
              data: {
                columns: chartData,
                types: chartData.reduce((acc, [label]) => ({ ...acc, [label]: 'area-spline' }), {})
              },
              axis: {
                x: {
                  type: 'category',
                  categories: performerLabels,
                  tick: { rotate: 90, multiline: false },
                  height: 100
                },
                y: { label: 'Z-Score' }
              }
            });
          } else {
            console.warn(`No valid data for chart on ${bindto}`);
          }
        };

        const processCategory = (keys: string[]) => {
          let categoryValues: { [key: string]: number[] } = {};
          keys.forEach(key => categoryValues[key] = []);
          this.spaPerformersReport.forEach(report =>
            keys.forEach(key => categoryValues[key].push(report[key]))
          );
          let stats: { [key: string]: { mean: number, stdDev: number } } = {};
          keys.forEach(key => stats[key] = calculateStats(categoryValues[key]));
          return stats;
        };

        const allKeys = [...SportsKeys, ...IsokineticKeys, ...Concentrickeys];
        this.spaPerformersReport = this.spaPerformersReport.filter(report =>
          allKeys.every(key => typeof report[key] === 'number' && !isNaN(report[key]))
        );

        const sportsStats = processCategory(SportsKeys);
        const concentricStats = processCategory(Concentrickeys);
        const isokineticStats = processCategory(IsokineticKeys);

        this.spaPerformersReport.forEach(report => {
          let overallZScore = 0, overallConcentricZScore = 0, overallIsokineticScore = 0;

          SportsKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], sportsStats[key].mean, sportsStats[key].stdDev));
            overallZScore += report[zScoreKey];
          });

          Concentrickeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], concentricStats[key].mean, concentricStats[key].stdDev));
            overallConcentricZScore += report[zScoreKey];
          });

          IsokineticKeys.forEach(key => {
            const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
            report[zScoreKey] = this.zScoreToPercent(safeCalculateZScore(report[key], isokineticStats[key].mean, isokineticStats[key].stdDev));
            overallIsokineticScore += report[zScoreKey];
          });

          report.overallZScore = Math.round(Number((overallZScore / SportsKeys.length).toFixed(4)));
          report.overallConcentricZScore = Math.round(Number((overallConcentricZScore / Concentrickeys.length).toFixed(4)));
          report.overallIsokineticScore = Math.round(Number((overallIsokineticScore / IsokineticKeys.length).toFixed(4)));

          report.overallZScorePercentage = Math.min(Math.max(Math.round(((report.overallZScore + 3) / 6) * 100), 0), 100);
        });

        const performerLabels = this.spaPerformersReport.map(report => report.usrFullName);
        const overallZScoreSum = this.spaPerformersReport.reduce((sum, report) => sum + report.overallZScore, 0);
        const averageOverallZScore = overallZScoreSum / this.spaPerformersReport.length;
        this.averageOverallPercentage = Number(averageOverallZScore.toFixed(2));

        this.filterPerformers('top', 'Sport');
        this.filterPerformers('top', 'Isokinetic');
        this.filterPerformers('top', 'Concentric');
      } else {
        console.warn("No data available for the selected athletes.");
      }
    } catch (error) {
      console.error('Error fetching sports performance analysis:', error);
    }
  }
  
  zScoreToPercent(zScore: number | null): number | null {
    if (zScore === null) return null;
 
    const erf = (x: number): number => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
 
      const a1 =  0.254829592;
      const a2 = -0.284496736;
      const a3 =  1.421413741;
      const a4 = -1.453152027;
      const a5 =  1.061405429;
      const p  =  0.3275911;
 
      const t = 1.0 / (1.0 + p * x);
      const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
 
      return sign * y;
    };
 
    const percentile = 0.5 * (1 + erf(zScore / Math.sqrt(2)));
    return Math.round(percentile * 100);  // Round to the nearest integer percentage
  }

// Dropdown filter function
filterPerformers(filterType: string, chart: string): void {


    let filteredReport: any[] = []; // Initialize filteredReport to an empty array
 
    // Sort the spaPerformersReport based on the specified chart type's Z-score
    if (chart === 'Sport') {

        this.spaPerformersReport.sort((a, b) => b.overallZScore - a.overallZScore); // Sort by overallZScore

        if (filterType === 'Excellent') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallZScore > 85).sort((a: any, b: any) => b.overallZScore - a.overallZScore).slice(0, 10);
          this.sportTitle ='Excellent Sports Performers'
        } else if (filterType === 'Good') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallZScore > 70 && performer.overallZScore <= 85).sort((a: any, b: any) => b.overallZScore - a.overallZScore).slice(0, 10);
          this.sportTitle ='Good Sports Performers'
        } else if (filterType === 'Average') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallZScore > 40 && performer.overallZScore <= 70).sort((a: any, b: any) => b.overallZScore - a.overallZScore).slice(0, 10);
          this.sportTitle ='Average Sports Performers'
        } else if (filterType === 'Poor'){
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallZScore <= 40).sort((a: any, b: any) => a.overallZScore - b.overallZScore).slice(0, 10);
          this.sportTitle ='Poor Sports Performers'
        }else{
          filteredReport = this.spaPerformersReport.sort((a, b) => b.overallZScore - a.overallZScore).slice(0, 10);
          this.sportTitle ='Overall Sports Performers'
        }
        
    } else if (chart === 'Isokinetic') {


        this.spaPerformersReport.sort((a, b) => b.overallIsokineticScore - a.overallIsokineticScore); // Sort by overallIsokineticScore

        if (filterType === 'Excellent') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallIsokineticScore > 85).sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore).slice(0, 10);
          this.isokineticTitle ='Excellent Isokinetic Performers'
        } else if (filterType === 'Good') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallIsokineticScore > 70 && performer.overallIsokineticScore <= 85).sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore).slice(0, 10);
          this.isokineticTitle ='Good Isokinetic Performers'
        } else if (filterType === 'Average') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallIsokineticScore > 40 && performer.overallIsokineticScore <= 70).sort((a: any, b: any) => b.overallIsokineticScore - a.overallIsokineticScore).slice(0, 10);
          this.isokineticTitle ='Average Isokinetic Performers'
        } else if (filterType === 'Poor'){
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallIsokineticScore <= 40).sort((a: any, b: any) => a.overallIsokineticScore - b.overallIsokineticScore).slice(0, 10);
          this.isokineticTitle ='Poor Isokinetic Performers'
        }else{
          filteredReport = this.spaPerformersReport.sort((a, b) => b.overallIsokineticScore - a.overallIsokineticScore).slice(0, 10);
          this.isokineticTitle ='Overall Isokinetic Performers'
        }
    } else if (chart === 'Concentric') {

        this.spaPerformersReport.sort((a, b) => b.overallConcentricZScore - a.overallConcentricZScore); // Sort by overallConcentricZScore

        if (filterType === 'Excellent') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallConcentricZScore > 85).sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore).slice(0, 10);
          this.concentricTitle ='Excellent Concenteric Performers'
        } else if (filterType === 'Good') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallConcentricZScore > 70 && performer.overallConcentricZScore <= 85).sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore).slice(0, 10);
          this.concentricTitle ='Good Concenteric Performers'
        } else if (filterType === 'Average') {
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallConcentricZScore > 40 && performer.overallConcentricZScore <= 70).sort((a: any, b: any) => b.overallConcentricZScore - a.overallConcentricZScore).slice(0, 10);
          this.concentricTitle ='Average Concenteric Performers'
        } else if (filterType === 'Poor'){
          filteredReport = this.spaPerformersReport.filter((performer: any) => performer.overallConcentricZScore <= 40).sort((a: any, b: any) => a.overallConcentricZScore - b.overallConcentricZScore).slice(0, 10);
          this.concentricTitle ='Poor Concenteric Performers'
        }else{
          filteredReport = this.spaPerformersReport.sort((a, b) => b.overallConcentricZScore - a.overallConcentricZScore).slice(0, 10);
          this.concentricTitle ='Overall Concenteric Performers'
        }
    }   

  const SportsKeys = [
    'AverageTorqueCR', 'AverageTorqueCL', 'PeakTorqueCR', 'PeakTorqueCL',
    'AverageTorqueIR', 'AverageTorqueIL', 'PeakTorqueIR', 'PeakTorqueIL'
  ];
  const Concentrickeys = ['AverageTorqueCR', 'PeakTorqueCR', 'AverageTorqueCL', 'PeakTorqueCL'];
  const IsokineticKeys = ['AverageTorqueIR', 'PeakTorqueIR', 'AverageTorqueIL', 'PeakTorqueIL'];

  const generateChart = async (keys: string[], bindto: string, performerLabels: string[]) => {
    const chartData: [string, ...number[]][] = keys.map(key => {
      const zScoreKey = `zScore${key.charAt(0).toUpperCase() + key.slice(1)}`;
      return [key.replace('spa', '').replace(/([A-Z])/g, ' $1') + ' Z-Score', ...filteredReport.map(report => report[zScoreKey] as number)];
    });
    const c3 = (await import('c3')).default;
    if (chartData.length > 0) {
      c3.generate({
        bindto,
        data: {
          columns: chartData,
          types: chartData.reduce((acc, [label]) => ({ ...acc, [label]: 'area-spline' }), {})
        },
        axis: {
          x: { type: 'category', categories: performerLabels, tick: { rotate: 90, multiline: false }, height: 100 },
          y: { label: 'Z-Score' }
        },
          tooltip: {
            contents: (data) => {
              // Create the custom tooltip
              let html = '<table class="c3-tooltip">';
              html += '<thead><tr><th>Metric</th><th>Z-Score</th><th>Actual Value</th><th>IBM</th></tr></thead>';
              html += '<tbody>';
         
              // Loop through the data and build the rows
              data.forEach(item => {
                const key = item.id; // Get the key (e.g., "Body Weight Z-Score")
                const zScoreValue = item.value; // Get the Z-Score value
         
                // Extract the base key (e.g., "Body Weight") from the Z-Score key
                const baseKey = key.replace(' Z-Score', ''); // Adjust based on your label format
         
                // Find the corresponding performer for the current index
                const performer = filteredReport[item.index];
                const actualValue = performer[`${baseKey.replace(/ /g, '')}`]; // Remove spaces and prepend 'na'
         
                // Find the corresponding IBM reference
                const ibmMatch = this['IBMReference'].find((ibm: { testColumnName: string; }) => ibm.testColumnName === `spa${baseKey.replace(/ /g, '')}`);
                const ibmRange = ibmMatch ? ibmMatch.ibmRange : 'N/A';
         
                html += '<tr>';
                html += `<td>${baseKey}</td>`; // Metric Name
                html += `<td>${zScoreValue}</td>`; // Z-Score
                html += `<td>${actualValue}</td>`; // Actual Value from performer
                html += `<td>${ibmRange}</td>`; // IBM Range or 'N/A'
                html += '</tr>';
              });
         
              html += '</tbody></table>';
              return html;
            }
          }
      });
    } else {
      console.warn(`No valid data for chart on ${bindto}`);
    }
  };

  const performerLabels = filteredReport.map(report => report.usrFullName);

  if (this.Admin || this.OrgAdmin) {
    const performerLabels = filteredReport.map(report => report.usrFullName);
    if (chart === 'Sport') {
      generateChart(SportsKeys, '#new-chart', performerLabels);
    } else if (chart === 'Isokinetic') {

      generateChart(IsokineticKeys, '#isokinetic-chart', performerLabels);
    } else if (chart === 'Concentric') {
      generateChart(Concentrickeys, '#concentric-chart', performerLabels);

    }
  } else if (this.Coach) {
    const performerLabels = filteredReport.map(report => report.athleteName);
    if (chart === 'Sport') {
      generateChart(SportsKeys, '#new-chart', performerLabels);
    } else if (chart === 'Isokinetic') {

      generateChart(IsokineticKeys, '#isokinetic-chart', performerLabels);
    } else if (chart === 'Concentric') {
      generateChart(Concentrickeys, '#concentric-chart', performerLabels);

    }
  } else {
    const performerLabels = filteredReport.map(report => report.spaAthlete);
    if (chart === 'Sport') {
      generateChart(SportsKeys, '#new-chart', performerLabels);
    } else if (chart === 'Isokinetic') {

      generateChart(IsokineticKeys, '#isokinetic-chart', performerLabels);
    } else if (chart === 'Concentric') {
      generateChart(Concentrickeys, '#concentric-chart', performerLabels);

    }
  }
}

}

