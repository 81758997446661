import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class UserRegisterService {
 
  
 constructor(private http: HttpClient) {}
  // Method to add physiology details
  getAllUsers(newStatus: number, org: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.getUserUrl}`;
    const params = new HttpParams()
      .set('newStatus', newStatus.toString())
      .set('org', org.toString());
    
    return this.http.get<any>(url, { params });
  }
  
  updateUserStatus(userId: number, isActive: number): Observable<any> {
    const url = `${environment.BaseUrl}${environment.updateUser}?usrId=${userId}&newStatus=${isActive}`;
    console.log({ userId, isActive }, 'active');
    return this.http.put(url, {});
  }
  fetchUserById(id: any): Observable<any> {
    const url = `${environment.BaseUrl}${environment.userByid}${id}`;
    return this.http.get<any>(url);
  }

  getOrgTeams(orgId: any) {
    const url = `${environment.BaseUrl}${environment.orgTeams}${orgId}`;
    return this.http.get<any>(url);
  }
 
 
  userRegister(formData: any): Observable<any> {
    console.log(formData,"formdata")
    return this.http.post<any>(environment.BaseUrl+environment.Plyerregister, formData);
}
  fetchUser(id: any): Observable<any> {

  const url = `${environment.BaseUrl}${environment.userRegistrationURL}${id}`;
  return this.http.get<any>(url);
    }
   

    getPlayersByCoachId(CoachId:any): Observable<any> {
      const url = `${environment.BaseUrl}${environment.getPlayersByCoachId}${CoachId}`;
      return this.http.get<any>(url);
    }

    getTests(teamid:any): Observable<any> {
      const url = `${environment.BaseUrl}${environment.getTestsName}${teamid}`;
      return this.http.get<any>(url);
    }  
    updateProfile(formData: any): Observable<any> {
      console.log(formData,"formdata")
      return this.http.post<any>(environment.BaseUrl+environment.updateProfileDetails, formData);
  }
  changePassword(userId: any, oldPassword: any, newPassword: any ): Observable<any> {
    const url = `${environment.BaseUrl}${environment.changePassword}?userId=${userId}&oldPassword=${oldPassword}&newPassword=${newPassword}`;
    return this.http.post(url, {});
  }
}
