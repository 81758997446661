<div class="body-wrapper">

  <div >
    <button class="btn btn-primary" (click)="onBack()">Back</button>
  </div>
<div class="container">
  <header>Assessment</header><br>
  <form (ngSubmit)="onSubmit()">
    <div class="form first">
      <div class="row align-items-start">
        <!-- Image column -->
        <div class="col-md-4">
          <img *ngIf="showImage && image" 
               src="{{image}}" 
               class="custom-img-width">
               
        </div>

        <!-- Dropdown column (only visible in edit mode, aligned right) -->
        <div class="col-md-8 d-flex justify-content-end">
            <div  class="dropdown-wrapper mb-2">
              <label for="athleteDropdown" class="player-label">Select Player:</label>
              <div class="select-wrapper">
                <select class="form-select athlete-dropdown" id="athleteDropdown" (change)="onAthleteChange($event)">
                  <option *ngFor="let player of playerList" [selected]="player.aplPlayer === this.athleteId" [value]="player.aplPlayer">
                    {{player.aplPlayer}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
    <!-- <img *ngIf="showImage && ImageUrl" src="{{this.ImageUrl.changingThisBreaksApplicationSecurity}}"> -->
      <div class="details">
        <div class="fields">
          <!-- <div class="input-field"> -->
          
            <div class="input-field">
              <label>Player-ID</label>
              <input type="number" value="{{ athleteId }}" name="playerId" [disabled]="true"  placeholder="Enter Player ID" required (blur)="getProfileImage()">
            </div>
            <!-- <select id="athleteDropdown" (change)="onAthleteChange($event)">
                <option *ngFor="let player of playerList"
                [selected]="player.aplPlayer ===this.athleteId"
                 [value]="player.aplPlayer" selected=>
                  {{player.aplPlayer}}
                </option>
              </select> -->
              <div class="input-field" *ngIf="IsAdmin">
                <label>Player Name</label>
                <input type="text" value="{{playerName[0].Name}}" [disabled]="true" name="athleteName" placeholder="Enter Player Name" required>
              </div>
              <div class="input-field" *ngIf="IsAdmin">
                <label>Player Email</label>
                <input type="text" value="{{playerName[0].PlayerEmail}}" [disabled]="true" name="athleteEmail" placeholder="Enter Player Email" required>
              </div>
              <div class="input-field" *ngIf="IsAdmin">
                <label>Player PhoneNo</label>
                <input type="text" value="{{playerName[0].PhoneNo}}" [disabled]="true" name="athletePhoneNO" placeholder="Enter Player PhoneNO" required>
              </div>
        <!-- </div> -->
          <div class="input-field">
            <label>Assessment Date</label>
            <input type="date" [disabled]="edit === true"  [(ngModel)]="this.formData['assessmentDate']" name="assessmentDate" placeholder="Enter Assessment Date" required>
          </div>
        </div>
      </div>
      <div *ngFor="let subHeading of groupedFields | keyvalue">
        <span class="title">{{ subHeading.key }}</span>
      
        <div class="fields">
          <div class="input-field" *ngFor="let test of subHeading.value">
            <label>{{ test.testName }}<span>{{ test.testUnit }}</span></label>
      
            <div  *ngIf="category === 10">
              <div *ngIf="scoreOptions.length > 0; else noOptions">
                <select  style="width: 250px;"
                  [(ngModel)]="formData[test.testColumnName]" 
                  name="{{ test.testColumnName }}" 
                  required>
                  <option *ngFor="let option of scoreOptions" [value]="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <ng-template #noOptions>
                <span>No options available</span>
              </ng-template>
            </div>
      
            <div *ngIf="category !== 10 &&  category !== 11">
              <input 
                type="number" 
                [(ngModel)]="formData[test.testColumnName]" 
                name="{{ test.testColumnName }}" 
                placeholder="Enter {{ test.testName }}" 
                required>
            </div>
            <div *ngIf="category === 11">
              <input 
                type="text" 
                [(ngModel)]="formData[test.testColumnName]" 
                name="{{ test.testColumnName }}" 
                placeholder="Enter {{ test.testName }}" 
                required>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-container">
        <!-- Submit button triggers form submission -->
        <button type="submit" class="btn btn-primary" style="   height: 45px;
    max-width: 200px; width: 100%;">
          <span class="btnText">Submit</span>
          <i class="uil uil-navigator"></i>
        </button>
      </div>
    </div>
  </form>
</div>
</div>
<div class="toaster">
  <ng-toast></ng-toast>
</div>
