<div class="body-wrapper">  
  <div >
    <button class="btn btn-primary" (click)="onBack()">Back</button>
  </div>
    <div class="row flex-grow">
      <div class="col-12 grid-margin stretch-card">
        <div class="card card-rounded">
          <div class="card-body">
            <div class="d-sm-flex justify-content-between align-items-start">
              <div>
                <h4 class="card-title card-title-dash">Pending Assessment</h4>
                <p class="card-subtitle card-subtitle-dash">Players Details</p>
              </div>
              <div *ngIf="this.role == 2">
                <button class="btn btn-primary"  [routerLink]="['/assignassessment']">Add Assessment</button>
              </div>
            </div>
   
            <div class="content" style="width: 100%; height: 100%;">
                <!-- The AG Grid component, with Dimensions, CSS Theme, Row Data, and Column Definition -->
                <ag-grid-angular
                class="ag-theme-quartz"
                style="height: 500px;"
                [rowData]="rowData"
                [columnDefs]="colDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="true"
              />
              </div>
              <div class="modal fade" id="playerDetailModal" tabindex="-1" aria-labelledby="playerDetailModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="playerDetailModalLabel">Player Details</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3" *ngFor="let test of tests">
                     
                      <p ><strong>Test Name:</strong> {{ test.testName }}</p>
                      <!-- <p><strong>Email:</strong> {{ selectedPlayer?.Email }}</p>
                      <p><strong>Coach Name:</strong> {{ selectedPlayer?.CoachName }}</p>
                      <p><strong>Address:</strong> {{ selectedPlayer?.Address }}</p>
                      <p><strong>Phone No:</strong> {{ selectedPlayer?.PhoneNo }}</p>
                      <p><strong>Previous Injuries:</strong> {{ selectedPlayer?.PreviousInjuries }}</p> -->
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsivee">
                <table class="table table-hover table-outline table-vcenter text-nowrap card-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sub Category</th>
                      <th scope="col">Tests</th>
                      <th scope="col">Assessor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let group of groupedData | keyvalue">
                      <!-- Group key as Category -->
                      <th scope="row">{{ group.key }}</th>
                      <td>
                        <div *ngFor="let assessment of group.value">
                          {{ assessment.testName }} <span style="font-size: 10px;">{{ assessment.testUnit }}</span>
                        </div>
                      </td>
                      <td>
                        {{ group.value[0]?.assesserName}}
                      </td>
                    </tr>
                  </tbody>
                  <!-- Template for when there is no data -->
                  <ng-template #noData>
                    <tr>
                      <td colspan="8" class="text-center">Data not available</td>
                    </tr>
                  </ng-template>
                </table>
              </div>
   
          </div>
        </div>
      </div>
    </div>
  </div>


