import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, NgZone, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AssessmentService } from '../../services/assessment.service';
import { SharedService } from '../../services/shared.service';
import { AgGridAngular } from 'ag-grid-angular';
import { User } from '../../model/User';
import { ColDef } from 'ag-grid-community';
import { PrivilegeEnum } from '../../model/PrivilageEnum';
import { FormsModule } from '@angular/forms';
import { FileuploadComponent } from '../fileupload/fileupload.component';
import { PrivilegeService } from '../../services/privilage.service';
import { UserService } from '../../services/user.service';
import { UserRegisterService } from '../../services/user-register.service';

interface IRow {}
@Component({
  selector: 'app-csshealth-fitness',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule, HttpClientModule, AgGridAngular,FormsModule, FileuploadComponent],
  templateUrl: './csshealth-fitness.component.html',
  styleUrl: './csshealth-fitness.component.css'
})
export class CSSHealthFitnessComponent {
  Response: any;
  detail: any;
  Id: any;
  Status: any;
  isTableVisible: boolean = false;
  coachData: any;
  public asmId: any;
  public testNames: any;
  public assesserName: any;
  public CoachName: any;
  public teamName: any;
  public created: any;
  public CoachId: any;
  assessments: any;
  user: User | null = null;
  @ViewChild('fileUploadModal') fileUploadModal: FileuploadComponent | undefined;
  themeClass = 'ag-theme-quartz';
  rowData: IRow[] = [];
  colDefs: ColDef[] = [];
  ramPrivilege: PrivilegeEnum | undefined;
  defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };
  Isplayer: boolean | undefined;
  Coach:boolean | undefined;
  Admin: boolean | undefined;
  org: any;
  role: any;
  category: number = 1;
  selectedStatus: string = '0';
  selectdata: any;
  privilege: any;
  userDetail: any;
  constructor(
    private assessmentService: AssessmentService,
    private sharedService: SharedService,
    private ngZone: NgZone, 
    private router: Router,
    private priService:PrivilegeService,private userRegisterService:UserRegisterService
  ) {
    this.privilege = this.priService.getPrivileges();
    this.Isplayer = this.privilege.isPlayer;
    this.Coach = this.privilege.isCoach;
  }

  ngOnInit() {
    this.sharedService.detail$.subscribe((data: any) => {
      this.detail = data;
      this.Id = this.detail.user.usrId;
      this.role=this.detail.user.usrRole;
      this.Status = 0;
      console.log(this.detail,"users")
    });
    this.getUser();
    
    this.getPendingAssessment();
  }

  getUser(){
    this.userRegisterService.fetchUser(this.Id).subscribe(
      (response: any) => {
        this.userDetail = response;
        console.log(this.userDetail,"userdetail")
      })
  }

 // Holds the selected status value (1 or 0)

  getPendingAssessment() {
    this.assessmentService.getAssessmentBycategory(this.category).subscribe(
      (response) => {
        this.selectdata = response.filter((assessment: any) => assessment.asmStatus == this.selectedStatus);
        console.log(this.selectdata,"coach")
        if(this.role == 1 ){

          this.selectdata = this.selectdata.filter((assessment: any) => assessment.asmTeam == this.userDetail[0].asiCurrentClubTeam);
console.log(this.selectdata,"data")
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();

        }else if(this.role ==  11){

          this.selectdata = this.selectdata.filter((assessment: any) => assessment.asmCoach == this.userDetail[0].usrId);
console.log(this.selectdata,"data")
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();
        }
        else if(this.role ==  12){

          this.selectdata = this.selectdata.filter((assessment: any) => assessment.asmOrg == this.userDetail[0].usrOrganization );
console.log(this.selectdata,"data")
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();
        }
        else{
          const uniqueAssessments = new Map<number, any>();
  
          this.selectdata.forEach((item: any) => {
            if (!uniqueAssessments.has(item.asmId)) {
              uniqueAssessments.set(item.asmId, {
                asmId: `${item.asmId}`,
                AssessmentName: `${item.asmName}`,
                TeamName: `${item.teamName}`,
                CoachName: `${item.coachName}`,
                OrganizationName: `${item.orgName}`,
                Description: `${item.asmDescription}`,
                category:`${item.testCategory}`,
                Date: new Date(item.asmCreatedOn).toLocaleDateString(),
                testNames: [item.testName],
                CoachId: item.asmCoach,
                id: item.asmId,
                asmStatus: item.asmStatus, // Add asmStatus to the object for filtering
              });
            } else {
              const existingAssessment = uniqueAssessments.get(item.asmId);
              if (!existingAssessment.testNames.includes(item.testName)) {
                existingAssessment.testNames.push(item.testName);
              }
            }
          });
    
          this.rowData = Array.from(uniqueAssessments.values());
          this.filterAssessmentsByStatus(); // Call filter function after setting the data
          this.colDefs = this.getColumnDefinitions();
        }
        
      },
      (error) => {
        console.error('Error retrieving pending assessments', error);
      }
    );
  }
  
  // Filter the data by asmStatus
  filterAssessmentsByStatus() {
    if (this.selectedStatus !== '') {
      // Filter rowData based on the selectedStatus
      this.rowData = this.rowData.filter((assessment: any) => assessment.asmStatus == this.selectedStatus);
    }
  }
  
  openModal(): void {
    // Use Bootstrap's modal function to open the modal
    const modalElement = document.getElementById('fileUploadModal')!;
    const modal = new (window as any).bootstrap.Modal(modalElement);
    modal.show();
  }

  getColumnDefinitions(): ColDef[] {
    const columns: ColDef[] = [
      { field: 'AssessmentName' },
      { field: 'TeamName' },
      { field: 'CoachName' },
      { field: 'OrganizationName' },
      { field: 'Description' },
      { field: 'Date' },
      {
        headerName: 'Action',
        cellRenderer: (_params: any) => {
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.justifyContent = 'space-around';

          const viewButton = document.createElement('button');
          viewButton.classList.add('btn');
          viewButton.innerHTML = '<i class="fa fa-eye"></i>';
          viewButton.addEventListener('click', () => {
           

            this.ngZone.run(() => {
              if ( this.Isplayer) {
                
                this.router.navigate(['/report'], {
                  queryParams: {
                    asmId: _params.data.asmId,
                    category: _params.data.category
                  }
                });
              } else {
              
                this.router.navigate(['/fitnessDetail'], {
                  queryParams: {
                    asmId: _params.data.asmId,
                    category: _params.data.category
                  }
                });
              }
            });
          });
          container.appendChild(viewButton);
          return container;
        },
        width: 120,
      },
    ];

    if (this.Admin) {
      return columns;
    } else if (this.Isplayer) {
      return columns.filter((col) => col.field !== 'playername');
    } else {
      return columns.filter((col) => col.field !== 'Assessor');
    }
  }
}
