<div *ngIf="isLoading" class="loading-spinner">
  <div class="spinner"></div>
</div>

<div class="body-wrapper" [ngClass]="{ 'blurred': isLoading }">

  <div>
    <div class="container-fluid">
      <div class="d-flex justify-content-end mb-3">
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <button type="button" class="accept" (click)="downloadPDF()">
            <i class='bx bx-download'></i> Download
          </button>
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div id="pdf-content">
                <div *ngFor="let athlete of admitcardReport; let i = index" class="modal-body">
                  <div class="card1">
                    <div class="logo-content">
                      <div>
                        <h2>AMS SPORTS</h2>
                        <p>Centre For Sports Science</p>
                      </div>
                      <div class="logo-circle">
                        <img src="/assets/image/new_id.jpg" alt="Logo" class="logo-img">
                      </div>
                    </div>

                    <!-- Display each athlete's image -->
                    <div class="img">
                      <img *ngIf="imageUrls[i]" [src]="imageUrls[i]" alt="Athlete Image">
                    </div>

                    <h2 class="title">
                      <span>ID : </span>
                      {{athlete.usrId}}
                    </h2> <h2 class="title">
                      <span>Name: </span>
                      {{athlete.usrFullName}}
                    </h2>
                    <p class="info" style="font-size: 15px;">DOB : {{athlete.usrDoB | date:'dd-MM-yyyy'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="drop-items d-flex justify-content-end align-items-center mb-3 gap-4">
    <div class="select-wrapper">
      <button class="btn btn-primary" (click)="DownloadClick()" data-bs-toggle="modal"
        data-bs-target="#exampleModal">Download Player Id</button>
    </div>
    <div class="select-wrapper">
      <button class="btn btn-primary" [routerLink]="['/report']"> My Report</button>
    </div>
    <div class="select-wrapper">
      <select class="form-select" [(ngModel)]="selectedOption" (change)="onDropdownChange()">
        <option value="Level One">Level One</option>
        <option value="Physical Fitness">Physical Fitness</option>
        <option value="Nutrition">Nutrition</option>
        <!-- <option value="Cardio">Cardio</option> -->
        <option value="Cognitive">Cognitive</option>
        <option value="Injury Prevention">Injury Prevention</option>
        <option value="Sports Performance">Sports Performance</option>
      </select>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Level One'">
    <div class="container-fluid p-0">
      <div *ngIf="lastLevelOneAssessment" class="row">
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['Height', 'Weight', 'RulerDrop', 'HandGrip', 'SeatedMedBall', 'SitnReach']">
          <div>
            <div class="card-body p-3">
              <div class="text-muted mb-2">{{ metric.replace('Height', 'Height')
                .replace('Weight', 'Weight')
                .replace('RulerDrop', 'Ruler Drop')
                .replace('HandGrip', 'Hand Grip')
                .replace('SeatedMedBall', 'Seated Med Ball')
                .replace('SitnReach', 'Sit and Reach') }}</div>
              <div class="h3 m-0">{{ lastLevelOneAssessment['loa' + metric] || 0 }}</div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8 d-flex align-items-strech">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>

              </div>
              <div id="levelone-graph"></div>

            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment Table</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="levelone-table-body">
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Physical Fitness'">
    <div class="container-fluid p-0">
      <div *ngIf="lastFitnessAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['50mSprint', '30mSprint', 'Level', 'VO2', 'SitNReach', 'TTest']">
          <div>
            <div class="card-body p-3">
              <div class="text-muted mb-2">{{ metric.replace('Drop', ' Drop').replace('ReactionTime', ' Reaction Time')
                }}
              </div>
              <div class="h3 m-0">{{ lastFitnessAssessment['pfa' + metric] || 0 }}</div>

            </div>
          </div>
        </div>
      </div>


      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-8 d-flex align-items-strech">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="fitness-graph"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment Table</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="fitness-table-body">
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Nutrition'">
    <div class="container-fluid p-0">
      <div *ngIf="lastNutritionAssessment" class="row">
        <!-- Dynamic Nutrition Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['BodyWeight', 'BMI', 'BodyFat', 'SkeletalMuscle', 'BodyWater', 'HealthScore']">
          <div>
            <div class="card-body p-3">
              <div class="text-muted mb-2">{{ metric.replace('BodyWeight', 'Body Weight')
                .replace('BMI', 'BMI')
                .replace('BodyFat', 'Body Fat')
                .replace('SkeletalMuscle', 'Skeletal Muscle')
                .replace('BodyWater', 'Body Water')
                .replace('HealthScore', 'Health Score') }}</div>
              <div class="h3 m-0">{{ lastNutritionAssessment['na' + metric] || 0 }}</div>

            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-8 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="card-desc">Range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="nutritional-graph"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment Table</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="nutritional-table-body">
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Cognitive'">
    <div class="container-fluid p-0">
      <div *ngIf="lastCognitiveAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['RulerDrop', 'Blazepods', 'BlazepodsTime', 'WallToss', 'PlateTaping']">
          <div>
            <div class="card-body p-3">
              <div class="text-muted mb-2">{{ metric.replace('Drop', ' Drop').replace('ReactionTime', ' Reaction
                Time').replace('Endurance', ' Problem Solving').replace('Flexibility', ' Decision-Making') }}</div>
              <div class="h3 m-0">{{ lastCognitiveAssessment['cna' + metric] }}</div>

            </div>
          </div>
        </div>
      </div>


      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-8 d-flex align-items-strech">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="cognitive-graph"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment Table</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="cognitive-table-body">
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Injury Prevention'">
    <div class="container-fluid p-0">
      <div *ngIf="lastInjuryAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['TotalScore', 'DeepSquat', 'InlineLunge', 'HurdleStep', 'PushUp', 'ActiveSLR']">
          <div>
            <div class="card-body p-3 ">
              <div class="text-muted mb-2">{{ metric.replace('TotalScore', 'Total Score')
                .replace('DeepSquat', 'Deep Squat')
                .replace('InlineLunge', 'Inline Lunge')
                .replace('HurdleStep', 'Hurdle Step')
                .replace('PushUp', 'Push Up')
                .replace('ActiveSLR', 'ActiveSLR') }}</div>
              <div class="h3 m-0">{{ lastInjuryAssessment['ipa' + metric] || 0 }}</div>

            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-8 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="injury-graph"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment Table</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="injury-table-body">
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedOption == 'Sports Performance'">
    <div class="container-fluid p-0">
      <div *ngIf="lastSportsAssessment" class="row">
        <!-- Dynamic Fitness Cards -->
        <div class="col-6 col-sm-4 col-lg-2"
          *ngFor="let metric of ['AverageTorqueCR', 'AverageTorqueCL', 'PeakTorqueCR', 'PeakTorqueCL', 'JumpHeight', 'Force']">
          <div>
            <div class="card-body p-3">
              <div class="text-muted mb-2">{{ metric.replace('AverageTorqueCR', 'Average Torque CR')
                .replace('AverageTorqueCL', 'Average Torque CL')
                .replace('PeakTorqueCR', 'Peak Torque CR')
                .replace('PeakTorqueCL', 'Peak Torque CL')
                .replace('JumpHeight', 'Jump Height')
                .replace('Force', 'Force') }}</div>
              <div class="h5 m-0">{{ lastSportsAssessment['spa' + metric] || 0 }}</div>

            </div>
          </div>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="row">
        <div class="col-lg-8 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-body">
              <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                <div class="mb-3 mb-sm-0">
                  <h5 class="card-title fw-semibold">{{ selectedOption }} Performance Graph</h5>
                  <p class="cart-desc">range of last 2 {{ selectedOption }}</p>
                </div>
              </div>
              <div id="sports-graph"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fw-semibold">Current Assessment Table</h5>
                  <div class="table-responsive">
                    <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody id="sports-table-body">
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>