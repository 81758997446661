import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from '../../../services/assessment.service';
import { CommonModule } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';


@Component({
  selector: 'app-nutrition-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nutrition-detail.component.html',
  styleUrl: './nutrition-detail.component.css'
})
export class NutritionDetailComponent implements OnInit {
  selectedDate: string = ''; // Holds the selected date from the date picker
 


  id: any;
  athleteReport: any;
  athleteId: any;
 
  constructor(private router: Router,private location: Location, private route: ActivatedRoute,private assessmentService: AssessmentService) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.athleteId = params['athleteId']; // Ensure athleteId is in query parameters
      console.log(this.id, this.athleteId, "ID and AthleteId");
    });
    
 
  }
 
  ngOnInit(): void {
    if(this.id){
      this.assessmentService.getNutritionByAsm(this.athleteId, this.id).subscribe((response: any) => {
 
      this.athleteReport = response;
      console.log(this.athleteReport,"report")
 
    });
  }
  }
  downloadPDF(): void {
    const DATA = document.getElementById('pdf-content');
    if (DATA) {
      html2canvas(DATA).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth * 0.8; // 80% of the page width
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        const margin = (pdfWidth - imgWidth) / 2;
        const topMargin = 20; // Add top margin
 
        pdf.addImage(imgData, 'PNG', margin, topMargin, imgWidth, imgHeight);
        pdf.save('BodyComposition-details.pdf');
      });
    }
  }

  onBack():void{
    this.location.back();
  }
}