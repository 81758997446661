<div class="sidebar" #sidebar [ngClass]="isSidebarClosed ? 'hide' : ''">
  <div class="logo-details">
    <i #sidebarBtn class='bx bx-menu'></i>
 
    <span class="logo_name">AMS & Management</span>
  </div>
 
  <ul  class="nav-links" *ngIf="Admin || OrgAdmin"> 
    <!-- Dashboard -->
    <li [class.active]="activeLink === '/dashboard'" (click)="setActiveLink('/dashboard')">
      <a routerLink="/dashboard">
        <i class='bx bx-grid-alt'></i>
        <span class="link_name">Dashboard</span>
      </a>
    </li>
 
    <!-- Manage (with toggle sub-menu) -->
    <li [class.active]="activeLink === 'manage'" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
      <div class="iocn-link" (click)="toggleSubMenu(); setActiveLink('manage')">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <i class='bx bx-file'></i><span class="link_name">Manage</span>
        </a>
        <i *ngIf="!isSidebarClosed" class='bx' [ngClass]="isSubMenuOpen? 'bxs-chevron-up' : 'bxs-chevron-down'"></i>
      </div>
 
      <!-- Show submenu based on hover and sidebar state -->
      <ul class="sub-menu" *ngIf="(isSidebarClosed && isHovered) || (!isSidebarClosed && isSubMenuOpen)">
        <li [class.active]="activeLink === '/registerrequest'" (click)="setActiveLink('/registerrequest')">
          <a routerLink="/registerrequest">User</a>
        </li>
        <li *ngIf="Admin" [class.active]="activeLink === '/organization'" (click)="setActiveLink('/organization')">
          <a routerLink="/organization">Organization</a>
        </li>
        <li *ngIf="Admin" [class.active]="activeLink === '/team'" (click)="setActiveLink('/team')">
          <a routerLink="/team">Team</a>
        </li>
        <li *ngIf="Admin" [class.active]="activeLink === '/PendingAssessment'" (click)="setActiveLink('/PendingAssessment')">
          <a routerLink="/PendingAssessment">Assessment</a>
        </li>
      </ul>
    </li> 
 
    <!-- CSS Health & Fitness -->
    <li [class.active]="activeLink === '/Fitness'" (click)="setActiveLink('/Fitness')">
      <a routerLink="/Fitness">
        <i class='bx bx-book-alt'></i>
        <span class="link_name">CSS Health & Fitness</span>
      </a>
    </li>
 
    <!-- School Sports Health & Fitness -->
    <li [class.active]="activeLink === '/saifitness'" (click)="setActiveLink('/saifitness')">
      <a routerLink="/saifitness">
        <i class='bx bx-book-alt'></i>
        <span class="link_name">School Sports Health & Fitness</span>
      </a>
    </li>
 
    <!-- Sports Cognitive -->
    <li *ngIf="Admin" [class.active]="activeLink === '/CognitiveAssessment'" (click)="setActiveLink('/CognitiveAssessment')">
      <a routerLink="/CognitiveAssessment">
        <i class='bx bx-file'></i>
        <span class="link_name">Sports Cognitive</span>
      </a>
    </li>
 
    <!-- Sports Cardio -->
    <li *ngIf="Admin" [class.active]="activeLink === '/CardiologyAssessment'" (click)="setActiveLink('/CardiologyAssessment')">
      <a [routerLink]="['/CardiologyAssessment']">
        <i class="bx bx-book-alt"></i>
        <span class="link_name">Sports Cardio</span>
      </a>
    </li>
 
    <!-- Sports Physical Fitness -->
    <li *ngIf="Admin" [class.active]="activeLink === '/PhysicalfitnessAssessment'"
      (click)="setActiveLink('/PhysicalfitnessAssessment')">
      <a routerLink="/PhysicalfitnessAssessment">
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Sports Physical Fitness</span>
      </a>
    </li>
 
    <!-- Sports Physical Fitness -->
    <li *ngIf="Admin" [class.active]="activeLink === 'Sports Nutritional'" (mouseenter)="onNutrionalMouseEnter()" (mouseleave)="onNutrionalMouseLeave()">
      <div class="iocn-link" (click)="SportsNutritiontoggleSubMenu(); setActiveLink('Sports Nutritional')">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <i class='bx bx-file'></i><span class="link_name">Sports Nutritional</span>
        </a>
        <i *ngIf="!isSidebarClosed" class='bx' [ngClass]="isNutritionalSubMenuOpen ? 'bxs-chevron-up' : 'bxs-chevron-down'"></i>
      </div>
   
      <!-- Submenu -->
      <ul class="sub-menu" *ngIf="(isSidebarClosed && isNutrionalHovered) || (!isSidebarClosed && isNutritionalSubMenuOpen)">
        <li [class.active]="activeLink === '/SleepandRestAssessment'" (click)="setActiveLink('/SleepandRestAssessment')">
          <a routerLink="/SleepandRestAssessment" routerLinkActive="active">Sleep and Rest</a>
        </li>
        <li [class.active]="activeLink === '/HydrationandNutritionAssessment'" (click)="setActiveLink('/HydrationandNutritionAssessment')">
          <a routerLink="/HydrationandNutritionAssessment" routerLinkActive="active">Hydration and Nutrition</a>
        </li>
        <li [class.active]="activeLink === '/NurtitionAssessment'" (click)="setActiveLink('/NurtitionAssessment')">
          <a routerLink="/NurtitionAssessment" routerLinkActive="active">Body Composition</a>
        </li>
        <li [class.active]="activeLink === '/RecallListComponent'" (click)="setActiveLink('/RecallListComponent')">
          <a routerLink="/RecallListComponent" routerLinkActive="active">24 Hour Recall Assessment</a>
        </li>
      </ul>
    </li>

    <li *ngIf="Admin" [class.active]="activeLink === '/InjuryanalysisAssessment'" (click)="setActiveLink('/InjuryanalysisAssessment')">
      <a routerLink="/InjuryanalysisAssessment">
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Injury Prevention Analysis</span>
      </a>
    </li>
    <li *ngIf="Admin" [class.active]="activeLink === '/SportsPerformanceAnalysisAssessment'" (click)="setActiveLink('/SportsPerformanceAnalysisAssessment')">
      <a routerLink="/SportsPerformanceAnalysisAssessment">
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Sports Performance Analysis</span>
      </a>
    </li>
 
    <!-- Log Out -->
    <li (click)="clearUser()" [class.active]="activeLink === 'logout'">
      <a href="#">
        <i class='bx bx-cog'></i>
        <span class="link_name">Log Out</span>
      </a>
      <ul class="sub-menu blank">
        <li><a class="link_name">Log Out</a></li>
      </ul>
    </li>
  </ul>
 
  <!-- player -->
  <ul class="nav-links" *ngIf="Isplayer || Coach">
 
    <!-- Dashboard Link -->
    <li [class.active]="activeLink === '/dashboard'" (click)="setActiveLink('/dashboard')">
      <a routerLink="/dashboard">
        <i class='bx bx-grid-alt'></i>
        <span class="link_name">Dashboard</span>
      </a>
    </li>
    <!-- <li [class.active]="activeLink === '/PendingAssessment'" (click)="setActiveLink('/PendingAssessment')">
      <a routerLink="/PendingAssessment">
      <i class='bx bx-user'></i>
      <span class="link_name">Assessment</span>
    </a>
  </li> -->
    <!-- Players List -->
    <!-- <li *ngIf="Coach" [class.active]="Coach ? activeLink === '/playerlist' : activeLink === '/physiotherapydetaillist'"
      (click)="setActiveLink(Coach ? '/playerlist' : '/physiotherapydetaillist'); Coach ? navigateWithId('/playerlist') : navigateWithId('/physiotherapydetaillist');">
      <a>
        <i class='bx bx-user'></i>
        <span class="link_name">Players List</span>
      </a>
    </li>  -->
 
    <!-- School Sports Health & Fitness -->
    <!-- <li [class.active]="Coach ? activeLink === '/levelonelist' : activeLink === '/levelonelist'"
      (click)="setActiveLink(Coach ? '/levelonelist' : '/levelonelist'); Coach ? navigateWithId('/levelonelist') : navigateWithId('/levelonelist');">
      <a>
        <i class='bx bx-compass'></i>
        <span class="link_name">School Sports Health & Fitness</span>
      </a>
    </li>  -->
 
    <!-- Sports Cardio -->
    <!-- <li [class.active]="Coach ? activeLink === '/CardiologyList' : activeLink === '/CardiologyDetailList'"
      (click)="setActiveLink(Coach ? '/CardiologyList' : '/CardiologyDetailList'); Coach ? navigateWithId('/CardiologyList') : navigateWithId('/CardiologyDetailList')">
      <a>
        <i class='bx bx-pie-chart-alt-2'></i>
        <span class="link_name">Sports Cardio</span>
      </a>
    </li>  -->
 
    <!-- Sports Cognitive -->
    <!-- <li [class.active]="Coach ? activeLink === '/cognitivelist' : activeLink === '/cognitivedetaillist'"
      (click)="setActiveLink(Coach ? '/cognitivelist' : '/cognitivedetaillist'); Coach ? navigateWithId('/cognitivelist') : navigateWithId('/cognitivedetaillist')">
      <a>
        <i class='bx bx-book-alt'></i>
        <span class="link_name">Sports Cognitive</span>
      </a>
    </li>  -->
 
    <!-- Sports Physical Fitness -->
    <!-- <li [class.active]="Coach ? activeLink === '/physicalfitnesslist' : activeLink === '/physicalfitnessdetaillist'"
      (click)="setActiveLink(Coach ? '/physicalfitnesslist' : '/physicalfitnessdetaillist'); Coach ? navigateWithId('/physicalfitnesslist') : navigateWithId('/physicalfitnessdetaillist');">
      <a>
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Sports Physical Fitness</span>
      </a>
    </li>  -->
 
    <!-- Sports Nutrition -->
    <!-- <li [class.active]="Coach ? activeLink === '/nutritionlist' : activeLink === '/nutritiondetaillist'"
      (click)="setActiveLink(Coach ? '/nutritionlist' : '/nutritiondetaillist'); Coach ? navigateWithId('/nutritionlist') : navigateWithId('/nutritiondetaillist');">
      <a>
        <i class='bx bx-plug'></i>
        <span class="link_name">Sports Nutrition</span>
      </a>
    </li>
    <li [class.active]="Coach ? activeLink === '/InjuryanalysisList' : activeLink === '/InjuryanalysisDetailList'"
    (click)="setActiveLink(Coach ? '/InjuryanalysisList' : '/InjuryanalysisDetailList'); Coach ? navigateWithId('/InjuryanalysisList') : navigateWithId('/InjuryanalysisDetailList');">
    <a>
      <i class='bx bx-line-chart'></i>
      <span class="link_name">Injury Prevention Analysis</span>
    </a>
  </li> -->
 
  <li [class.active]="activeLink === '/Fitness'" (click)="setActiveLink('/Fitness')">
    <a routerLink="/Fitness">
      <i class='bx bx-book-alt'></i>
      <span class="link_name">CSS Health & Fitness</span>
    </a>
  </li>

  <li [class.active]="activeLink === '/saifitness'" (click)="setActiveLink('/saifitness')">
    <a routerLink="/saifitness">
      <i class='bx bx-book-alt'></i>
      <span class="link_name">School Sports Health & Fitness</span>
    </a>
  </li>
  
    <!-- Log Out -->
    <li (click)="clearUser()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a href="#">
        <i class='bx bx-cog'></i>
        <span class="link_name">Log Out</span>
      </a>
      <ul class="sub-menu blank">
        <li><a class="link_name">Log Out</a></li>
      </ul>
    </li> 
  </ul>
 
  <!-- player -->
 
  <!-- Assessor -->
  <ul class="nav-links" *ngIf="!Admin && !Isplayer && !OrgAdmin &&!Coach ">
    
    <li [class.active]="activeLink === '/dashboard'" (click)="setActiveLink('/dashboard')">
      <a routerLink="/dashboard">
        <i class='bx bx-book-alt'></i>
        <span class="link_name">Dashboard</span>
      </a>
    </li>
    <!-- <li  *ngIf="!Coach" [class.active]="isFormActive" (click)="activateReportForm()"><a>
        <i class='bx bx-book-alt'></i>
        <span class="link_name">Report Form</span>
      </a>
    </li> -->
     <!-- School Sports Health & Fitness -->
     <li [class.active]="activeLink === '/saifitness'" (click)="setActiveLink('/saifitness')">
      <a routerLink="/saifitness">
        <i class='bx bx-book-alt'></i>
        <span class="link_name">School Sports Health & Fitness</span>
      </a>
    </li>
    <li [class.active]="activeLink === '/PhysicalfitnessAssessment'"
    (click)="setActiveLink('/PhysicalfitnessAssessment')">
    <a routerLink="/PhysicalfitnessAssessment">
      <i class='bx bx-line-chart'></i>
      <span class="link_name">Sports Physical Fitness</span>
    </a>
  </li>
    <!-- Sports Cognitive -->
    <li [class.active]="activeLink === '/CognitiveAssessment'" (click)="setActiveLink('/CognitiveAssessment')">
      <a routerLink="/CognitiveAssessment">
        <i class='bx bx-file'></i>
        <span class="link_name">Sports Cognitive</span>
      </a>
    </li>
 
    <!-- Sports Cardio -->
    <li [class.active]="activeLink === '/CardiologyAssessment'" (click)="setActiveLink('/CardiologyAssessment')">
      <a [routerLink]="['/CardiologyAssessment']">
        <i class="bx bx-book-alt"></i>
        <span class="link_name">Sports Cardio</span>
      </a>
    </li>
    <li [class.active]="activeLink === 'Sports Nutritional'" (mouseenter)="onNutrionalMouseEnter()" (mouseleave)="onNutrionalMouseLeave()">
      <div class="iocn-link" (click)="SportsNutritiontoggleSubMenu(); setActiveLink('Sports Nutritional')">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <i class='bx bx-file'></i><span class="link_name">Sports Nutritional</span>
        </a>
        <i *ngIf="!isSidebarClosed" class='bx' [ngClass]="isNutritionalSubMenuOpen ? 'bxs-chevron-up' : 'bxs-chevron-down'"></i>
      </div>
   
      <!-- Submenu -->
      <ul class="sub-menu" *ngIf="(isSidebarClosed && isNutrionalHovered) || (!isSidebarClosed && isNutritionalSubMenuOpen)">
        <li [class.active]="activeLink === '/SleepandRestAssessment'" (click)="setActiveLink('/SleepandRestAssessment')">
          <a routerLink="/SleepandRestAssessment" routerLinkActive="active">Sleep and Rest</a>
        </li>
        <li [class.active]="activeLink === '/HydrationandNutritionAssessment'" (click)="setActiveLink('/HydrationandNutritionAssessment')">
          <a routerLink="/HydrationandNutritionAssessment" routerLinkActive="active">Hydration and Nutrition</a>
        </li>
        <li [class.active]="activeLink === '/NurtitionAssessment'" (click)="setActiveLink('/NurtitionAssessment')">
          <a routerLink="/NurtitionAssessment" routerLinkActive="active">Body Composition</a>
        </li>
        <li [class.active]="activeLink === '/RecallListComponent'" (click)="setActiveLink('/RecallListComponent')">
          <a routerLink="/RecallListComponent" routerLinkActive="active">24 Hour Recall Assessment</a>
        </li>
      </ul>
    </li>
 
    <li [class.active]="activeLink === '/InjuryanalysisAssessment'" (click)="setActiveLink('/InjuryanalysisAssessment')">
      <a routerLink="/InjuryanalysisAssessment">
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Injury Prevention Analysis</span>
      </a>
    </li>
    <li [class.active]="activeLink === '/SportsPerformanceAnalysisAssessment'" (click)="setActiveLink('/SportsPerformanceAnalysisAssessment')">
      <a routerLink="/SportsPerformanceAnalysisAssessment">
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Sports Performance Analysis</span>
      </a>
    </li>
 
    <!-- <li   *ngIf="!Coach" [class.active]="isReportActive" (click)="activateReport()"><a>
        <i class='bx bx-line-chart'></i>
        <span class="link_name">Overall Report</span>
      </a>
    </li>  -->
    <!-- <li><a >Physiotherapy</a></li> -->
 
    <li (click)="clearUser()">
      <a href="#">
        <i class='bx bx-cog'></i>
        <span class="link_name">Log Out</span>
      </a>
      <ul class="sub-menu blank">
        <li><a class="link_name">Log Out</a></li>
      </ul>
    </li>
  </ul>
  <!-- Support staff -->
</div>
<section class="home-section">
  <div class="home-content">
 
    <header class="app-header">
      <nav class="navbar navbar-expand-lg navbar-light">
 
        <div class="d-flex align-items-center">
          <div class="logo me-3">
            <img src="../../assets/image/athletelogo.png">
          </div>
 
          <div>
            <div class="Title">Hello, {{name}}
              <img src="/assets/image/welcome.png" class="welcome-image">
            </div>
            <p class="description">Welcome to {{getdashboardname()}} dashboard </p>
          </div>
        </div>
 
        <ul class="navbar-nav flex-row ms-auto align-items-center">
          <li class="nav-link"> 
            <button #themeToggleBtn class="bx bxs-moon"></button>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-icon-hover" href="javascript:void(0)">
              <i class='bx bx-bell'></i>
              <div class="notification bg-primary rounded-circle"></div>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2">
              
              <img  [src]="profileImageUrl" alt="Profile Image" width="35" height="35"
                class="rounded-circle">
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
              <div class="message-body">
                <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item"
                  routerLink="/profile">
                  <i class="ti ti-mail fs-6"></i>
                  <p class="mb-0 fs-3">My Account</p>
                </a>
                <a class="btn btn-outline-primary mx-3 mt-2 d-block" (click)="logout()">Logout</a>
              </div>
            </div>
          </li>
        </ul>
 
      </nav>
    </header>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
</section>