<!--  Main wrapper -->
<div *ngIf="isLoading" class="loading-spinner">
    <div class="spinner"></div>
</div>
<div class="text-center" *ngIf="this.nutrionReport.length === 0">
  !No Data
</div>
<div class="row" *ngIf="this.nutrionReport.length > 0">  
        <div class="row">
        
          <div class="col-sm-8 flex-column d-flex stretch-card">
            <div class="row">
              <div class="col-lg-4 d-flex grid-margin stretch-card">
                <div class="card bg-blue">
                  <div class="card-body text-white">
                    <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h3>
                    <div class="progress mb-3">
                      <!-- Use Angular's property binding to set the width of the progress bar -->
                      <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                        [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
                      </div>
                    </div>
                    <p class="pb-0 mb-0">Overall Z-Score</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 d-flex grid-margin stretch-card">
                <div class="card player-diffrence-border">
                  <div class="card-body">
                    <h2 class="text-dark mb-2 font-weight-bold"> {{ reportNutritionCount }}</h2>
                    <h4 class="card-title mb-2">Players Count</h4>
  
                  </div>
                </div>
              </div>
              <div class="col-lg-4 d-flex grid-margin stretch-card">
                <!-- <div class="card justify-content-center player-diffrence-border">
                  <div class="card-body ms-4">
                    <img class="pro-img"
                    src="{{TopPerformer[1]?.image}}">
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 grid-margin d-flex stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="card-title mb-2">{{GraphHeader}} Performers In Nutrition</h4>
                      <!-- <div class="select-wrapper">
                        <select (change)="filterPerformers($event, 'overall')" class="form-select">
                          <option selected disabled>Select Option</option>
                          <option value="All">All</option>
                          <option value="excellent">Excellent</option>
                          <option value="good">Good</option>
                          <option value="average">Average</option>
                          <option value="poor">Poor</option>
                        </select>
                      </div> -->
                      <div class="dropdown">
                        <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                          data-bs-toggle="dropdown" id="settingsDropdownsales">
                          <i class="mdi mdi-dots-horizontal"></i></a>
                          <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                          aria-labelledby="settingsDropdownsales">
                          <a class="dropdown-item" (click)="filterPerformers('All', 'overall')">
                            <i class="mdi mdi-grease-pencil text-primary"></i>
                            All
                          </a>
                          <a class="dropdown-item" (click)="filterPerformers('excellent', 'overall')">
                            <i class="mdi mdi-grease-pencil text-primary"></i>
                            Excellent
                          </a>
                          <a class="dropdown-item" (click)="filterPerformers('good', 'overall')">
                            <i class="mdi mdi-grease-pencil text-primary"></i>
                            Good
                          </a>
                          <a class="dropdown-item" (click)="filterPerformers('average', 'overall')">
                            <i class="mdi mdi-grease-pencil text-primary"></i>
                            Average
                          </a>
                          <a class="dropdown-item" (click)="filterPerformers('poor', 'overall')">
                            <i class="mdi mdi-grease-pencil text-primary"></i>
                            Poor
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="nutrition-chart"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 flex-column d-flex stretch-card">
            <div class="row flex-grow">
              <div class="col-sm-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div>
                        <h4 class="card-title fw-semibold">Top Performer</h4>
                        <h4 class="font-weight-bold text-name">Name : {{ TopPerformer[0]?.usrFullName }}</h4>
                        <h4 class="font-weight-bold text-id">ID :
                          {{ TopPerformer[0]?.naAthlete }}
                        </h4>
                        <p class="text-dark">{{ TopPerformer[0]?.naCreatedOn | date:'dd-MM-yyyy' }}</p>
                      </div>
                      <!-- <div class="col-lg-4">
                        <div class="position-relative">
                          <img src="{{nutritionTop5Performer[0].imageUrl?.changingThisBreaksApplicationSecurity}}"
                            class="w-100" alt="">
                          <div class="live-info badge badge-success">Live</div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row">
                      <div class="col-sm-12 mt-4 mt-lg-0">
                        <div class="bg-blue text-white px-4 py-4 card">
                          <div class="row">
                            <div class="col-sm-6 pl-lg-5">
                              <h2>{{ TopPerformer[0]?.naBMI || 0 }}%</h2>
                              <p class="mb-0 text-small">BMI</p>
                            </div>
                            <div class="col-sm-6 climate-info-border mt-lg-0 mt-2">
                              <h2>{{ nutritiontopPerformer[0]?.naSkeletalMuscle || 0 }}%</h2>
                              <p class="mb-0 text-small">Skeletal Muscle</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3 mt-md-1">
                      <div class="col-6 text-center">
                        <div class=" purchase-detail-legend d-flex flex-column align-items-center justify-content-center">
                          <div class="circular-progress mb-2"
                            style="--value: {{ TopPerformer[0]?.naLeanMass || 0 }}; --color: #ff5521;">
                            <svg width="60" height="60" class="progress-circle">
                              <circle class="background" cx="30" cy="30" r="27" stroke="#e6e6e6" stroke-width="6"
                                fill="none"></circle>
                              <circle class="circle" cx="30" cy="30" r="27" stroke="#ff5521" stroke-width="6" fill="none"
                                [attr.stroke-dasharray]="169.65"
                                [attr.stroke-dashoffset]="169.65 - (TopPerformer[0]?.naLeanMass / 100 * 169.65)"
                                stroke-linecap="round">
                              </circle>
                            </svg>
                          </div>
                          <div>
                            <p class="font-weight-medium text-dark text-small">LeanMass</p>
                            <h3 class="font-weight-bold text-dark mb-0">{{ TopPerformer[0]?.naLeanMass || 0 }}%
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 text-center">
                        <div class=" purchase-detail-legend d-flex flex-column align-items-center justify-content-center">
                          <div class="circular-progress mb-2"
                            style="--value: {{TopPerformer[0]?.naProtein || 0 }}; --color: #ff5521;">
                            <svg width="60" height="60" class="progress-circle">
                              <circle class="background" cx="30" cy="30" r="27" stroke="#e6e6e6" stroke-width="6"
                                fill="none"></circle>
                              <circle class="circle" cx="30" cy="30" r="27" stroke="#ff5521" stroke-width="6" fill="none"
                                [attr.stroke-dasharray]="169.65"
                                [attr.stroke-dashoffset]="169.65 - (TopPerformer[0]?.naProtein / 100 * 169.65)"
                                stroke-linecap="round">
                              </circle>
                            </svg>
                          </div>
                          <div>
                            <p class="font-weight-medium text-dark text-small">Protein</p>
                            <h3 class="font-weight-bold text-dark mb-0">{{TopPerformer[0]?.naProtein || 0
                              }}%
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <h4  class="card-title fw-semibold">International Benchmark (IBM)</h4>
              <div class="table-responsive">
                <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Test</th>
                      <th>IBM Male</th>
                      <th>IBM Female</th>
                      <th>Team Average Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BMI</td>
                      <td>18.5–24.9</td>
                      <td> 18.5–24.9 </td>
                      <td>{{ this.averageNaBMI | number: '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Body Fat</td>
                      <td>7-12</td>
                      <td>12-18</td>
                      <td>{{ averageNaBodyFat | number: '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Fat Free Weight</td>
                      <td>40-60</td>
                      <td>30-50</td>
                      <td>{{ averageNaFatFreeWeight  | number: '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Body Water</td>
                      <td>50-65</td>
                      <td>45–60</td>
                      <td>{{ averageNaBodyWater | number: '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Bone Mass</td>
                      <td>2.5-4.0</td>
                      <td>1.8–3.0</td>
                      <td>{{ averageNaBoneMass | number: '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Protein</td>
                      <td>16-20</td>
                      <td>16–20</td>
                      <td>{{ averageNaProtein | number: '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>BMR </td>
                      <td>1600-1800</td>
                      <td>1300–1500</td>
                      <td>{{ averageNaBMR | number: '1.2-2' }}</td>
                    </tr>
                  </tbody>
                  <!-- Template for when there is no data -->
                  <ng-template #noData>
                    <tr>
                      <td colspan="8" class="text-center">Data not available</td>
                    </tr>
                  </ng-template>
                </table>
              </div>
            </div>
          </div>
          <div class="row row-cards row-deck">
            <div class="d-sm-flex justify-content-between align-items-center mb-2">
              <h4 class="card-title fw-semibold" >{{header}}</h4>
              <div>
                <div class="select-wrapper">
                  <!-- <i class="bx bx-cog select-icon"></i> -->
                  <select [(ngModel)]="selectedStatus" (change)="onNutritionStatusChange()" class="form-select">
                    <option selected disabled>Select Option</option>
                    <option value="0">All </option>
                    <option value="1">Excellent </option>
                    <option value="2">Good</option>
                    <option value="3">Average</option>
                    <option value="4">Poor</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Top Performers Section -->
            <div class="col-12">
              <div class="card">
                <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
                <div class="table-responsive">
                  <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                    <thead>
                      <tr>
                        <th>Profile</th>
                        <th>Athletes</th>
                        <th>Height (cm)</th>
                        <th>Weight (Kgs)</th>
                        <th>BMI</th>
                        <th>Body Fat</th>
                        <th>Fat Free Weight</th>
                        <th>Subcutaneous Fat</th>
                        <th>Visceral Fat</th>
                        <th>Body Water</th>
                        <th>Skeletal Muscle</th>
                        <th>Lean Mass</th>
                        <th>Bone Mass</th>
                        <th>Protein</th>
                        <th>BMR</th>
                        <th>Metabolic Age</th>
                        <th>Health Score</th>
                        <th>Z Score</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="nutritiontopPerformer && nutritiontopPerformer.length > 0; else noData">
                      <tr *ngFor="let performer of nutritiontopPerformer">
                        <td class="text-center">
          
                          <img class="avatar"
                          src="{{performer.image}}">
                         
                        </td>
                        <td>
                          <div>{{ performer.usrFullName}}</div>
                          <div class="small text-muted">ID: {{ performer.naAthlete }}</div>
                        </td>
                        <td>{{ performer.naBodyHeight }}</td>
                        <td>{{ performer.naBodyWeight }}</td>
                        <td class="text-center">{{ performer.naBMI }}</td>
                        <td class="text-center">{{ performer.naBodyFat }}</td>
                        <td class="text-center">{{ performer.naFatFreeWeight }}</td>
                        <td class="text-center">{{ performer.naSubcutaneousFat }}</td>
                        <td class="text-center">{{ performer.naVisceralFat }}</td>
                        <td class="text-center">{{ performer.naBodyWater }}</td>
                        <td class="text-center">{{ performer.naSkeletalMuscle }}</td>
                        <td class="text-center">{{ performer.naLeanMass }}</td>
                        <td class="text-center">{{ performer.naBoneMass }}</td>
                        <td class="text-center">{{ performer.naProtein }}</td>
                        <td class="text-center">{{ performer.naBMR }}</td>
                        <td class="text-center">{{ performer.naMetabolicAge }}</td>
                        <td class="text-center">{{ performer.naHealthScore }}</td>
                        <td class="text-center">{{ performer.overallZScore.toFixed(2) }} %</td>
                       </tr>
                    </tbody>
                    <!-- Template for when there is no data -->
                    <ng-template #noData>
                      <tr>
                        <td colspan="8" class="text-center">Data not available</td>
                      </tr>
                    </ng-template>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
     
</div>