<div class="text-center" *ngIf="this.athleteReport.length === 0">
  !No Data
</div>
<div class="row" *ngIf="this.athleteReport.length > 0">
   <div class="col-sm-8 flex-column d-flex stretch-card">
     <div class="row">
    
       <div class="col-lg-4 d-flex grid-margin stretch-card">
         <div class="card bg-blue">
           <div class="card-body text-white">
             <h3 class="font-weight-bold mb-3">{{ averageOverallPercentage }}%</h3>
             <div class="progress mb-3">
               <!-- Use Angular's property binding to set the width of the progress bar -->
               <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="averageOverallPercentage"
                 [attr.aria-valuenow]="averageOverallPercentage" aria-valuemin="0" aria-valuemax="100">
               </div>
             </div>
             <p class="pb-0 mb-0">Overall Average</p>
           </div>
         </div>
       </div>
       <div class="col-lg-4 d-flex grid-margin stretch-card">
         <div class="card player-diffrence-border">
           <div class="card-body">
             <h2 class="text-dark mb-2 font-weight-bold"> {{ reportCount }}</h2>
             <h4 class="card-title mb-2">Players Count</h4>
           </div>
         </div>
       </div>
       <div class="col-lg-4 d-flex grid-margin stretch-card">
        <!-- <div class="card justify-content-center player-diffrence-border" >
          <div class="card-body ms-4">
            <img class="pro-img"
            src="{{cognitiveTop5Performers[0]?.image}}">
          </div>
        </div> -->
      </div>
     </div>
     <div class="row">
       <div class="col-sm-12 grid-margin d-flex stretch-card">
         <div class="card">
           <div class="card-body">
             <div class="d-flex align-items-center justify-content-between">
               <h4 class="card-title mb-2">{{this.reportTitle}} Performers In Cognitive</h4>
               <div class="dropdown">
                 <a href="#" class="text-success btn btn-link dropdown-toggle dropdown-arrow-none"
                   data-bs-toggle="dropdown" id="settingsDropdownsales">
                   <i class="mdi mdi-dots-horizontal"></i></a>
                 <div class="dropdown-menu dropdown-menu-right navbar-dropdown"
                   aria-labelledby="settingsDropdownsales">
                   <a class="dropdown-item" (click)="filterPerformers('All','overall')">
                     <i class="mdi mdi-grease-pencil text-primary"></i>
                     All
                   </a>
                   <a class="dropdown-item" (click)="filterPerformers('Excellent','overall')">
                     <i class="mdi mdi-grease-pencil text-primary"></i>
                     Excellent
                   </a>
                   <a class="dropdown-item"  (click)="filterPerformers('Good','overall')">
                     <i class="mdi mdi-delete text-primary"></i>
                     Good
                   </a>
                   <a class="dropdown-item"  (click)="filterPerformers('Average','overall')">
                     <i class="mdi mdi-delete text-primary"></i>
                     Average
                   </a>
                   <a class="dropdown-item"  (click)="filterPerformers('Poor','overall')">
                     <i class="mdi mdi-delete text-primary"></i>
                     Poor
                   </a>
                 </div>
               </div>
             </div>
             <div id="cognitive-chart"></div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="col-sm-4 flex-column d-flex stretch-card">
     <div class="row flex-grow">
       <div class="col-sm-12 grid-margin stretch-card">
         <div class="card">
           <div class="card-body">
             <div class="row">
              <div>
                <h4 class="card-title fw-semibold">Top Performer</h4>
                <h4 class="font-weight-bold text-name">Name : {{ cognitiveTop5Performers[0]?.usrFullName }}</h4>
                <h4 class="font-weight-bold text-id">ID :
                  {{ cognitiveTop5Performers[0]?.cnaAthlete }}
                </h4>
                <p class="text-dark">{{ cognitiveTop5Performers[0]?.cnaCreatedOn | date:'dd-MM-yyyy' }}</p>
              </div>
               <!-- <div class="col-lg-4">
             <div class="position-relative">
               <img src="{{CognitivePerformers[0].imageUrl.changingThisBreaksApplicationSecurity}}"
                 class="w-100" alt="">
               <div class="live-info badge badge-success">Live</div>
             </div>
           </div> -->
             </div>
             <div class="row">
               <div class="col-sm-12 mt-4 mt-lg-0">
                 <div class="bg-blue text-white px-4 py-4 card">
                   <div class="row">
                     <div class="col-sm-6 pl-lg-5">
                       <h2>{{ cognitiveTop5Performers[0]?.zScoreRulerDrop || 0 }}%</h2>
                       <p class="mb-0 text-small">RulerDrop</p>
                     </div>
                     <div class="col-sm-6 climate-info-border mt-lg-0 mt-2">
                       <h2>{{ cognitiveTop5Performers[0]?.zScoreBlazepods || 0 }}%</h2>
                       <p class="mb-0 text-small">Blazepods</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div class="row pt-3 mt-md-1">
               <div class="col-6">
                 <div class="purchase-detail-legend d-flex flex-column align-items-center justify-content-center">
                   <div class="circular-progress me-2"
                     style="--value: {{ cognitiveTop5Performers[0]?.zScoreRulerDrop || 0 }}; --color: #ff5521;">
                     <svg width="60" height="60" class="progress-circle">
                       <circle class="background" cx="30" cy="30" r="27" stroke="#e6e6e6" stroke-width="6"
                         fill="none"></circle>
                       <circle class="circle" cx="30" cy="30" r="27" stroke="#ff5521" stroke-width="6" fill="none"
                         [attr.stroke-dasharray]="169.65"
                         [attr.stroke-dashoffset]="169.65 - (cognitiveTop5Performers[0]?.zScoreRulerDrop / 100 * 169.65)"
                         stroke-linecap="round">
                       </circle>
                     </svg>
                   </div>
                   <div>
                     <p class="font-weight-medium text-dark text-small">RulerDrop (cm)</p>
                     <h3 class="font-weight-bold text-dark mb-0">{{ cognitiveTop5Performers[0]?.zScoreRulerDrop || 0 }}%
                     </h3>
                   </div>
                 </div>
               </div>
               <div class="col-6">
                 <div class="purchase-detail-legend d-flex flex-column align-items-center justify-content-center">
                   <div class="circular-progress me-2"
                     style="--value: {{cognitiveTop5Performers[0]?.zScoreBlazepods || 0 }}; --color: #ff5521;">
                     <svg width="60" height="60" class="progress-circle">
                       <circle class="background" cx="30" cy="30" r="27" stroke="#e6e6e6" stroke-width="6"
                         fill="none"></circle>
                       <circle class="circle" cx="30" cy="30" r="27" stroke="#ff5521" stroke-width="6" fill="none"
                         [attr.stroke-dasharray]="169.65"
                         [attr.stroke-dashoffset]="169.65 - (cognitiveTop5Performers[0]?.zScoreBlazepods / 100 * 169.65)"
                         stroke-linecap="round">
                       </circle>
                     </svg>
                   </div>
                   <div>
                     <p class="font-weight-medium text-dark text-small">Blazepods</p>
                     <h3 class="font-weight-bold text-dark mb-0">{{cognitiveTop5Performers[0]?.zScoreBlazepods || 0
                       }}%</h3>
                   </div>
                 </div>
               </div>

             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="row row-cards row-deck">
     <div class="col-12">
       <div class="card">
        <h4  class="card-title fw-semibold">International Benchmark (IBM)</h4>
         <div class="table-responsive">
           <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
             <thead>
               <tr>
                 <th>Test</th>
                 <th>IBM Male</th>
                 <th>IBM Female</th>
                 <th>Team Average Score</th>
               </tr>
             </thead>
             <tbody *ngIf="cognitiveTop5Performers && cognitiveTop5Performers.length > 0; else noData">
               <tr>
                 <td>Ruler Drop (cm)</td>
                 <td>0.25</td>
                 <td>0.25</td>
                 <td>{{ this.averageCNaRulerDrop.toFixed(2) }}</td>
               </tr>
               <!-- <tr>
                 <td>Blaze Pods</td>
                 <td></td>
                 <td></td>
                 <td>{{ this.averageCNaBlazePods.toFixed(2) }}</td>
               </tr> -->
               <!-- <tr>
                 <td>Blaze Pods Time</td>
                 <td></td>
                 <td></td>
                 <td>{{ this.averageCNaBlazepodsTime.toFixed(2) }}</td>
               </tr> -->
               <tr>
                 <td>Wall Toss</td>
                 <td>30-40</td>
                 <td>30-40</td>
                 <td>{{ this.averageCNaWallToss.toFixed(2) }}</td>
               </tr>
               <!-- <tr>
                 <td>Plate Taping</td>
                 <td></td>
                 <td></td>
                 <td>{{ this.averageCNaPlateTaping.toFixed(2) }}</td>
               </tr> -->
             </tbody>
             <ng-template #noData>
               <tr>
                 <td colspan="8" class="text-center">Data not available</td>
               </tr>
             </ng-template>
           </table>
         </div>
       </div>
     </div>
   </div>
   <div class="row row-cards row-deck">
     <div class="d-sm-flex justify-content-between align-items-center mb-2">
       <h4 class="card-title fw-semibold" >{{header}} Performers</h4>
       <div>
         <div class="select-wrapper">
           <select [(ngModel)]="selectedStatus" (change)="onCognitiveStatusChange()" class="form-select">
             <option selected disabled>Select Option</option>
             <option value="0">All</option>
             <option value="1">Excellent </option>
             <option value="2">Good</option>
             <option value="3">Average </option>
             <option value="4">Poor</option>
           </select>
         </div>
       </div>
     </div>
     <!-- Top Performers Section -->
     <div class="col-12">
       <div class="card">
         <h4 class="m-3" *ngIf="chat">Athlete Data</h4>
         <div class="table-responsive">
           <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
             <thead>
               <tr>
                 <th>Profile</th>
                 <th>Athletes</th>
                 <th>Ruler Drop (cm)</th>
                 <th>Blaze pods</th>
                 <th>Blaze pods Time (sec)</th>
                 <th>Wall Toss</th>
                 <th>Plate Taping (sec)</th>
                 <th> Z-Score </th>
                 <!-- <th>AssessmentDate</th> -->
               </tr>
             </thead>
             <tbody *ngIf="CognitivePerformers && CognitivePerformers.length > 0; else noData">
               <tr *ngFor="let performer of CognitivePerformers">
                 <td class="text-center">
                  <img class="avatar"
                  src="{{performer.image}}">
                 </td>
                 <td>
                   <div>{{ performer.usrFullName }}</div>
                   <div class="small text-muted">ID: {{ performer.cnaAthlete }}</div>
                 </td>
                 <td>
                   <div class="clearfix">
                     <div class="float-left"><strong>{{ performer.cnaRulerDrop }}</strong></div>
                   </div>
                   <!-- <div class="progress progress-xs">
                 <div class="progress-bar" [ngClass]="{
             'bg-green': performer.loaAverageRange >= 80,
             'bg-yellow': performer.loaAverageRange >= 50 && performer.loaAverageRange < 80,
             'bg-red': performer.loaAverageRange < 50
           }" role="progressbar" [style.width.%]="performer.loaAverageRange"></div>
               </div> -->
                 </td>
                 <td>{{ performer.cnaBlazepods }}</td>
                 <td>{{ performer.cnaBlazepodsTime }}</td>
                 <td>{{ performer.cnaWallToss }}</td>
                 <td>{{ performer.cnaPlateTaping }}</td>
                 <td>{{performer.overallZScore}}%</td>
                 <!-- <td>{{performer.cnaCreatedOn | date:'dd/MM/yyyy'}}</td> -->
               </tr>
             </tbody>
             <!-- Template for when there is no data -->
             <ng-template #noData>
               <tr>
                 <td colspan="8" class="text-center">Data not available</td>
               </tr>
             </ng-template>
           </table>
         </div>
       </div>
     </div>
   </div>
 </div>